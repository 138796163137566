import React from "react";
import Footer from "../../components/footer";
import styled from "styled-components";
import { PaginaSobreContainer, Session, SessionLocalizacao, TextSession, TitleSession, TextSessionLocal, Img, TitleSessionLocal, SessionParceiros, TitleSessionParceiros, Parceiros, ImgParceiros, TitleParceiros, A, Div } from "./styles";
// import MapXcene from "../../components/map";
import control from "../../assets/control.png";
import loud from "../../assets/loud.png";
import Newsletter from "./Newsletter";
//import { CaixaEmail } from "./CaixaEmail";
import Mapa from '../../assets/mapaX.png'

const PaginaSobre: React.FC = () => {
    return (
        <>
            <PaginaSobreContainer>
                <Session>
                    <div style={{ flex: 1, minWidth: "300px" }}>
                        <TitleSession>Sobre</TitleSession>
                        <Div>
                            <TextSession style={{minWidth: "300px", maxWidth: "570px"}}>
                                A Austhen Ind. Com e Serv. LTDA ME foi fundada em 2005 e é a fabricante da reconhecida marca Xcene Automação, líder no mercado de automação residencial. Nós acreditamos em valores como inovação, qualidade e compromisso com nossos clientes, e é por isso que nossos produtos são produzidos com os mais altos padrões de qualidade.
                            </TextSession>
                            <TextSession style={{minWidth: "300px", maxWidth: "640px"}}>
                                Nós nos dedicamos a fornecer soluções de automação residencial personalizadas, que atendam às necessidades e desejos específicos de nossos clientes. Estamos sempre buscando oferecer a melhor experiência possível.
                            </TextSession>
                        </Div>
                    </div>
                </Session>

                <SessionLocalizacao>
                    <div style={{ flex: 1, minWidth: "300px" }}>
                        <TitleSession>Nossa Localização</TitleSession>
                        <TextSessionLocal
                        // style={{minWidth: "300px", maxWidth: "520px"}}
                        >
                            Nós oferecemos um atendimento excepcional para nossos clientes, com uma equipe pronta para ajudar com todas as suas necessidades de automação residencial. 
                        </TextSessionLocal>
                    </div>
                    <div>
                        <a  style={{display: "flex", alignItems: "center", justifyContent: "center"}} href="https://goo.gl/maps/8dLrggaEjDgQNy95A">
                            <Img
                            style={{
                                // marginTop: "40px",
                                // width: "100%",
                                // minWidth: "200px",
                                // maxWidth: "700px",
                                // height: "405px",
                                // marginLeft: "100px",
                                // backgroundColor: 'red',
                                // borderRadius: "25px",
                            }} src={Mapa} alt="localização xcene" />
                        </a>
                        {/* <Maps /> */}
                        {/* <Map></Map> */}
                    {/* <MapXcene /> */}
                    </div>
                </SessionLocalizacao>

                {/* <SessionParceiros>
                    <div>
                        <TitleSessionParceiros>Nossos Parceiros</TitleSessionParceiros>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between"}}>
                        <Parceiros>
                            <A href="https://www.smarthomecontrol.com.br" rel="noreferrer" target="_blank">
                                <ImgParceiros src={control}/>
                                <TitleParceiros>Smart Home Control</TitleParceiros>
                            </A>
                        </Parceiros>
                        <Parceiros>
                            <A href="https://www.loudaudio.com.br">
                                <ImgParceiros src={loud}/>
                                <TitleParceiros>Loud</TitleParceiros>
                            </A>
                        </Parceiros>
                    </div>
                </SessionParceiros>
                <Newsletter /> */}

                {/* <SessionNewsletter>
                    <div style={{display:"flex", flexDirection: "row", justifyContent: "center"}}>
                        <Newsletter>
                            <TittleNewsletter>Inscreva-se em nossa Newsletter</TittleNewsletter>
                            <TextNewsletter>
                                    Fique por dentro das últimas novidades e promoções exclusivas da nossa empresa. Mantenha-se informado sobre as tendências e tecnologias de automação residencial. Inscreva-se agora e não perca nenhuma atualização.
                            </TextNewsletter>
                        </Newsletter>
                        <CaixaEmail/>
                    </div>
                </SessionNewsletter> */}
            </PaginaSobreContainer>
        </>
    );
};

export default PaginaSobre;
