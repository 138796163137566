import styled from "styled-components";

export const PaginaContatoContainer = styled.div`
    /* min-height: 150vh; */
    /* position: relative; */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 40%;
    margin-top: 3%;
    justify-content: center;

    @media screen and (max-width: 480px) {
        padding: 0 10%;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        padding: 0 10%;
    }
`;

export const Session = styled.div`
    
    /* min-width: 300px; */
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    /* max-width: 1500px; */
    /* div {
        padding: 0px;
        padding-inline: 0px;
        //height: 290px;
    } */
`;

export const TitleSession = styled.h1`
    
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    /* line-height: 135%; */
    /* margin: 2em 0; */
    /* margin-top: 1em; */

    /* identical to box height, or 65px */

    letter-spacing: -0.01em;

    color: #1e272f;

    @media screen and (max-width: 480px) {
        font-size: 36px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 38px;
    }
`;

export const SubTitle = styled.h1`
    //position: absolute;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    /* line-height: 160%; */
    margin: -2.5% 0% 3.5% ;
    /* margin: -1em 0em 2.5em ; */
    
    @media screen and (max-width: 480px) {
        font-size: 18px;
        /* margin: -1em 0em 2.5em ; */
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 18px;
    }`;

export const TextSession = styled.h3`
    //display: flex;
    //padding: 1.8em;
    /* position: absolute; */
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 160%;
    //letter-spacing: -0.01em;
    /* margin: -0.5em 0em; */
    color: #787d82;
    /* margin-bottom: 10em; */
    min-width: 200px;
    max-width: 1024px;

    @media screen and (max-width: 480px) {
        font-size: 18px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 18px;
    }
    
`;