import React from "react";
// import Footer from "../../components/footer";
// import RootElement from "../../components/root";
// import { ContainerSideBar } from "../../components/root/style";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { HomePageContainer, Session, Session2, SessionMarc, TitleSession } from "./styled";
// import Keypad from "../../assets/Keypad.png";
// // import Modulo from "../../assets/Modulo.png";
// import SaibaMais from "../../assets/SaibaMais.png";
// import SobreSessionImage from "../../assets/SobreSession.png";
// import Arrow from "../../assets/Arrow.svg";
import NossoServicosAutomacao from "../../assets/automacao.png";
import NossoServicosAssistencia from "../../assets/assistencia.png";
// import MapXcene from "../../components/map";
// import Newsletter from "../sobre/Newsletter";
// import Map from "../../components/map";
// import Maps from "../../components/map/Maps";
// import Mapa from '../../assets/Mapa.png';
// import Button from "../../components/button";
// import Destaques from "../../components/destaques";
import Modulo from "../../assets/FrenteRele.png";
import Pulsador from "../../assets/3BFlatBranco.jpg";
import Pulsador4 from "../../assets/image 5.png";

// const SessionProdutosCard = styled.div`
//     display: flex;
//     position: absolute;
//     /* content: ""; */
//     height: 100%;
//     /* top: 0; */
//     align-items: center;
//     justify-content: center;
//     border-radius: 45px;
//     /* z-index: -1; */
//     flex: 1;
//     /* min-width: 300px; */
//     //width: 300px;

//     @media screen and (max-width: 480px) {
//         /* display: grid; */
//         padding: 0 2rem;
//         width: 80vw;
//     }
// `;

const TitleNossoServico = styled.h1`
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: #1C70BB;

    @media screen and (max-width: 480px) {
        font-size: 12px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 16px;
    }
`;

const TextNossoServico = styled.text`
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    color: #787d82;
    /* padding: 100px; */

    @media screen and (max-width: 480px) {
        font-size: 10px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 14px;
    }
`;


// const TitleProdutos = styled.h1`
//     font-family: "Rubik";
//     font-style: normal;
//     font-weight: 500;
//     font-size: 20px;
//     line-height: 140%;
//     color: #4b5259;

//     @media screen and (max-width: 480px) {
//         font-size: 15px;
//     }
// `;

// const TextProdutos = styled.text`
//     //margin-right: 1em;
//     display: flex;
//     font-family: "Rubik";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 16px;
//     line-height: 160%;
//     color: #787d82;

//     @media screen and (max-width: 480px) {
//         /* height: 170px; */
//         font-size: 12px;
//         padding: 0 2rem;
//         /* width: 200px; */
//     }
// `;

// const NavIten = styled(Link)`
//     display: inline-flex;
//     align-items: center;
//     //text-align: center;
//     color: #1C70BB;
// `;

// const Text = styled.h4`
//     font-family: "Rubik";
//     font-weight: 400;
//     font-style: normal;
//     font-size: 10px;
//     line-height: 140%;
//     color: #1C70BB;

//     @media screen and (max-width: 480px) {
//         /* height: 170px; */
//         font-size: 8px;
//         line-height: 0%;
//     }
// `;

const SessionNossoServicoCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: center;
    min-width: 240px;
    max-width: 440px;
    /* width: 340px; */

    @media screen and (max-width: 480px) {
        /* width: 160px; */
        min-width: 100px;
        max-width: 200px;
        /* margin: 5% 2% 0 0; */
        /* height: 20rem; */
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        /* width: 250px; */
        min-width: 250px;
        max-width: 350px;
    }
`;

// const Img = styled.img`
//     /* width: 1rem;  */
//     margin-left: -15.8rem;

//     @media screen and (max-width: 480px) {
//         width: 1rem; 
//         margin-left: -9rem;
//         margin-top: 0.1rem;
//         /* line-height: 160%; */
//     }
// `;

// const Imgs = styled.img`
//     @media screen and (max-width: 890px) {
//         height: 16rem;
//     }

//     @media screen and (max-width: 480px) {
//         height: 10rem; 
//         margin-top: -23%;
//     }
// `;

// const ImgsLoc = styled.img`
//     margin-right: 3rem;

//     @media screen and (max-width: 890px) {
//         height: 16rem;
//     }
    
//     @media screen and (max-width: 480px) {
//         height: 10rem; 
//         margin-top: -42%;
//         margin-left: 15%;
//     }
// `;

const ServicosImg = styled.img`
    width: 100px;
    @media screen and (max-width: 480px) {
        width: 65px;
    }
`;

// const ProductImg = styled.img`
//     /* min-width: 97px; */
//     width: 194px; 
//     height: 335px; 
//     /* margin-left:-10rem;  */
//     margin-top: -9%; 

//     @media screen and (max-width: 480px) {
//         width: 120px;
//         height: 213px;
//         margin-left: -2rem;
//         margin-top: 15%;
//     }
// `;

// const News = styled.div`
//     @media screen and (max-width: 480px) {
//         /* align-items: center; */
//         justify-content: center;
//         /* padding: 1.2rem; */
//     }
// `;

// const ProductDiv = styled.div`
//     @media screen and (max-width: 480px) {
//         width: 50px;
//     }
// `;

export const Div = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* position: fixed;
    top: 15%; */
    /* width: 100%; */
    
    @media screen and (max-width: 480px) {
        /* font-size: 12px; */
        /* min-width: 200px; */
    }
`;

export const Aviso = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background: #FFF; */
    /* width: 300px; */
    /* height: 100px; */
    margin: -6% 0;
    
    @media screen and (max-width: 480px) {
        /* font-size: 12px; */
        /* min-width: 200px; */
    }
`;

export const TextAviso = styled.h1`
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: red;
    
    @media screen and (max-width: 480px) {
        font-size: 12px;
    }
`;

export const Abas = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    min-width: 350px;
    max-width: 500px;
    /* width: 380px; */
    height: 480px;
    border-radius: 10px;
    /* background-color: #1C70BB; */
    background: linear-gradient(180deg, rgba(32, 46, 89, 0) 0%, rgba(32, 46, 89, 0.59) 57%, #202E59 100%);
    position: relative;
    /* z-index: 2; */
    
    @media screen and (max-width: 480px) {
        /* width: 260px; */
        min-width: 240px;
        max-width: 400px;
        height: 340px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        /* width: 330px; */
        height: 420px;
    }
    
    /* @media screen and (max-width: 480px) {
        width: 260px;
        height: 320px;
    } */
`;

export const TextAbas = styled.h1`
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    color: #FFFFFF;
    
    @media screen and (max-width: 480px) {
        font-size: 28px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 42px;
    }
`;

// const Div1 = styled.div`
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     flex-wrap: wrap;
//     width: 100%;
//     height: 260px;
//     /* background: red; */

//     @media screen and (max-width: 480px) {
//         width: 200px;
//         height: 200px;
//     }

//     div {
//         display: flex;
//         flex-wrap: wrap;
//         width: 250px;
//         height: 400px;
//         align-items: center;
//         justify-content: center;

//         @media screen and (max-width: 480px) {
//             width: 125px;
//             height: 200px;
//         }

//         img {
//             width: auto;
//             height: auto;

//             @media screen and (max-width: 480px) {
//                 height: 60%;
//             }
//         }
//     }
// `;

// const Div2 = styled.div`
//     display: flex;
//     flex-direction: column;
//     width: 350px;
//     height: 100%;
//     /* padding: 0 15px 20px; */
//     align-items: flex-start;
//     justify-content: flex-end;
//     /* background: yellow; */
//     margin-bottom: 3%;
//     margin-left: 3%;


//     @media screen and (max-width: 480px) {
//         width: 200px;
//         padding: 0 8px 10px;
//     }
// `;

// const Div3 = styled.div`
//     display: flex;
//     align-items: flex-end;
//     justify-content: flex-end;
//     /* gap: 10px; */
//     /* background: red; */

//     @media screen and (max-width: 480px) {
//         gap: 0px;
//     }
// `;

// const Div4 = styled.div`
//     display: flex;
//     gap: 2%;
//     flex-wrap: wrap;
//     min-width: 200px;
//     /* background-color: red; */

//     @media screen and (max-width: 480px) {
//         min-width: 130px;
//     }
// `;

// const Produto = styled.h3`
//     font-family: "Rubik";
//     font-size: 20px;
//     font-weight: 400;
//     line-height: 0px;
//     color: #1e272f;
//     flex-wrap: wrap;
//     /* min-width: 200px; */

//     @media screen and (max-width: 480px) {
//         font-size: 18px;
//     }
// `;

// const Preco = styled.h4`
//     font-family: "Rubik";
//     font-size: 16px;
//     font-weight: 500;
//     line-height: 0%;
//     color: #1e272f;

//     @media screen and (max-width: 480px) {
//         font-size: 12px;
//     }
// `;

// const TextCard = styled.h3`
//     font-family: "Rubik";
//     font-size: 14px;
//     font-weight: 400;
//     line-height: 0%;
//     color: #787d82;

//     @media screen and (max-width: 480px) {
//         font-size: 11px;
//     }
// `;

// const Btn = styled(Button)`
//     /* width: 6.8vw; */
//     min-width: 48px;
//     max-width: 100px;
//     /* height: 3.2vw; */
//     min-height: 20px;
//     max-height: 50px;
//     border-radius: 4px;
//     background: #202e59;

//     @media screen and (max-width: 480px) {
//         min-width: 37.5px;
//         max-width: 70px;
//         min-height: 30px;
//         max-height: 45px;
//     }

//     span {
//         color: #fafbff;
//         /* font-size: 16px; */
//         /* font-weight: 400; */

//         @media screen and (max-width: 480px) {
//             font-size: 12px;
//         }
//     }

//     :hover {
//         background-color: #1b3480;
//         border-color: transparent;
//         color: #444;
//     }
// `;

// export const NavIten2 = styled(Link)`
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-end;
//     align-items: flex-start;
//     color: #1E272F;
//     font-family: "Rubik";
//     font-size: 16px;
//     line-height: 145%;
//     font-weight: 500;
//     text-decoration: none;

//     @media screen and (max-width: 480px) {
//         font-size: 8px;
//     }
// `;

interface SobreServicoProps {
    image: string;
    title: string;
    text: string;
}

const NossoServicoCard = ({ image, title, text }: SobreServicoProps) => {
    return (
        <SessionNossoServicoCard>
            <ServicosImg src={image} />
            <TitleNossoServico>{title}</TitleNossoServico>
            <TextNossoServico>{text}</TextNossoServico>
        </SessionNossoServicoCard>
    );
};


// const ProdutosCard = ({ image, title, text }: SobreServicoProps) => {
//     return (
//         <SessionProdutosCard>
//             <div>
//                 <img style={{width: "auto"}} src={image} />
//             </div>
//             <div style={{textAlign: "center"}}>
//             <TitleProdutos>{title}</TitleProdutos>
//             <TextProdutos>{text}</TextProdutos>
//             <a href="" style={{fontFamily: "Rubik", fontSize: "10px", fontWeight: "400", lineHeight: "140%", color: "#1C70BB", }}>Saiba mais</a>
//             </div>
//         </SessionProdutosCard>
//     );
// };

const HomePage: React.FC = () => {
    const navigate = useNavigate();
    return (
        <>
            <HomePageContainer>
                <Session>
                    <div style={{ flex: 1, height: "auto", alignItems: "center", display: "flex", flexDirection: "column" }}>
                        {/* <Destaques/> */}
                        <SessionMarc>
                            <div style={{display:"flex", alignItems: "center", justifyContent: "center", position: "relative", cursor: "pointer"}}>
                                <img style={{position: "absolute", objectFit: "cover", width: "100%", height: "100%"}} src={Pulsador} alt="" />
                                <Abas
                                onClick={() => {navigate("/products/Keypads")}}
                                >
                                    <TextAbas>KEYPADS</TextAbas>
                                </Abas>
                            </div>
                            <div style={{display:"flex", alignItems: "center", justifyContent: "center", position: "relative", cursor: "pointer"}}>
                                <img style={{position: "absolute", objectFit: "contain", width: "100%", height: "100%"}} src={Pulsador4} alt="" />
                                <Abas
                                 onClick={() => {navigate("/products/Centrais")}}>
                                    <TextAbas>CENTRAIS</TextAbas>
                                </Abas>
                            </div>
                            <div style={{display:"flex", alignItems: "center", justifyContent: "center", position: "relative", cursor: "pointer"}}>
                                <img style={{position: "absolute", objectFit: "contain", width: "100%", height: "100%"}} src={Modulo} alt="" />
                                <Abas onClick={() => {navigate("/products/Modulos")}}>
                                    <TextAbas>MÓDULOS</TextAbas>
                                </Abas>
                            </div>
                            {/* <SessionProdutosCard>
                                <ProductDiv>     
                                    <ProductImg src={Keypad} />
                                </ProductDiv>
                                <div style={{
                                    textAlign: "center",
                                    marginTop: "5%",
                                    flex: 1,
                                    minWidth: "250px",
                                    maxWidth: "500px",
                                }}>
                                    <TitleProdutos>Keypads</TitleProdutos>
                                    <TextProdutos>Nossos  Keypads são a solução perfeita para controle de acesso de forma prática e segura. Com design moderno e tecnologia avançada, é a escolha ideal para aqueles que buscam um controle de acesso eficiente e sofisticado.</TextProdutos>
                                    <NavIten to="/products">
                                        <Text>Saiba Mais</Text>
                                        <img style={{ paddingLeft: "0.1vw" }} src={SaibaMais} alt="Saiba Mais" />
                                    </NavIten>
                                </div>
                            </SessionProdutosCard> */}
                        </SessionMarc>
                    </div>
                </Session>
                {/* <Session style={{ marginBottom: "4%" }}>
                    <div style={{ flex: 1, minWidth: "200px", maxWidth: "600px", flexWrap: "wrap" }}>
                        <TitleSession>Sobre</TitleSession>
                        <TextSession>
                            Nós valorizamos a satisfação do cliente acima de tudo, e é por isso que temos uma equipe altamente treinada e experiente para
                            fornecer suporte técnico e assistência quando necessário. Se você procura produtos de automação residencial confiáveis e de
                            qualidade, a Xcene Automação é a escolha certa.
                        </TextSession>
                        <a href="/sobre" style={{ display: "flex", alignItems: "center", textDecoration: "none", marginTop: "1%" }}>
                            <TextSession style={{ color: "#1C70BB" }}> Ler mais sobre nós </TextSession>
                            <Img src={Arrow} alt="Arrow" />
                        </a>
                    </div>
                    <div>
                        <Imgs src={SobreSessionImage} height={330} />
                    </div>
                </Session> */}
                <Session2 style={{ marginBottom: "70px", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ flex: 1, height: "auto", alignItems: "center", justifyContent: "center" }}>
                        <TitleSession style={{ textAlign: "center" }}>Nossos Serviços</TitleSession>
                        {/* <SessionMarck> */}
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "5%" }}>
                                <NossoServicoCard
                                    image={NossoServicosAutomacao}
                                    title="Automação"
                                    text="Transforme sua casa em um ambiente inteligente e personalizado com nosso serviço de automação residencial."
                                />
                                <NossoServicoCard
                                    image={NossoServicosAssistencia}
                                    title="Assistência Técnica"
                                    text="Com nosso serviço de automação residencial, você tem a tranquilidade de saber que terá suporte técnico de alta qualidade sempre que precisar."
                                ></NossoServicoCard>
                            </div>
                        {/* </SessionMarck> */}
                    </div>
                </Session2>
                {/* <Session style={{
                    marginBottom: "7%", flexWrap: "wrap"
                }}>
                    <div style={{ flex: 1, minWidth: "200px", maxWidth: "450px", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
                        <TitleSession>Nossa Localização</TitleSession>
                        <TextSession style={{ fontWeight: "400" }}>
                            Nós oferecemos um atendimento excepcional para nossos clientes, com uma equipe pronta para ajudar com todas as suas necessidades de automação residencial.
                        </TextSession>
                    </div>
                    <a href="https://goo.gl/maps/8dLrggaEjDgQNy95A" style={{ display: "flex", alignItems: "center", textDecoration: "none", justifyContent: "center" }}>
                            <ImgsLoc src={Mapa} alt="localização xcene" height={330}/>
                    </a>
                </Session> */}
                {/* <News>
                    <Newsletter />
                </News> */}
            </HomePageContainer>
        </>
    );
};

export default HomePage;

// import React, { useState } from 'react';
// import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';

// const containerStyle= {
//     width: '100%',
//     height: '400px'
// };

// const center = {
//     lat: -23.625485664373144,
//     lng: -46.63411721510235
// };

// function Map() {
//     const { isLoaded, loadError } = useLoadScript({
//         googleMapsApiKey: 'AIzaSyDL9uHqkapaEa6Npq7r9bIaUWIPpDXJk5w'
//     })

//     const [markers, setMarkers] = useState([]);

//     const onMapClick = (event: any) => {
//         setMarkers(current => [
//             ...current,
//             {
//                 lat: event.latLng.lat(),
//                 lng: event.latLng.lng(),
//                 time: new Date ()
//             }
//         ]);
//     };

//     if (loadError) return <div>Erro ao carregar o mapa</div>;
//     if (!isLoaded) return <div>Carregando o mapa</div>;

//     return (
//         <GoogleMap
//             mapContainerStyle={containerStyle}
//             center={center}
//             zoom={10}
//             onClick={onMapClick}
//         >
//             {markers.map(marker => (
//                 <Marker
//                 key={`${marker.lat}-${marker.lng}`}
//                 position={{ lat: marker.lat, lng: marker.lng}}
//                 />
//             ))}
//         </GoogleMap>
//     );
// }

// export default Map;
