import React from "react";
import styled from "styled-components";

export const Tuso = styled.div`
    min-width: 300px;
    /* max-width: 1500px; */
    /* min-height: 150vh; */
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
`;

export const Session = styled.div`
    min-width: 300px;
    max-width: 1300px;
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: row; */
    justify-content: space-evenly;
    /* flex-wrap: wrap; */
    /* max-width: 1500px; */
    margin-bottom: 40px;
    
    @media screen and (max-width: 480px) {
        margin-bottom: 0px;
        /* text-align: center; */
    }
`;

export const TitleSession = styled.h2`

    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    /* line-height: 64.8px; */
    margin: 50px 50px;

    /* identical to box height, or 65px */

    letter-spacing: -0.01em;

    color: #1e272f;

    @media screen and (max-width: 480px) {
        font-size: 36px;
        margin: 30px 37px;
        margin-bottom: 0px;
    }
    
`;

export const At = styled.h2`

    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    /* line-height: 64.8px; */
    margin: 50px 50px;
    margin-top: -3.5%;

    /* identical to box height, or 65px */

    letter-spacing: -0.01em;

    color: #1e272f;

    @media screen and (max-width: 480px) {
        font-size: 36px;
        margin: 30px 37px;
        margin-bottom: 0px;
    }
    
`;

export const TextSession = styled.text`
    display: flex;
    padding: 32px;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.01em;
    margin: -40px 10px;
    color: #787d82;

    @media screen and (max-width: 480px) {
        font-size: 15px;
        margin: 0px 17px;
        padding: 10px 25px;
    }
    
`;

export const Sub = styled.h2`

display: flex;
    padding: 30px;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.01em;
    margin: 15px 10px -40px;
    color: #1e272f;

    @media screen and (max-width: 480px) {
        font-size: 15px;
        margin: 0px 17px;
        padding: 10px 25px;
    }
    
`;

export const Sub2 = styled.h2`

display: flex;
    padding: 30px;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.01em;
    margin: -40px 10px -40px 50px;
    color: #1e272f;

    @media screen and (max-width: 480px) {
        font-size: 15px;
        margin: 0px 17px;
        padding: 10px 25px;
    }
    
`;

export const Text = styled.text`
    display: flex;
    padding: 32px;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.01em;
    margin: -60px 10px -40px 80px;
    color: #1e272f;

    @media screen and (max-width: 480px) {
        font-size: 15px;
        margin: 0px 17px;
        padding: 10px 25px;
    }
    
`;

export const email = "comercial@ibsaudio.com.br";

const Emailibs = <a style=
    {{
        color: "#1E272F",
        fontFamily: "Rubik",
        fontWeight: "700",
        textDecoration: "none",
        paddingLeft: "7px"
    }}
    href={`mailto:${email}?body=Vim%20pelo%20site`}>{email}</a>;

    const Data = '29 Nov 2023 09:33:25 (Horário Padrão de Brasília)';

const TermosdeUso: React.FC = () => {
    return (
        <>
            <Tuso>
                <Session>
                    <div style={{ flex: 1, minWidth: "300px" }}>
                        <TitleSession>Termos de Uso do Aplicativo IBS</TitleSession>
                        <At>Última atualização: {Data}</At>
                        <TextSession>
                            Estes Termos de Uso ("Termos") regem o uso do aplicativo IBS (nome fictício). Ao acessar ou utilizar o aplicativo, você concorda com estes Termos. Se você não concordar com estes Termos, por favor, não use o aplicativo.
                        </TextSession>
                        <Sub>1. Uso do Aplicativo</Sub>
                        <Sub2>1.1 Permissão de Uso:</Sub2>
                        <Text>
                            Concedemos a você uma licença limitada, não exclusiva, intransferível e revogável para usar o aplicativo IBS estritamente de acordo com estes Termos.
                        </Text>
                        <Sub2>1.2 Restrições de Uso:</Sub2>
                        <Text>
                            Você concorda em não reproduzir, distribuir, modificar, criar obras derivadas, realizar engenharia reversa ou descompilar o aplicativo, nem tentar acessar áreas não autorizadas do aplicativo.
                        </Text>
                        <Sub>2. Registro e Conta do Usuário</Sub>
                        <Sub2>2.1 Registro de Conta:</Sub2>
                        <Text>
                            Ao criar uma conta, você concorda em fornecer informações precisas, atualizadas e completas. Você é responsável por manter a confidencialidade de suas credenciais de conta.
                        </Text>
                        <Sub2>2.2 Uso Responsável:</Sub2>
                        <Text>
                            Você concorda em usar o aplicativo de maneira que não viole leis aplicáveis ou prejudique a segurança e integridade do aplicativo ou de terceiros.
                        </Text>
                        <Sub>3. Propriedade Intelectual</Sub>
                        <Sub2>3.1 Direitos de Propriedade:</Sub2>
                        <Text>
                            O aplicativo IBS e todo o conteúdo associado são protegidos por direitos autorais, marcas registradas e outras leis de propriedade intelectual.
                        </Text>
                        <Sub2>3.2 Licença Limitada:</Sub2>
                        <Text>
                            Ao aceitar estes Termos, concedemos uma licença limitada para usar o aplicativo de acordo com estes Termos. Nenhum direito, titularidade ou interesse é transferido para você além do expressamente declarado nestes Termos.
                        </Text>
                        <Sub>4. Responsabilidades e Limitações</Sub>
                        <Sub2>4.1 Uso por Sua Conta e Risco:</Sub2>
                        <Text>
                            O uso do aplicativo é por sua conta e risco. Não garantimos que o aplicativo seja livre de erros ou que atenda a todos os requisitos.
                        </Text>
                        <Sub2>4.2 Limitação de Responsabilidade:</Sub2>
                        <Text>
                            Em nenhuma circunstância seremos responsáveis por danos diretos, indiretos, incidentais, especiais, consequenciais ou punitivos decorrentes do uso do aplicativo.
                        </Text>
                        <Sub>5. Modificações nos Termos</Sub>
                        <Sub2></Sub2>
                        <Text>
                            Reservamo-nos o direito de modificar ou atualizar estes Termos a qualquer momento. Recomendamos revisar periodicamente. O uso contínuo do aplicativo após a publicação de alterações constitui aceitação dessas alterações.
                        </Text>
                        <Sub>6. Encerramento</Sub>
                        <Sub2></Sub2>
                        <Text>
                            Reservamo-nos o direito de encerrar ou suspender sua conta e acesso ao aplicativo, a nosso critério, sem aviso prévio.
                        </Text>
                        <Sub>7. Contato</Sub>
                        <Sub2></Sub2>
                        <Text>
                            Para dúvidas ou preocupações relacionadas à privacidade, entre em contato conosco pelo e-mail {Emailibs}.
                        </Text>
                        <TextSession>
                            Ao utilizar o aplicativo IBS, você concorda com estes Termos de Uso.
                        </TextSession>
                    </div>
                </Session>
            </Tuso>
        </>
    );
};

export default TermosdeUso;
