import React, { useMemo } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useSliderContext } from "../../../contexts/currentSlide";

const Page = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 480px) {
        height: 100vw;
    }
`;

const Text = styled.h1`
    font-family: "Rubik";
    font-weight: 500;
    font-size: 32px;
    line-height: 135%;
    color: #1C70BB;

    @media screen and (max-width: 480px) {
        font-size: 28px;
    }
`;

export const Div = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    
    @media screen and (max-width: 480px) {
    }
`;

export const DivTexto = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    margin-top: 3%;
    
    @media screen and (max-width: 480px) {
    }
`;

export const Container = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 30%;
    padding-bottom: 100px;
    width: 90%;

    @media screen and (max-width: 480px) {
        margin-top: 50px;
        padding-bottom: 50px;
    }
`;

const ButtonSalvar = styled.button`
    width: 335px;
    height: 69px;
    background: #EAEDF6;
    color: #4B5259;
    border-radius: 4px;
    position: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Rubik';
    font-size: 16px;
    border: 0;
    cursor: pointer;

    @media screen and (max-width: 480px) {
        min-width: 100px;
        max-width: 150px;
        min-height: 35px;
        max-height: 60px;
        font-size: 12px;
    }
`;

export const NavIten = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-width: 20px;
    z-index: 3;

    font-family: "Rubik";
    font-weight: 500;
    font-style: normal;
    font-size: 18px;
    line-height: 145%;
    text-decoration: none;
    color: #fff;

    @media screen and (max-width: 480px) {
        min-width: 15px;
        font-size: 14px;
    }
`;

const TextVerificacao = styled.h1`
    font-family: "Rubik";
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    color: #4B5259;
    margin-left: 1%;

    @media screen and (max-width: 480px) {
        font-size: 28px;
    }
`;

export const DivVerificacao = styled.div`
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: flex-start;
    width: 95%;
    gap: 0.5%;
    
    @media screen and (max-width: 480px) {
    }
`;

export const Div2 = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    /* margin-top: 3%; */
    flex-direction: column;
    
    @media screen and (max-width: 480px) {
    }
`;

export const Cor = styled.div<{ bg?: string }>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 25px;
    height: 25px;
    border-radius: 25px;
    background: ${({ bg }) => (bg ? bg : "#FFFFFF")};
    /* margin: 0 0.5%; */
    
    @media screen and (max-width: 480px) {
    }
`;

const FinalizarPulsadorPage: React.FC = () => {
    const {
        slider: currentSlide,
        keypads,
      } = useSliderContext();

      const cenaNome = useMemo(
        () => keypads[currentSlide]?.cenaNome || "",
        [currentSlide, keypads]
      );
    
      const quantidade = useMemo(
        () => keypads[currentSlide]?.quantidade || 0,
        [currentSlide, keypads]
      );
    
      const quantBtn = useMemo(
        () => keypads[currentSlide]?.quantBtn || 0,
        [currentSlide, keypads]
      );
    
      const tipo = useMemo(
        () => keypads[currentSlide]?.tipo || "",
        [currentSlide, keypads]
      );

      const colorBtn = useMemo(
        () => keypads[currentSlide]?.colorBtn || "",
        [currentSlide, keypads]
      );

      const colorEspelho = useMemo(
        () => keypads[currentSlide]?.colorEspelho || "",
        [currentSlide, keypads]
      );

      const url = `https://wa.me//+5511958746055/?text=${encodeURIComponent(
                            "Olá, vim pelo site da Xcene," + 
                                "\r\n" +
                                "esse é o meu pedido " +
                                "\r\n" +
                                "."
                        )}&type=phone_number`;
// window.open(url, "_blank"); //@ts-ignore

    //   console.log('keypads: ', keypads);
    //   console.log(cenaNome);
    //   console.log(quantidade);
    //   console.log(quantBtn);
    //   console.log(tipo);
    //   console.log(colorBtn);
    //   console.log(colorEspelho);

    return (
        <>
            <Page>
                <Div>
                    <DivTexto>
                        <Text>Verifique se seu pedido está correto e clique em finalizar</Text>
                    </DivTexto>
                    <Div2>
                        {keypads?.map((e) => {
                            const {
                                cenaNome, colorBtn, colorEspelho, quantBtn, quantidade, textBtn, tipo
                            } = e;
                            return (
                                <DivVerificacao>
                                    <DivVerificacao style={{width: "19%", gap: "3%"}}>
                                        <TextVerificacao>Espelho: </TextVerificacao>
                                        <Cor bg={colorEspelho}/>
                                        <TextVerificacao>Botões: </TextVerificacao>
                                        <Cor bg={colorBtn}/>
                                    </DivVerificacao>
                                    <TextVerificacao>
                                        {quantidade} {tipo} com {quantBtn} {quantBtn > 1 ? "botões" : "botão"}
                                    </TextVerificacao>
                                    {/* <TextVerificacao>
                                        {quantidade} {tipo}
                                    </TextVerificacao>
                                    <Cor bg={colorEspelho}/>
                                    <TextVerificacao>
                                        com {quantBtn} {quantBtn > 1 ? "botões" : "botão"}
                                    </TextVerificacao>
                                    <Cor bg={colorBtn}/> */}
                                </DivVerificacao>
                            )
                        })}
                        {/* {keypads.map(e =>)} */}
                        {/* <h1>{keypads.length} {keypads.length > 1 ? "keypads" : "keypad"}</h1> */}
                        {/* {quantidade} */}
                    </Div2>
                </Div>
                <Container>
                    <ButtonSalvar onClick={() => {window.open(url, "_blank");}}>Salvar e Finalizar</ButtonSalvar>
                </Container>
            </Page>
        </>
    );
};

export default FinalizarPulsadorPage;
