import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import DropDown, { categoriasType } from "./Dropdown";
import Drop from '../../../assets/Drop.svg'
import { getCategorys } from "../../../controllers/getProducts";

export const Btn = styled.button`
   display: flex;
   align-items: center;
   justify-content: space-between;
   position: absolute;
   /* width: 19vw; */
   min-width: 250px;
   max-width: 500px;
   height: 3.15vw;
   padding: 0 1.3vw;
   color: #333;
   background-color: #F2F7FD;
   font-family: "Rubik";
   font-weight: 400;
   font-size: 16px;
   line-height: 160%;
   text-align: left;
   white-space: nowrap;
   vertical-align: middle;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   border: 0;
   border-radius: 4px;
   cursor: pointer;

    :hover {
    background-color: #F2F7FD;
    }
    .active {
      background-color: #F2F7FD;
    }

    @media screen and (max-width: 480px) {
      width: 115px;
      height: 30px;
      font-size: 12px;
    }
`;

const Div = styled.div`
  /* width: 19vw; */
  min-width: 250px;
  max-width: 500px;
  height: 3.15vw;
  /* padding: 0 1.3vw; */

  @media screen and (max-width: 480px) {
    width: 115px;
    height: 30px;
  }
`;

const Text = styled.h3`
  font-family: 'Rubik';
  font-weight: 400;
  font-size: 16px;
  line-height: 0%;
  
  @media screen and (max-width: 480px) {
    font-size: 12px;
    flex-wrap: wrap;
  }
`;

const Input = styled.input`
  background: transparent; 
  border: 0 none; 
  font-family: 'Rubik'; 
  font-size: 16px; 
  font-weight: 400; 
  outline: 0;

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
`;

// const firestore = getFirestore(initializeApp(firebaseConfig));

const DropD = <img src={Drop} style={{ width: "1vw" }} alt=" " />;

interface DropdownProps {
  onChangeCategoria?: (categoria: string) => void;
  // categ: string;
};

const Dropdown: React.FC<DropdownProps> = ({ onChangeCategoria, 
  // categ
  // cate
 }): JSX.Element => {

  // const product = useLoaderData() as Products;
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  // const [showInput, setShowInput] = useState<boolean>(false);
  const [selectCategoria, setSelectCategoria] = useState<categoriasType>();
  const [categorias, setCategorias] = useState<categoriasType[]>([]);
  const [catNew, setCatNew] = useState<string>('');
  // const [categorys, setcategorys] = React.useState<Array<[string]>>;

  const CategoriaSelection = (categoria: categoriasType): void => {
    setSelectCategoria(categoria);
    onChangeCategoria?.(categoria.label);
  };

  const AddNew = <Input type="text" key={"AddNewCat"} value={catNew} placeholder="Adicionar nova categoria" onChange={e => {
    setCatNew(e.currentTarget.value);
    onChangeCategoria?.(e.currentTarget.value);
    setShowDropDown(false)
  }} />;

  useEffect(() => {
    getCategorys().then((cat) => {
      const arratCat: categoriasType[] = cat.map(e => ({
        label: e,
        element: <Text>{e}</Text>
      }));
      setCategorias(arratCat);
    });
  }, []);

//   useEffect(() => {
//     setCategorias( arratCat.keys(categ).map((key) => {
//         return [key, categ[key]]
//     }))
// }, [categ])

  useEffect(() => {
    let category: string = ""
    categorias.forEach((e) => {
        category = "e";
    })
    onChangeCategoria?.(category);
  }, [categorias, onChangeCategoria]);


  const toggleDropDown = () => {
    setShowDropDown(!showDropDown);
    // setShowInput(false);
  };

//   const {category} = product;

  // const catt = () => {
  //   if(category == undefined){
  //     category == ""
  //   } else {
  //     category == category
  //   }
  // }
// const categoriaSelecionada: categoriasType[] =  ({
//   label: category,
//   element: <Text>{category}</Text>
// })
  /**
   * Hide the drop down menu if click occurs
   * outside of the drop-down element.
   *
   * @param event  The mouse event
   */
  const dismissHandler = (event: React.FocusEvent<HTMLButtonElement>): void => {
    if (event.currentTarget === event.target) {
      setShowDropDown(false);
    }
  };

  const arrayAddLement = useMemo(() => [...categorias, {
    label: 'Adicionar nova categoria',
    element: selectCategoria?.label ? AddNew : 'Adicionar nova categoria',
    // element: 'Adicionar nova categoria' ? AddNew : selectCategoria?.label,
  }], [AddNew, categorias, selectCategoria])

  return (
    <>
      <Div>
        <Btn
          type="button"
          className={showDropDown ? "active" : undefined}
          onClick={(): void => toggleDropDown()}
          onBlur={(e: React.FocusEvent<HTMLButtonElement>): void =>
            dismissHandler(e)
          }
        >
          <div style={{ fontFamily: "Rubik" }}>
            {/* {selectCategoria} */}
            {/* {selectCategoria?.element} */}
            {
              selectCategoria && arrayAddLement.find(e => e.label === selectCategoria.label)?.element
            }
          </div>
          {/* <div style={{fontFamily: "Rubik"}}>{ selectCategoria ? selectCategoria?.label : selectCategoria }</div> */}
          {DropD}
          {showDropDown && (
            <DropDown
              categorias={arrayAddLement}
              showDropDown={false}
              toggleDropDown={(): void => toggleDropDown()}
              categoriaSelection={CategoriaSelection}
            />
          )}
        </Btn>
      </Div>
    </>
  );
};

export default Dropdown;