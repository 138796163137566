import styled from "styled-components";
import { Link } from "react-router-dom";

export const Revenda = styled.div`
    display: grid;
    justify-content:center;

    @media screen and (max-width: 480px) {
        min-width: 200px;
        max-width: 460px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 400px;
        max-width: 700px;
        align-items: center;
        padding: 0 10%;
        /* margin-left: 5%; */
        /* display: flex;
        flex-direction: column; */
        /* justify-content: baseline; */
    }
`;

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
    padding-bottom: 10%;

    @media screen and (max-width: 480px) {
        margin-top: 10%;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        margin-top: 20%;
    }
`;

export const Text = styled.h3`
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #1C70BB;

    @media screen and (max-width: 480px) {
        font-size: 10px;
        flex-wrap: wrap;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 14px;
    }
`;

export const NavIten = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-width: 20px;
    z-index: 3;

    font-family: "Rubik";
    font-weight: 500;
    font-style: normal;
    font-size: 18px;
    line-height: 145%;
    text-decoration: none;
    color: #fff;

    @media screen and (max-width: 480px) {
        min-width: 15px;
        font-size: 14px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        /* min-width: 16px; */
        font-size: 17px;
    }
`;