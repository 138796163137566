import React, { useMemo } from 'react';
import styled from 'styled-components';
import logo from '../../assets/logo.png';
import { Link, useLoaderData } from 'react-router-dom';
import Arrow from '../../assets/Arrow.svg';
// import BarraPesquisa from '../../components/pesquisa/Search/barraPesquisa';
import Products from '../../controllers/getProducts';
import ProductsPage from '../products';
import BarraPesquisa from './pesquisaEdt';
// import Products from '../../controllers/getProducts';

const PaginaEdt = styled.div`
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* padding: 0 8vw 8vw 8vw; */
`;

const BtnAddEdt = styled.button`
    display: flex;
    width: 11.8vw;
    height: 4.5vw;
    border-radius: 4px;
    background: #1C70BB;
    /* text-align: center; */
    align-items: center;
    justify-content: center;
    border: 0;
    cursor: pointer;
    
    :hover, :focus {
        background: #1C70BB;
        color: #1C70BB;
        /* transform: translateY(-0.25em); */
    }

    @media screen and (max-width: 480px) {
        width: 60px;
        height: 40px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        /* width: 600px; */
        width: 90px;
        height: 40px;
    }
`;

const Texto = styled.h3`
    font-family: 'Rubik';
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;

    @media screen and (max-width: 480px) {
        font-size: 11px;
    }
`;

// const Img = styled.div`
//     display: flex;
//     flex-direction: column;
//     width: 56.3vw;
//     /* 84.65vw;  */
//     height: 32.45vw;
//     /* 37.75vw; */
//     background: #F5F7F9;
//     /* background: yellow; */
//     box-shadow: -8px 26px 40px rgba(131, 155, 177, 0.08);
//     align-items: center;
//     justify-content: center;
// `;

// const Image = styled.div`
//     display: flex;
//     width: 16.2vw;
//     height: 15.9vw;
//     background: #F5F7F9;
//     /* background: yellow; */
//     align-items: center;
//     justify-content: center;
//     box-shadow: -8px 26px 40px rgba(131, 155, 177, 0.08);
// `;

const Text = styled.h4`
    font-family: 'Rubik';
    font-size: 14px;
    font-weight: 400;
    line-height: 0%;
    color:#4B5259;

    @media screen and (max-width: 480px) {
        font-size: 11px;
    }
`;

const Title = styled.h3`
    font-family: 'Rubik';
    font-size: 20px;
    font-weight: 600;
    color: #9CAFC1;
    line-height: 135%;
    /* text-align: center; */

    @media screen and (max-width: 480px) {
        font-size: 15px;
    }
`;

// const Input = styled.input`
//     background: #F2F7FD;
//     height: 3.15vw;
//     border-radius: 10px;
//     padding: 0 1.5vw;
//     border: 0;
//     outline: 0 none;
//     font-family: 'Rubik';
//     font-size: 14px;
// `;





export const NavIten = styled(Link)`
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    min-width: 20px;
    z-index: 3;

    font-family: "Rubik";
    font-weight: 500;
    font-style: normal;
    font-size: 18px;
    line-height: 145%;
    text-decoration: none;
    color: #fff;

    @media screen and (max-width: 480px) {
        font-size: 14px;
    }
`;

// // const Container = styled.div`
// //     width: 22.65vw;
// //     height: 28.25vw;
// //     background: rgba(255, 255, 255, 0.8);
// //     box-shadow: 0px 179px 72px rgba(0, 0, 0, 0.01), 0px 101px 60px rgba(0, 0, 0, 0.05), 0px 45px 45px rgba(0, 0, 0, 0.09), 0px 11px 25px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
// //     border-radius: 67px;
// //     display: flex;
// //     flex-direction: column;
// //     justify-content: center;
// //     align-items: center;
// //     margin-bottom: 4vw;
// // `;

// export function AddProduto() {
//     const product = useLoaderData() as Products | undefined;


//     // if (!product) {
//     //     return (
//     //         <>
//     //             <>Produto nao existe</>
//     //         </>
//     //     )
//     // }



//     const {
//         name,
//         variants,
//         variants: color,
//         variants: img,
//         quant,
//         preco,
//         category,
//         esp,
//         datasheets,
//         desc,
//         imgs,
//         id,
//     } = product;

//     console.log({
//         product
//     });

const ProductContainer = styled.div`
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 1% 0 1.5% 0;

`;

const EditarProduto: React.FC = ({ }) => {
    const productsList = useLoaderData() as Products[];

    const categorys = useMemo(() => {
        const cat: Record<string, Array<Object>> = {};

        productsList.forEach(({ category, ...rest }) => {
            const ct = category || 'Outros';
            if (cat[ct] === undefined) {
                cat[ct] = [];
            }
            cat[ct].push(rest);
        });
        return cat;
    }, [productsList]);
    console.log(categorys);

    const handleClick = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };
    
    return (
        <>  
            <PaginaEdt>
                <div style={{
                    display: "flex", 
                    padding: "3vw 30vw 5vw 3.5vw", 
                    // padding: "30px 300px 50px 35px", 
                    justifyContent: "space-between"
                }}>
                    <Link to="/" ><img src={logo} alt="" style={{
                        width: "", 
                        height: "4.2vw", 
                        justifyContent: "initial"
                    }} /></Link>
                    <div style={{
                        display: "flex", 
                        alignItems: "center", 
                        width: "24.8vw", 
                        height: "5.4vw", 
                        background: "#FAFBFF",
                        // background: "red",
                        justifyContent: "center",
                        borderRadius: "4px",
                        gap: "0.4vw"
                    }}>
                        <Link to="../new" style={{ textDecoration: "none" }}>
                            <BtnAddEdt style={{background: "#FAFBFF"}}>
                                <Texto style={{color: "#787D82"}}>Adicionar Produto</Texto>
                            </BtnAddEdt>
                        </Link>
                        
                        <Link to="../edit" style={{ textDecoration: "none" }}>
                            <BtnAddEdt>
                                <Texto>Editar Produto</Texto>
                            </BtnAddEdt>
                        </Link>
                    </div>
                </div>
                <ProductContainer>
                    <BarraPesquisa productsList={productsList} categorys={Object.keys(categorys)} />
                </ProductContainer>
                <div style={{display: "flex", padding: "3.5vw 8vw", justifyContent: "space-between"}}>
                    <NavIten  onClick={handleClick} to="/" style={{ display: "flex", textDecoration: "none" }}>
                        <img style={{ transform: "matrix(-1, 0, 0, -1, 0, 0)" }} src={Arrow} alt="Arrow" />
                        <Text style={{fontSize: "16px", lineHeight: "160%", color: "#1C70BB", fontWeight: 500}}>Voltar para a página inicial</Text>
                    </NavIten>
                </div>
            </PaginaEdt>
        </>
        )
    }

export default EditarProduto;





