import { collection, doc, getDoc, getDocs, getFirestore, query, setDoc, updateDoc, where } from "firebase/firestore";
// import Filters from "../components/pesquisa/Filters/Filters";
// import { useState } from "react";

const db = getFirestore();

type Optional<T> = { [K in keyof T]?: T[K] | undefined };

export default interface Products {
    id?: string | null;
    category: string;
    name: string;
    desc: string;
    modelo: string;
    quantTeclas?: number;
    persianas: string;
    color?: Array<string>;
    // esp: Array<string>;
    // esp: Record<string, string>;
    // esp: Record<string, {
    //     esp1: string,
    //     esp2: string;
    // }>;
    imgs: Array<string>;
    // quant: string;
    // preco: string;
    datasheets: Record<string, string>;
    // variants: Array<string>;
    variants: Array<{
        color: string;
        img: Array<string>;
    }>;
}

export const getAllProducts = async () => {
    console.log("getAllProducts");
    const ref = collection(db, "products");
    const listProducts = await getDocs(ref);
    return listProducts.docs.map((data) => ({ id: data.id, ...data.data() } as Products));
};

export const getProductByID = async (id: string) => {
    console.log("getProductByID");
    const ref = doc(collection(db, "products"), id);
    const listProducts = await getDoc(ref);
    return listProducts;
};

export const setProductByID = async (id: string, product: Optional<Products>) => {
    console.log("setProductByID");
    const ref = doc(collection(db, "products"), id);
    await setDoc(ref, product);
};

export const setProduct = async (product: Products) => {
    console.log("setProduct");
    const ref = doc(collection(db, "products"));
    await setDoc(ref, product);
};

export const editProduct = async (id: string, product: Optional<Products>) => {
    console.log("editProduct");
    const ref = doc(collection(db, "products"), id);
    await updateDoc(ref, product);
};

export const getCategorys = async () => {
    console.log("getCategorys");
    const queryRef = query(collection(db, "products"), where("category", "!=", false));
    const snap = await getDocs(queryRef);
    const categorys = snap.docs.map((e) => e.data().category);
    // console.log(categorys);

    return categorys.filter((item, pos, a) => {
        return a.indexOf(item) == pos && item != "" && item;
    });
};

export const getModelos = async (category?: string) => {
    console.log("getModelos");
    const queryRef = query(collection(db, "products"), category ? where("category", "==", category) : where("category", "!=", false)); //&& where("modelo", "!=", false)
    const snap = await getDocs(queryRef);
    const modelos = snap.docs.map((e) => e.data().modelo);
    console.log("modelos: ", modelos);

    return modelos.filter((item, pos, a) => {
        return a.indexOf(item) == pos && item != "" && item;
    });
};

export const getColors = async (category?: string) => {
    console.log("getColors");
    const queryRef = query(collection(db, "products"), category ? where("category", "==", category) : where("category", "!=", false)); //&& where("modelo", "!=", false)
    const snap = await getDocs(queryRef);
    const colors = snap.docs.map((e) => e.data().variants);
    // const color = colors.map(e => e.color);
    // console.log("colors: ", colors);
    // console.log("color: ", color);

    return colors.filter((item, pos, a) => {
        return a.indexOf(item) == pos && item != "" && item;
    });
};

export const getVar = async () => {
    try {
        const snap = await getDocs(collection(db, "products"));
        const variantss = snap.docs.map((e) => e.data().variants) as Array<Products["variants"]>;
        let cores: Array<string> = [];
        variantss
            .map((e) => e?.map?.((b) => b.color))
            .forEach((e) => {
                if (e) {
                    cores = [...cores, ...e];
                }
            });
        cores = cores?.filter((item, pos, a) => {
            return a.indexOf(item) == pos && item != "" && item;
        });
        return cores;
    } catch (error) {
        console.error(error);
    }
    return [];
};
