import React from "react";
// import IWidget from "../../components/pesquisa/Widget/IWidget";
import Products from "../../controllers/getProducts";
import styled from "styled-components";
import Button from "../../components/button";
import { NavIten } from "../addProduto";
// import { getFirestore } from "firebase/firestore";
// import { initializeApp } from "firebase/app";
// import { firebaseConfig } from "../../middlewares/firebase";

const Container = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 260px;
  height: 290px;
  border-radius: 9px;
  background: #F5F7F9;
  box-shadow: 0px 6px 24px rgba(131, 155, 177, 0.12);

  @media screen and (max-width: 480px) {
    width: 66.5vw;
    height: 73.5vw;
  }

  @media screen and (min-width: 481px) and (max-width: 890px) {
    /* width: 600px; */
    min-width: 200px;
    max-width: 370px;
  }
`;

const Produto = styled.h2`
  font-family: 'Rubik';
  font-size: 20px;
  font-weight: 500;
  line-height: 100%;
  color: #1E272F;

  @media screen and (max-width: 480px) {
    font-size: 25px;
  }
`;

const Btn = styled(Button)`
  width: 8.4vw;
  /* min-height: 2vw; */
  height: 40px;
  border-radius: 4px;
  background: #202E59;

  @media screen and (max-width: 480px) {
    width: 120px;
    height: 35px;
  }

  @media screen and (min-width: 481px) and (max-width: 890px) {
    /* width: 600px; */
    min-width: 130px;
    max-width: 200px;
    height: 40px;
  }
  
  span{
    color: #FAFBFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 100%;
  }

  :hover {
    background-color: #1b3480;
    border-color: transparent;
    color: #444
  }
`;

const Div = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 180px;
  width: 100%;
  align-items: center;
  /* background-color: red; */

  @media screen and (max-width: 480px) {
    max-height: 45vw;
    justify-content: center;
    margin-bottom: 5%;
  }
`;

const Img = styled.img`
  max-width: 90%; 
  max-height: 90%;
  background-size: cover; 
  background-position: center;
  object-fit: cover;
`;

const Div1 = styled.div`
  display: flex; 
  flex-direction: column; 
  justify-content: flex-end; 
  align-items: center;
  padding-bottom: 6%;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;
  /* background-color: red; */

  @media screen and (max-width: 480px) {
    padding: 5% 0;
  }

  @media screen and (max-width: 300px) {
    padding: 2% 0;
  }
`;

const DiV = styled.div`
  display: inline-table;
  padding: 2%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* background-color: red; */

  @media screen and (max-width: 480px) {
    padding: 5vw 1.8vw;
  }
`;

// const firestore = getFirestore(initializeApp(firebaseConfig));

export function ProductCard(props: Products) {

  const handleClick = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  const {
    name,
    // category,
    imgs = [0, 1, 2],
    id,
  } = props;
  return (
    <>
      <DiV>
        <Container>
          <Div1>
            <Div>
              <Img src={String(imgs[0])} alt="" />
            </Div>
            <div 
            >
              <Produto>{name}</Produto>
            </div>
            <div style={{
              display: "flex", 
              alignItems: "center", 
              // justifyContent: "end", 
              }}>
              <NavIten to={`../edit/${id}`}>
                <Btn onClick={handleClick} title="Editar Produto"></Btn>
              </NavIten>
            </div>
          </Div1>
        </Container>
      </DiV>
    </>
    
  );
}