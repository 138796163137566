import React from "react";
import "./middlewares/firebase";
import "./App.css";

import Routers from "./routers";

function App() {
    return (
        <React.StrictMode>
            <Routers />
        </React.StrictMode>
    );
}

export default App;
