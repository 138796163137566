import React, { InputHTMLAttributes } from "react";
import styled from "styled-components";
import { ButtonContainer } from "../button";

const InputSession = styled(ButtonContainer)<{ error?: boolean }>`
    /* cursor: alias; */
    border: ${({ error }) => (!error ? "none" : "3px red solid")};
    width: auto;
    margin-inline: 0px;
    :hover::before {
        transform: scaleX(0);
    }
`;

const InpBody = styled.input`
    width: 100%;
    height: 100%;
    border: 0;
    padding: 20px;

    /* Body/regular-16 */

    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    /* identical to box height, or 26px */

    /* Text & icon/100 */

    color: #1e272f;

    @media screen and (max-width: 480px) {
        padding: 3%;
        font-size: 12px;
    }
`;

const Input: React.FC<InputHTMLAttributes<HTMLInputElement> & { error?: boolean }> = ({ error, ...props }) => {
    return <InputSession error={error}>{React.createElement(InpBody, props)}</InputSession>;
};

export default Input;
