import React, { useMemo } from "react";
import Products from "../../../controllers/getProducts";
import styled from "styled-components";
import Button from "../../button";
import { NavIten } from "../../footer/styled";
// import { useNavigate } from "react-router-dom";

// export function WidgetCard(props: IWidget) {
//   const {
//     title,
//     description,
//     rating,
//     id,
//     isSpecialCard,
//   } = props;
//   return (
//     <div className="col-12 p-3">
//       <div className={isSpecialCard ? "card specialCard" : "card"}>
//         <div className="card-body">
//           <h1 className="card-title">{title}</h1>
//           <p className="card-text">{description}</p>
//           <p className="card-text font-italic">Rating: {rating}/10</p>
//         </div>
//         <div className="card-footer text-muted text-right">
//           <span className="float-left">#{id}</span>
//           {/* <Moment fromNow date={created} />, updated:{" "}
//           <Moment fromNow date={updated} /> */}
//         </div>
//       </div>
//     </div>
//   );
// }

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: 33.2vw; */
    min-width: 350px;
    max-width: 450px;
    /* width: 566px; */
    /* height: 36.3vw; */
    min-height: 400px;
    max-height: 500px;
    margin: 15px 0;
    /* height: 515px; */
    /* border-radius: 9px; */
    background: #f5f7f9;
    /* box-shadow: 0px 6px 24px rgba(131, 155, 177, 0.12); */
    /* margin: 2vw 0; */

    /* @media screen and (max-width: 480px) {
        min-width: 100px;
        max-width: 300px;
        min-height: 100px;
        max-height: 300px;
    } */
    @media screen and (max-width: 480px) {
        min-width: 190px;
        max-width: 280px;
        min-height: 265px;
        max-height: 331px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 250px;
        max-width: 350px;
        min-height: 300px;
        max-height: 420px;
    }
`;

const Produto = styled.h2`
    font-family: "Rubik";
    font-size: 20px;
    font-weight: 400;
    line-height: 0%;
    color: #1e272f;
    flex-wrap: wrap;
    /* min-width: 200px; */

    /* @media screen and (max-width: 480px) {
        font-size: 18px;
    } */
     @media screen and (max-width: 480px) {
        font-size: 14px;
        line-height: 0px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 16px;
        line-height: 0%;
    }
`;

const Text = styled.h4`
    font-family: "Rubik";
    font-size: 14px;
    font-weight: 400;
    line-height: 0%;
    color: #787d82;

    @media screen and (max-width: 480px) {
        font-size: 12px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 13px;
    }
`;

// const Preco = styled.h4`
//     font-family: "Rubik";
//     font-size: 16px;
//     font-weight: 500;
//     line-height: 0%;
//     color: #1e272f;

//     @media screen and (max-width: 480px) {
//         font-size: 12px;
//     }
// `;

const Btn = styled(Button)`
    /* width: 6.8vw; */
    min-width: 48px;
    max-width: 100px;
    /* height: 3.2vw; */
    min-height: 40px;
    max-height: 50px;
    border-radius: 4px;
    background: #202e59;

    @media screen and (max-width: 480px) {
        min-width: 40px;
        max-width: 70px;
        min-height: 30px;
        max-height: 45px;
    }

    span {
        color: #fafbff;
        /* font-size: 16px; */
        /* font-weight: 400; */

        @media screen and (max-width: 480px) {
            font-size: 14px;
        }
    }

    :hover {
        background-color: #1b3480;
        border-color: transparent;
        color: #444;
    }
`;

const Div = styled.div`
    display: flex;
    /* padding: 2.15%; */
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
`;

const Div1 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* width: 380px;
    height: 300px; */
    min-width: 300px;
    max-width: 381px;
    min-height: 190px;
    max-height: 310px;

    @media screen and (max-width: 480px) {
        width: 200px;
        height: 200px;
    }

    div {
        display: flex;
        flex-wrap: wrap;
        width: 250px;
        height: 300px;
        /* min-width: 200px;
        max-width: 360px;
        min-height: 200px;
        max-height: 400px; */
        align-items: center;
        justify-content: center;
        overflow: hidden;
        /* background-color: red; */

        @media screen and (max-width: 480px) {
            width: 125px;
            height: 200px;
        }

        img {
            width: 100%;
            height: auto;
            object-fit: cover;

            @media screen and (max-width: 480px) {
                height: 60%;
            }
        }
    }
`;

const Div2 = styled.div`
    display: flex;
    flex-direction: column;
    /* width: 395px; */
    /* padding: 0 15px 20px; */
    /* min-width: 310px;
    max-width: 400px; */
    width: 100%;
    align-items: center;
    justify-content: center;
    /* background-color: blue; */

    @media screen and (max-width: 480px) {
        /* width: 200px;
        padding: 0 8px 10px; */
        min-width: 190px;
        max-width: 280px;
        margin-left: 0;
    }
`;

const Div3 = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    /* gap: 100px; */
    margin: 10px;
    /* background-color: red; */
    width: 95%;

    @media screen and (max-width: 480px) {
        /* gap: 0px; */
    }
`;

const Div4 = styled.div`
    display: flex;
    gap: 2%;
    flex-wrap: wrap;
    min-width: 200px;

    @media screen and (max-width: 480px) {
        min-width: 170px;
    }

     @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 210px;
    }
`;

const A = styled.a`
    font-family: "Rubik";
    font-size: 14px;
    font-weight: 400;
    line-height: 0%;
    color: #498DC9;
    cursor: pointer;
    text-decoration: none;
    text-decoration-color: none;

    @media screen and (max-width: 480px) {
        font-size: 12px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 13px;
    }
`;

export function ProductCard(props: Products) {
    // const navigate = useNavigate();
    const handleClick = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const { name, imgs, id } = props;

    const imgendRend = useMemo(() => imgs.filter((e) => !!e), [imgs]);
    const number = "+5511958746055";

    const Consulta = (
        <A href={`https://wa.me/${number}`} rel="noreferrer" target="_blank">
            Consultar
        </A>
    );

    return (
        <>
            <Div>
                <Container>
                    <Div1>
                        <div>
                            <img src={String(imgendRend[0])} alt="" />
                        </div>
                    </Div1>
                    <Div2>
                        <Div3>
                            <div>
                                {/* <div style={{ display: "flex", flexWrap: "wrap", alignItems: "flex-start", justifyContent: "flex-start" }}> */}
                                <Produto>{name}</Produto>
                                {/* </div> */}
                                {/* <Text>{category}</Text> */}
                                <Div4>
                                    <Text> Quantidade Mínima: </Text>
                                    <Text>{Consulta}</Text>
                                    {/* <Text style={{ color: "#498DC9" }}>{quant}</Text> */}
                                </Div4>
                                <div style={{ display: "flex", gap: "2%" }}>
                                    <Text> Preço: </Text>
                                    <Text>{Consulta}</Text>
                                    {/* <Preco>R${preco}</Preco> */}
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                    justifyContent: "end",
                                }}
                            >
                                <NavIten to={`${id}`}>
                                    <Btn onClick={handleClick} title="Detalhes"></Btn>
                                </NavIten>
                            </div>
                        </Div3>
                    </Div2>
                </Container>
            </Div>
        </>
    );
}

// export function WidgetCard(props: IWidget) {
//   const {
//     image,
//     produto,
//     quantMin,
//     preco,
//     id,
//     isSpecialCard,
//   } = props;
//   return (
//     <div className="col-12 p-3">
//       <div className={isSpecialCard ? "card specialCard" : "card"}>
//         <div className="card-body">
//           <div>
//             <img src={image} alt="" />
//           </div>
//           <div>
//             <h3 className="card-title">{produto}</h3>
//             <p> Quantidade Mínima: {quantMin}</p>
//             <p>Preço: R${preco}</p>
//           </div>
//           <div>
//             <span>#{id}</span>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
