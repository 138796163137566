import styled from "styled-components";

export const PaginaSobreContainer = styled.div`
    min-width: 300px;
    /* max-width: 1500px; */
    /* min-height: 150vh; */
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
`;

export const Session = styled.div`
    min-width: 300px;
    max-width: 1300px;
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: row; */
    justify-content: space-evenly;
    /* flex-wrap: wrap; */
    /* max-width: 1500px; */
    margin-bottom: 40px;
    
    @media screen and (max-width: 480px) {
        margin-bottom: 0px;
        /* text-align: center; */
    }
`;

export const Div = styled.div`
    /* min-width: 300px;
    max-width: 1300px; */
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: row; */
    justify-content: space-between;
    gap: 2%;
    flex-wrap: wrap;
    /* max-width: 1500px; */
    /* margin-bottom: 40px; */
    /* background-color: red; */
    
    @media screen and (max-width: 480px) {
        /* margin-bottom: 0px; */
        /* text-align: center; */
    }
`;

export const TitleSession = styled.h2`

    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 64.8px;
    /* margin: 40px 20px; */

    /* identical to box height, or 65px */

    letter-spacing: -0.01em;

    color: #1e272f;

    @media screen and (max-width: 480px) {
        font-size: 36px;
        margin: 30px 37px;
        margin-bottom: 0px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 38px;
        margin: 30px 36px;
    }
`;

export const TextSession = styled.text`
    display: flex;
    /* padding: 40px; */
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 38.4px;
    letter-spacing: -0.01em;
    /* margin-right: 50px; */
    color: #787d82;
    /* min-width: 300px;
    max-width: 800px; */
    /* width: 565px; */
    /* padding: 0 25px; */
    /* background-color: red; */
    flex-wrap: wrap;
    flex: 1;

    @media screen and (max-width: 480px) {
        font-size: 15px;
        margin: 0px 17px;
        padding: 10px 25px;
    }
    
    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 18px;
        margin: 10px 36px;
    }
`;

export const SessionLocalizacao = styled(Session)`
    display: flex;
    flex-wrap: wrap;
    min-width: 300px;
    max-width: 1500px;
    justify-content: space-evenly;
    flex-direction: row;
    /* ::after { */
        /* content: ""; */
        /* flex-direction: row; */
        //width: 510px;
        /* padding: 0px; */
    
    @media screen and (max-width: 480px) {
        font-size: 15px;
        align-items: center;
    }
`;


export const TitleSessionLocal = styled.h2`
    font-family: "Rubik";
    /* font-style: normal; */
    font-weight: 500;
    font-size: 48px;
    line-height: 64.8px;
    letter-spacing: -0.01em;
    color: #1e272f;
    margin: 50px 50px;

    @media screen and (max-width: 480px) {
        font-size: 36px;
        margin: 30px 36px;
    }
`;

export const TextSessionLocal = styled.text`
    display: flex;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #787d82;
    /* margin: -40px 10px; */
    /* padding: 0 40px; */
    max-width: 510px;
    min-width: 250px;
    /* height: 128px; */

    @media screen and (max-width: 480px) {
        font-size: 15px;
        margin: -10px 10px;
        margin-bottom: 0px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 18px;
        margin: 10px 30px;
        /* margin-bottom: 0px; */
    }
`;

export const Img = styled.img`
    min-width: 400px;
    max-width: 800px;

    @media screen and (max-width: 480px) {
        /* width: 80%; */
        min-width: 200px;
        max-width: 400px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 300px;
        max-width: 500px;
    }
`;

export const SessionParceiros = styled(Session)`
    display: grid;
    padding-top: 80px;
    padding-bottom: 160px;
    gap: 15%;
    /* align-items: center; */
    text-align: center;

    @media screen and (max-width: 480px) {
        gap: 5%;
        padding-bottom: 100px;
        padding-top: 50px;
    }
`;

export const TitleSessionParceiros = styled.h2`
    font-size: 48px;
    font-weight: 500;

    @media screen and (max-width: 480px) {
        font-size: 36px;
    }
`;

export const Parceiros = styled.div`
    display: grid;
    align-items: center;
    padding: 0 50px;

    @media screen and (max-width: 480px) {
        display: flex;
        flex-direction: column;
        padding: 0px;
        justify-content: center;
    }
`;

export const ImgParceiros = styled.img`
    @media screen and (max-width: 480px) {
        width: 70%;
        /* width: 40%;
        height: 40%; */
    }
`;

export const TitleParceiros = styled.h3`
    font-size: 20px;
    font-weight: 500;
    line-height: 140%;
    color: #000;

    @media screen and (max-width: 480px) {
        font-size: 15px;
    }
`;

export const A = styled.a`
    text-decoration: none; 
    color: #1C70BB; 
    font-family: 'Rubik';

    @media screen and (max-width: 480px) {
        font-size: 12px;
    }
`;

// export const SessionNewsletter = styled.div `
//     display: flex;
//     justify-content: start;
//     align-items: center;
//     width: 77.5em;
//     height: 15.69em;
//     background-color: #1E272F;
//     border-radius: 10px;
//     margin-bottom: 8%;
// `;
                                                                                                                                                                                                                                           
// export const Newsletter = styled(Session) `
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     //position: absolute;
//     padding-left: 15%;
//     padding-bottom: 6%;
// `;

// export const TittleNewsletter = styled.h2`
//     font-family: "Rubik";
//     color: #FFFFFF;
//     font-size: 32px;
//     font-weight: 500;
//     margin-bottom: 2%;
// `;

// export const TextNewsletter = styled.text`
//     font-family: "Rubik";
//     color: #EAEDF6;
//     font-size: 16px;
//     font-weight: 400;
//     line-height: 160%;
//     width: 36.4em;
//     height: 4.9em;
// `;

//export const CaixaEmailContainer = styled.div`
//    display: flex;
//    background-color: #FAFBFF;
//    height: 4em;
//    width: 32.5em;
//    border-radius: 4px;
//    //position: absolute;
//    //left: 660px;  //24%;
//    //top: 78px;
//
//`;

// export const EntrarEmail = styled.text`
//     color: aliceblue;
//     padding-right: 5%;
//     padding-top: 6%;
// `;