import React, { useEffect, useState } from 'react';
import styled from "styled-components";
// import Drop from '../../assets/Drop.svg'

export const Drops = styled.div`
  position: absolute;
  top: 115%;
  left: 0;
  padding: 0;
  text-align: left;
  border-radius: 4px;
  padding: 0.6vw 0 1vw 0;
  font-family: 'Rubik';
  max-height: 150px;
  overflow-y: scroll;

  @media screen and (max-width: 480px) {
    /* max-height: 300px; */
  }
`;

export const P = styled.p`
  margin: 0;
  padding: 0.5vw 0 0.5vw 1vw;
  min-width: 8.15vw;
  font-family: 'Rubik';

  @media screen and (max-width: 480px) {
    padding: 5% 0 5% 10%;
    min-width: 100px;
  }
    
  :hover {
  background-color: #e8f2fd;
  }

  :last-child {
  border-bottom: 0 none;
  }
`;

// const DropD = <img style={{ paddingLeft: "3.5em" }} src={Drop} alt=" " /> ;

type DropDownProps = {
  opcoes: string[];
  showDropDown: boolean;
  toggleDropDown: Function;
  opcaoSelection: Function;
};

const DropDown: React.FC<DropDownProps> = ({
  opcoes,
  opcaoSelection,
}: DropDownProps): JSX.Element => {
  const [showDropDown, setShowDropDown] = useState<boolean>(false);

  /**
   * Handle passing the estado name
   * back to the parent component
   *
   * @param estado  The selected estado
   */
  const onClickHandler = (opcao: string): void => {
    opcaoSelection(opcao);
  };

  useEffect(() => {
    setShowDropDown(showDropDown);
  }, [showDropDown]);

  return (
    <>
      <Drops className={showDropDown ? 'dropdown' : 'dropdown active'}>
        {opcoes.map(
          (opcao: string, index: number): JSX.Element => {
            return (
              <P
                key={index}
                onClick={(): void => {
                  onClickHandler(opcao);
                }}
              >
                {opcao}
              </P>
            );
          }
        )}
      </Drops>
    </>
  );
};

export default DropDown;