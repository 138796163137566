import React, { useCallback, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { useSliderContext } from "../../../../../contexts/currentSlide";

const PulsadorBtns = styled.div<{ bg?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ bg }) => (bg ? bg : "#FFFFFF")};
  width: 131.25px;
  height: 268.08px;
  border-radius: 6px;
  box-shadow: #25313d44 4px 3px 7px 3px, #25313d16 -4px 5px 5px 5px;
  border: 5px solid #00000060;

  @media screen and (max-width: 480px) {
    width: 72.6px;
    height: 148.5px;
    border-radius: 3.3px;
    border: 2.75px solid #00000060;
  }
`;

const BtnFalse = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  width: 91.33px;
  height: 31.7px;
  /* border-radius: 8px; */
  border: 2px solid #00000090;
  
  @media screen and (max-width: 480px) {
    width: 50.5px;
    height: 13.75px;
    /* border-radius: 6.6px; */
    border: 2px solid #00000090;
  }
`;

const BtnP = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  width: 91.33px;
  height: 31.7px;
  border-radius: 8px;
  border: 4px solid #00000090;
  
  @media screen and (max-width: 480px) {
    width: 50.5px;
    height: 13.75px;
    border-radius: 6.6px;
    border: 2px solid #00000090;
  }
`;

const BtnM = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  width: 91.33px;
  height: 63.4px;
  border-radius: 8px;
  border: 4px solid #00000090;
  
  @media screen and (max-width: 480px) {
    width: 50.5px;
    height: 13.75px;
    border-radius: 6.6px;
    border: 2px solid #00000090;
  }
  `;

  const BtnG = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    width: 91.33px;
    height: 95.1px;
    border-radius: 12px;
    border: 4px solid #00000090;
  
    @media screen and (max-width: 480px) {
      width: 50.5px;
      height: 52.8px;
      border-radius: 6.6px;
      border: 2.2px solid #00000090;
    }
  `;

const TextPulsadorBtn = styled.input<{ cor?: string }>`
  font-family: "Rubik";
  font-weight: 400;
  font-size: 10px;
  line-height: 0%;
  border: 0;
  text-align: center;
  outline: 0 none;
  width: 90%;
  height: 90%;
  cursor: pointer;
  background: transparent;

  ::placeholder {
    color: ${({ cor }) => (cor ? cor : "")};
  }

  @media screen and (max-width: 480px) {
    font-size: 6px;
    flex-wrap: wrap;
  }
`;

interface AddKeypadProps {
  k4x4?: string;
}

const GBtn: React.FC<{
  colorBtn: string;
  textBtn: Record<string, string>;
  updateTextBtn: (data: Record<string, string>) => void;
  index: number;
}> = ({colorBtn, textBtn, updateTextBtn, index}) => {
  return (
    <BtnG style={colorBtn ? { background: colorBtn } : {}}>
      <TextPulsadorBtn
        style={colorBtn === "#404340" ? { color: "#fff" } : {}}
        cor={colorBtn === "#787D82" ? "#ffffffca" : ""}
        placeholder="Insira um texto"
        value={textBtn[index]}
        onChange={(e) => {
          const newData = textBtn;
          newData[index] = e.currentTarget.value;
          updateTextBtn(newData);
        }}
      />
    </BtnG>
  )
}

const MBtn: React.FC<{
  colorBtn: string;
  textBtn: Record<string, string>;
  updateTextBtn: (data: Record<string, string>) => void;
  index: number;
}> = ({colorBtn, textBtn, updateTextBtn, index}) => {
  return (
    <BtnM style={colorBtn ? { background: colorBtn } : {}}>
      <TextPulsadorBtn
        style={colorBtn === "#404340" ? { color: "#fff" } : {}}
        cor={colorBtn === "#787D82" ? "#ffffffca" : ""}
        placeholder="Insira um texto"
        value={textBtn[index]}
        onChange={(e) => {
          const newData = textBtn;
          newData[index] = e.currentTarget.value;
          updateTextBtn(newData);
        }}
      />
    </BtnM>
  )
}

const PBtn: React.FC<{
  colorBtn: string;
  textBtn: Record<string, string>;
  updateTextBtn: (data: Record<string, string>) => void;
  index: number;
}> = ({colorBtn, textBtn, updateTextBtn, index}) => {
  return (
    <BtnP style={colorBtn ? { background: colorBtn } : {}}>
      <TextPulsadorBtn
        style={colorBtn === "#404340" ? { color: "#fff" } : {}}
        cor={colorBtn === "#787D82" ? "#ffffffca" : ""}
        placeholder="Insira um texto"
        value={textBtn[index]}
        onChange={(e) => {
          const newData = textBtn;
          newData[index] = e.currentTarget.value;
          updateTextBtn(newData);
        }}
      />
    </BtnP>
  )
}

const FBtn: React.FC<{
    colorBtn: string;
    textBtn: Record<string, string>;
    updateTextBtn: (data: Record<string, string>) => void;
  }> = ({colorBtn, textBtn, updateTextBtn}) => {
    return (
      <BtnFalse style={colorBtn ? { background: colorBtn } : {}}>
      </BtnFalse>
    )
  }

const Buttons: React.FC<AddKeypadProps> = ({k4x4}) => {
  const {
    slider: currentSlide,
    keypads,
    updateDateKeypad,
  } = useSliderContext();

  const colorBtn = useMemo(
    () => keypads[currentSlide]?.colorBtn || "",
    [currentSlide, keypads]
  );

  const textBtn = useMemo(
    () => keypads[currentSlide]?.textBtn || undefined,
    [currentSlide, keypads]
  );

  const updateTextBtn = useCallback(
    (textBtn: Record<string, string>) => {
      updateDateKeypad(currentSlide, {
        textBtn,
      });
    },
    [currentSlide, updateDateKeypad]
  );

  const quantBtn = useMemo(
    () => keypads[currentSlide]?.quantBtn || undefined,
    [currentSlide, keypads]
  );

  const tipo = useMemo(
    () => keypads[currentSlide]?.tipo || "",
    [currentSlide, keypads]
  );

  // console.log(textBtn)

  if (quantBtn === 1 || (tipo === "Keypad 4x4" && quantBtn === 2)) {
      // || (tipo === "Keypad 4x4" && quantBtn === 2)
        return (
            <>
                <FBtn colorBtn={colorBtn} textBtn={textBtn} updateTextBtn={updateTextBtn}/>
                <FBtn colorBtn={colorBtn} textBtn={textBtn} updateTextBtn={updateTextBtn}/>
                <GBtn colorBtn={colorBtn} textBtn={textBtn} updateTextBtn={updateTextBtn} index={k4x4 ? 1 : 0}/>
                <FBtn colorBtn={colorBtn} textBtn={textBtn} updateTextBtn={updateTextBtn}/>
                <FBtn colorBtn={colorBtn} textBtn={textBtn} updateTextBtn={updateTextBtn}/>
            </>
        );
    }
    if (quantBtn === 2 || (tipo === "Keypad 4x4" && quantBtn === 4)) {
        return (
            <>
                <GBtn colorBtn={colorBtn} textBtn={textBtn} updateTextBtn={updateTextBtn} index={k4x4 ? 2 : 0}/>
                <FBtn colorBtn={colorBtn} textBtn={textBtn} updateTextBtn={updateTextBtn}/>
                <GBtn colorBtn={colorBtn} textBtn={textBtn} updateTextBtn={updateTextBtn} index={k4x4 ? 3 : 1}/>
            </>
        );
    }
    if (quantBtn === 3 || (tipo === "Keypad 4x4" && quantBtn === 6)) {
        return (
            <>
                <GBtn colorBtn={colorBtn} textBtn={textBtn} updateTextBtn={updateTextBtn} index={k4x4 ? 3 : 0}/>
                <GBtn colorBtn={colorBtn} textBtn={textBtn} updateTextBtn={updateTextBtn} index={k4x4 ? 4 : 1}/>
                <PBtn colorBtn={colorBtn} textBtn={textBtn} updateTextBtn={updateTextBtn} index={k4x4 ? 5 : 2}/>
            </>
        );
    }
    if (quantBtn === 4 || (tipo === "Keypad 4x4" && quantBtn === 8)) {
        return (
            <>
                <MBtn colorBtn={colorBtn} textBtn={textBtn} updateTextBtn={updateTextBtn} index={k4x4 ? 4 : 0}/>
                <MBtn colorBtn={colorBtn} textBtn={textBtn} updateTextBtn={updateTextBtn} index={k4x4 ? 5 : 1}/>
                <MBtn colorBtn={colorBtn} textBtn={textBtn} updateTextBtn={updateTextBtn} index={k4x4 ? 6 : 2}/>
                <PBtn colorBtn={colorBtn} textBtn={textBtn} updateTextBtn={updateTextBtn} index={k4x4 ? 7 : 3}/>
            </>
        );
    }
    // if (quantBtn === 5) {
    //     return (
    //         <>
    //             <MBtn colorBtn={colorBtn} textBtn={textBtn} updateTextBtn={updateTextBtn}/>
    //             <MBtn colorBtn={colorBtn} textBtn={textBtn} updateTextBtn={updateTextBtn}/>
    //             <MBtn colorBtn={colorBtn} textBtn={textBtn} updateTextBtn={updateTextBtn}/>
    //             <PBtn colorBtn={colorBtn} textBtn={textBtn} updateTextBtn={updateTextBtn}/>
    //         </>
    //     );
    // }
    if (quantBtn === 6 || (tipo === "Keypad 4x4" && quantBtn === 12)) {
        return (
            <>
                <PBtn colorBtn={colorBtn} textBtn={textBtn} updateTextBtn={updateTextBtn} index={k4x4 ? 6 : 0}/>
                <PBtn colorBtn={colorBtn} textBtn={textBtn} updateTextBtn={updateTextBtn} index={k4x4 ? 7 : 1}/>
                <PBtn colorBtn={colorBtn} textBtn={textBtn} updateTextBtn={updateTextBtn} index={k4x4 ? 8 : 2}/>
                <PBtn colorBtn={colorBtn} textBtn={textBtn} updateTextBtn={updateTextBtn} index={k4x4 ? 9 : 3}/>
                <PBtn colorBtn={colorBtn} textBtn={textBtn} updateTextBtn={updateTextBtn} index={k4x4 ? 10 : 4}/>
                <MBtn colorBtn={colorBtn} textBtn={textBtn} updateTextBtn={updateTextBtn} index={k4x4 ? 11 : 5}/> 
            </>
        );
    } else {
        return (
            <GBtn colorBtn={colorBtn} textBtn={textBtn} updateTextBtn={updateTextBtn} index={0}/>
        );
    }

};

export default Buttons;
