




// import { ProgressBarComponent } from '@syncfusion/ej2-react-progressbar';
// import * as React from "react";
// import * as ReactDOM from "react-dom";
// import { SampleBase } from '../common/sample-base';
// export class Default extends SampleBase<{}, {}> { 
//   public animation: any = {
//       enable: true,
//       duration: 2000,
//       delay: 0
//   };
//   render() {
//     return (
//         <div>
//             <ProgressBarComponent id="linear" type='Linear' height='60' value={40}
//             animation={this.animation}></ProgressBarComponent>
//         </div>
//     )
//   }
// }



// import * as React from "react";
// import * as ReactDOM from "react-dom";
// import { ProgressBarComponent } from "@syncfusion/ej2-react-progressbar";

// function App() {
//   return(<ProgressBarComponent
//                   id="lineardeterminate"
//                   type="Linear"
//                   height="60"
//                   value={100}
//                   animation={{
//                     enable: true,
//                     duration: 2000,
//                     delay: 0
//                   }}>
//           </ProgressBarComponent>
//         )
// };
// export default App;
// ReactDOM.render(<App />, document.getElementById("container"));




// import * as React from "react";
// import * as ReactDOM from "react-dom";
// import { ProgressBarComponent } from "@syncfusion/ej2-react-progressbar";

// function BarraProgresso() {
//   return(<ProgressBarComponent
//           id="linear"
//           type="Linear"
//           height="60"
//           trackThickness={9}
//           progressThickness={9}
//           // gapWidth={33.5}
//           // trackColor="#EAEDF6"
//           // segmentColor={}
//           cornerRadius="Round"
//           theme="Fluent"
//           // value={33.5}
//           value={0}
//           progressColor="#189877"
//           // secondaryProgress={67}
//           secondaryProgress={33.5}
//           secondaryProgressColor="#A3D6C9"
//           animation={{
//             enable: true,
//             duration: 0,
//             delay: -2000
//           }}>
//       </ProgressBarComponent>
//       )
// };
// export default BarraProgresso;
// // ReactDOM.render(<BarraProgresso />, document.getElementById("container"));





// import React, { useState } from "react";
// import styled from "styled-components";
// import Ok from "../../assets/Ok.svg";
// import PaginaRevenda from "../../routers/revenda/dadosPessoais";
// import PaginaRevendaConclusao from "../../routers/revenda/revendaConclusao";

// export const Conjunto = styled.div`
//     //color: #EAEDF6;
//     display: flex;
//     align-items: center;
//     //justify-content: start;
//     margin: 0 0 3em 1.5em;
// `;

// export const Barra = styled.div`
//     display: flex;
//     height: 1vh;
//     width: 75vw;
//     background: #EAEDF6;
//     /* background: #A3D6C9; */
//     border-radius: 5px;
//     align-items: center;
//     justify-content: unset;
//     /* width: 1260px;
//     height: 8px;
//     align-items: center; */
// `;

// // const Progress = styled.div`
// //     width: progress;
// // `;

// // export const ProgressBar = styled.div`
// //     display: flex;
// //     height: 0.5em;
// //     width: 26.1em;
// //     background: #A3D6C9;
// //     border-radius: 5px;
// //     align-items: center;
// //     justify-content: unset;
// // `;

// // export const Completo = styled.div`
// //     //box-sizing: border-box;
// //     //display: flex;
// //     position: unset;
// //     width: 50px;
// //     height: 8px;
// //     border-radius: 24px;
// //     background: #A3D6C9;
// // `;

// export const Circulo = styled.div`
//     display: flex;
//     justify-content: space-between;
//     gap: 24.6vw;
//     //margin-left: -2em;
// `;

// export const Circle = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     position: unset;
//     width: 24px;
//     height: 24px;
//     border-radius: 24px;
//     background: #CDD7E0;
    
//     //background: #A3D6C9;
//     margin-left: -1em;
// `;

// // // const ProgressBar = ({currentPage} : {currentPage: number}) => {
// // //     const progress = (currentPage / 4) * 100;

// // //     return (
// // //         <Barra>
// // //             <Progress></Progress>
// // //         </Barra>
// // //     );
// // // };


// // // const FormPage = ({ pageNumber, onNext}:{pageNumber: number; onNext: () => void}) => {
    
// // //     const [formData, setFormData] = useState<any>({});

// // //     const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
// // //         const { name, value } = event.target;
// // //         setFormData((prevData: any) => ({ ...prevData, [name]: value }));
// // //     };

// // //     const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
// // //         event.preventDefault();
// // //         onNext();
// // //     };

// // //     return (
// // //         <form onSubmit={handleSubmit}>
// // //             <h2>Página { pageNumber }</h2>
// // //             <PaginaRevenda></PaginaRevenda>
// // //         </form>
// // //     )
// // // };

// // // const Form = () => {
// // //     const [currentPage, setCurrentPage] = useState(1);
// // //     const handleNext = () => {
// // //         setCurrentPage((prevPage) => prevPage + 1);
// // //     };
// // //     return (
// // //         <div>
// // //             <ProgressBar currentPage={currentPage} />
// // //             { currentPage <= 3 ? (
// // //                 <FormPage pageNumber={currentPage} onNext={handleNext} />
// // //             ) : (
// // //                 <div>
// // //                     <PaginaRevendaConclusao/>
// // //                 </div>
// // //             )}
// // //         </div>
// // //     );
// // // };

// // // export default FormPage;

// // // const BarraProgresso = (props) => {
// // //     const { bgcolor, completed} = props;
// // //     return (
// // //         <>
// // //             <Conjunto>
// // //                 <Barra>
// // //                     {{completed}}
// // //                     <Circulo>
// // //                         <div>
// // //                             <Circle style={{marginRight: "-1em"}}></Circle>
// // //                         </div>
// // //                         <div>
// // //                             <Circle>
// // //                                 <img src={Ok} alt="" />
// // //                             </Circle>
// // //                         </div>
// // //                         <div>
// // //                             <Circle>
// // //                                 <img src={Ok} alt="" />
// // //                             </Circle>
// // //                         </div>
// // //                         <div>
// // //                             <Circle>
// // //                                 <img src={Ok} alt="" />
// // //                             </Circle>
// // //                         </div>
// // //                     </Circulo>
// // //                 </Barra>
// // //             </Conjunto>
// // //             {/* <div>
// // //                 <span>{`${completed}%`}</span>
// // //             </div> */}
// // //         </>
// // //     );
// // // };









// const BarraProgresso: React.FC = () => {
//     return (
//         <>
//             <Conjunto>
//                 <Barra>
//                     <div>
//                         {/* <ProgressBar>
//                         </ProgressBar> */}
//                     </div>
//                     <Circulo>
//                         <div>
//                             <Circle style={{marginRight: "-1em"}}></Circle>
//                         </div>
//                         <div>
//                             <Circle>
//                                 <img src={Ok} alt="" />
//                             </Circle>
//                         </div>
//                         <div>
//                             <Circle>
//                                 <img src={Ok} alt="" />
//                             </Circle>
//                         </div>
//                         <div>
//                             <Circle>
//                                 <img src={Ok} alt="" />
//                             </Circle>
//                         </div>
//                     </Circulo>
//                 </Barra>
//             </Conjunto>
            
            
//         </>    
//     );
// };

// export default BarraProgresso;

//display: flex;
//     align-items: center;
//     justify-content: center;
//     position: unset;
//     width: 24px;
//     height: 24px;
//     border-radius: 24px;
//     background: #CDD7E0;


    


import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import styled from "styled-components";
import Vector from '../../assets/Vector.png'
import Ok from '../../assets/Ok.svg'

const Etapa = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: unset;
  width: 30px;
  height: 30px;
  border-radius: 24px;
  background: #A3D6C9;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    width: 20px;
    height: 20px;
  }

  @media screen and (min-width: 481px) and (max-width: 890px) {
    width: 25px;
    height: 25px;
  }
`;

const BarraProgresso = props => {
  var stepPercentage = 0;

  if (props.currentStep === 1) {
    stepPercentage = 0;
  } else if (props.currentStep === 2) {
    stepPercentage = 33.4;
  } else if (props.currentStep === 3) {
    stepPercentage = 67;
  } else if (props.currentStep === 4) {
    stepPercentage = 100;
  } else {
    stepPercentage = 33.4;
  }

 
// class BarraProgresso extends React.Component {
//   render() {
  return (
      <div style={{margin: "1vw 0 3vw"}}>
        <ProgressBar
        percent={stepPercentage}
        filledBackground="#A3D6C9"
        // filledBackground="linear-gradient( to right, #189877 50% , #A3D6C9 50%, #A3D6C9 100% )"  
        unfilledBackground="#EAEDF6"  
        // transitionDuration="0"
        >
          <Step transition="scale">
            {({ accomplished }) => (
              <Etapa className={`Etapa ${accomplished ? "accomplished" : null}`} 
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)`}}
                width="30"
                src=""/>


              // <div className={`indexedStep ${accomplished ? "accomplished" : ""}`}
              //   style={{ filter: `grayscale(${accomplished ? 0 : 100}%)`, display: "flex", alignItems:   "center", justifyContent: "center", position:"unset", width: "30px", height: "30px", borderRadius:  "24px", background: "#A3D6C9" }}
              //   width="30"
              //   src="">
              // </div>
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <Etapa className={`Etapa ${accomplished ? "accomplished" : null}`}
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width="30"
                src=""       >
                  <img src={Ok}></img>
                </Etapa>
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <Etapa className={`Etapa ${accomplished ? "accomplished" : null}`}
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width="30"
                src=""       >
                  <img src={Ok}></img>
                  {/* <img style={{color: "#787D82"}} src={Vector}></img> */}
                </Etapa>
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <Etapa className={`Etapa ${accomplished ? "accomplished" : null}`}
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width="30"
                src=""       >
                  <img src={Ok}></img>
                </Etapa>
            )}
          </Step>
        </ProgressBar>
      </div>
      
  );
};

export default BarraProgresso;