import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import "react-activity/dist/library.css";
import { Link } from "react-router-dom";
import Arrow from "../../../assets/Arrow.svg";
import RootCadastro4 from "../../../components/rootCadastro/rootConclusao";
import { FormContext } from "../root";

export const email = "comercial@xcene.com";

const EmailX = <a style=
    {{
        color: "#1E272F",
        fontFamily: "Rubik",
        fontWeight: "700",
        textDecoration: "none"
    }}
    href={`mailto:${email}?body=Vim%20pelo%20site`}>{email}</a>;

const Conclusao = styled.div`
    display: grid;
    justify-content: center;
`;

const Concluido = styled.div`
    /* padding: 0 17.3vw 0 0; */
    min-width: 850px;
    max-width: 1800px;

    @media screen and (max-width: 480px) {
        min-width: 250px;
        max-width: 350px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 300px;
        max-width: 500px;
    }
`;

const Title = styled.h1`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 135%;
    color: #1C70BB;

    @media screen and (max-width: 480px) {
        font-size: 24px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 28px;
    }
`;

const Texts = styled.h2`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 140%;
    color: #1E272F;

    @media screen and (max-width: 480px) {
        font-size: 18px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 20px;
    }
    
`;

const Email = styled.h2`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    color: #1E272F;
    //padding-right: 15em;
    //margin-right: 19em;
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
`;

const NavIten = styled(Link)`
    display: flex;
    font-family: "Rubik";
    font-weight: 500;
    font-style: normal;
    font-size: 18px;
    line-height: 145%;
    text-decoration: none;
    color: #fff;
    margin-top: 350px;
    padding-bottom: 5px;

    @media screen and (max-width: 480px) {
        font-size: 14px;
        /* margin-top: 450px; */
    }
`;

const Text = styled.h3`
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #1C70BB;

    @media screen and (max-width: 480px) {
        font-size: 12px;
    }
`;

const PaginaRevendaConclusao: React.FC = () => {

    const handleClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const { sendToFormtoDB } = useContext(FormContext);

    const [send, setSend] = useState(false);
    const [sucess, setSucess] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                await sendToFormtoDB();
                setSucess(true);
            } catch (error) {
                setSucess(false);
            }
            setSend(true);
        })();

    }, [sendToFormtoDB]);


    return (
        <>
            <Conclusao>
                <RootCadastro4 />
                <Concluido>
                    {
                        !send ? (
                            <>Enviando</>
                        ) : (
                            <>
                                <div>
                                    <Title>Seu Cadastro foi enviado para análise</Title>
                                </div>
                                <div style={{ display: "flex", marginTop: "-2em" }}>
                                    <Texts>Envie uma cópia do contrato social para o nosso email: {EmailX}</Texts>
                                    {/* <div style={{marginLeft:"0.3em"}}>
                                    <Email>comercial@xcene.com.br</Email>
                                </div> */}
                                </div>
                            </>
                        )
                    }
                </Concluido >

                <Container>
                    <NavIten onClick={handleClick} to="/" style={{ textDecoration: "none" }}>
                        <img style={{ transform: "matrix(-1, 0, 0, -1, 0, 0)" }} src={Arrow} alt="Arrow" />
                        <Text>Voltar para a página inicial</Text>
                    </NavIten>
                </Container>
            </Conclusao >
        </>
    );
};

export default PaginaRevendaConclusao;