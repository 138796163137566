import styled from "styled-components";
import { Link } from "react-router-dom";
import Button from "../button";

export const ContainerNavBar = styled.div<{ fixed?: boolean }>`
    position: fixed;
    position: absolute;
    top: ${({ fixed }) => (fixed ? -100 : 0)};
    top: 0; 
    right: 0; 
    left: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 7rem;
    z-index: 100;
     position: fixed;

    @media screen and (max-width: 480px) {
        height: 0.9rem;
        margin: 0 2rem 0 1rem;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        justify-content: center;
        /* height: 0.9rem;
        margin: 0 2rem 0 1rem; */
    }
`;

export const NavIten = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0.5vw 2vw 0 0;
    font-family: "Rubik";
    font-weight: 500;
    font-style: normal;
    font-size: 18px;
    line-height: 145%;
    text-decoration: none;
    color: #fff;

    :hover, :focus {
        color: #77A9D6;
        transform: translateY(-0.25em);
        border-bottom: 0.2vw solid #77A9D6;
    }

    @media screen and (max-width: 480px) {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 1;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 13px;
    }
`;

export const ContainterBack = styled.div<{ url?: string; fixed?: boolean }>`
    /* background-image: url(${({ url }) => url}); */
    background-repeat: no-repeat;
    display: flex;
    background-size: cover;
    height: 8rem;
    /* max-height: 10em; */
    background-position: bottom;
    overflow: hidden;
    position: relative;
    background-color: #CDD7E04F;

    ::before {
        content: "";
        position: absolute;
        inset: 0;
        background: rgba(119, 169, 214, 0.25);
        min-height: 1rem;
    }

     @media screen and (max-width: 480px) {
        height: 4rem;
    }
`;

export const ContainerSideBar = styled.div`
    z-index: 0;
    opacity: 0.96;
    background: linear-gradient(90.02deg, rgba(255, 255, 255, 0.99) 87.16%, rgba(255, 255, 255, 0) 119.56%);
    display: none;
    justify-content: center;
    align-items: center;

    Button{
        .ContainerButton{
        background: #202E59;
    }
    }
`;

export const ContainterBackMini = styled(ContainterBack)<{ height: number }>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    height: 8rem;
    background: #CDD7E0;

    @media screen and (max-width: 480px) {
        height: 4rem;
    }
`;
