import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import DropDown from "./dropMod";
import Drop from '../../../assets/Drop.svg'
import { categoriasType } from "../dropdownCat/Dropdown";
import { getModelos } from "../../../controllers/getProducts";
// import { useLoaderData } from "react-router-dom";
// import Products from "../../../controllers/getProducts";

 export const Btn = styled.button`
   display: flex;
   align-items: center;
   justify-content: space-between;
   position: absolute;
   /* width: 9vw; */
   min-width: 250px;
   max-width: 500px;
   /* height: 3.5vw; */
   min-height: 40px;
   max-height: 60px;
   padding: 0 1.3vw;
   color: #333;
   background-color: #F2F7FD;
   font-family: 'Rubik';
   font-weight: 400;
   font-size: 16px;
   line-height: 160%;
   text-align: left;
   white-space: nowrap;
   vertical-align: middle;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   border: 0;
   border-radius: 4px;
   cursor: pointer;

   @media screen and (max-width: 480px) {
      min-height: 35px;
      max-height: 45px;
      padding: 0 6px;
      font-size: 14px;
   }

    :hover {
    background-color: #F2F7FD;
    }
    .active {
      background-color: #F2F7FD;
    }
`;

const Div = styled.div`
  /* width: 19vw; */
  min-width: 250px;
   max-width: 500px;
  height: 3.15vw;
  /* padding: 0 1.3vw; */

  @media screen and (max-width: 480px) {
    min-height: 35px;
    max-height: 45px;
  }
`;

const Input = styled.input`
  background: transparent; 
  border: 0 none; 
  font-family: 'Rubik'; 
  font-size: 16px; 
  font-weight: 400; 
  outline: 0;

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
`;

const Text = styled.h3`
  font-family: 'Rubik';
  font-weight: 400;
  font-size: 16px;
  line-height: 0%;

  @media screen and (max-width: 480px) {
    font-size: 12px;
    flex-wrap: wrap;
  }
`;

const DropD = <img src={Drop} style={{ width: "1vw" }} alt=" " /> ;

interface DropdownProps {
//   model?: string;
  onChangeModelo?: (modelo: string) => void;
}

const Modelo: React.FC<DropdownProps> = ({onChangeModelo}): JSX.Element => {
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  // const [selectOpcao, setSelectOpcao] = useState<categoriasType>();
  const [modNew, setModNew] = useState<string>('');
  const [modelos, setModelos] = useState<categoriasType[]>([]);
  const [selectModelo, setSelectModelo] = useState<categoriasType>();
  // const [modelos, setModelos] = useState<string>("");
  // const products = useLoaderData() as Products[];

//   const modelos = useMemo(() => {
//     const cat: Array<string> = [];

//     products.forEach(({ modelo }) => {
//         const mod = modelo || 'Outros';
//         // if (cat === undefined) {
//         //     cat = [];
//         // }
//         cat.push(mod);
//     });
//     return cat;
// }, [products]);

  // const {modelo} = product;
  // console.log(modelos)

  const toggleDropDown = () => {
    setShowDropDown(!showDropDown);
  };

  const dismissHandler = (event: React.FocusEvent<HTMLButtonElement>): void => {
    if (event.currentTarget === event.target) {
      setShowDropDown(false);
    }
  };

  const ModeloSelection = (modelo: categoriasType): void => {
    setSelectModelo(modelo);
    onChangeModelo?.(modelo.label)
  };

  const AddNew =<Input type="text" key={"AddNewMod"} value={modNew} placeholder="Adicionar nova categoria" onChange={e => {
    setModNew(e.currentTarget.value);
    onChangeModelo?.(e.currentTarget.value);
    setShowDropDown(false)
    // toggleDropDown()
  }} />;

  useEffect(() => {
    getModelos().then((mod) => {
      const arratMod: categoriasType[] = mod.map(e => ({
        label: e,
        element: <Text>{e}</Text>
      }));
      setModelos(arratMod);
    });
  }, []);

  useEffect(() => {
    let modelo: string = ""
    modelos.forEach((e) => {
        modelo = "e";
    })
    onChangeModelo?.(modelo);
}, [modelos, onChangeModelo]);

  const arrayAddLement = useMemo(() => [...modelos, {
    label: 'Adicionar novo modelo',
    element: selectModelo?.label ? AddNew : 'Adicionar nova categoria',
    // element: 'Adicionar nova categoria' ? AddNew : selectCategoria?.label,
  }], [AddNew, modelos, selectModelo])

  return (
    <>
      <Div>
        <Btn
          type="button"
          className={showDropDown ? "active" : undefined}
          onClick={(): void => toggleDropDown()}
          onBlur={(e: React.FocusEvent<HTMLButtonElement>): void =>
            dismissHandler(e)
          }
        >
          <div style={{fontFamily: "Rubik"}}>{ selectModelo ? selectModelo?.label && arrayAddLement.find(e => e.label === selectModelo.label)?.element : selectModelo}</div>
          {DropD}
          {showDropDown && (
            <DropDown
              modelos={arrayAddLement}
              showDropDown={false}
              toggleDropDown={(): void => toggleDropDown()}
              modeloSelection={ModeloSelection}
            />
          )}
        </Btn>
      </Div>
    </>
  );
};

export default Modelo;