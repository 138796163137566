import React from "react";
import "react-activity/dist/library.css";
import { Root, Img } from "./style"
import logo from "../../assets/logo.png";
import BarraProgresso from "../barraProgresso/apageOneBar";
import ButtonLog from "../buttonCadastro";
import BarraProgresso2 from "../barraProgresso/bPageTwoBar";
import { Link } from "react-router-dom";

const RootCadastro2: React.FC = () => {
    return ( 
    <>
        <Root>
            <div>
                <Link to="/">
                    <Img src={logo} alt="logo" />
                </Link>
            </div>
            <div>
                <ButtonLog />
            </div>
        </Root>
        <BarraProgresso2 />
    </>
    );
};

export default RootCadastro2;