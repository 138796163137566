import React, { useState } from "react";
import styled from "styled-components";
import Button from "../../components/button";
import Input from "../../components/input";
import { BodyLoginCard, LabelLogin, LoginBackView } from "./styled";
import * as Yup from "yup";
import { Formik } from "formik";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { authMessage } from "./menssagesCode";
import BtnLogin from "../../components/buttonLogin";
import Show from "../../assets/Show.svg";

const Login = styled.div`
    display: grid;
    padding: 15% 0;

    @media screen and (max-width: 480px) {
        padding: 40px 35px;
        align-items: center;
    }
`;

const Title = styled.h1`
    text-align: start;
    color: ${({ theme }) => theme.background};

    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #ffffff;

    @media screen and (max-width: 480px) {
        font-size: 18px;
    }
`;

const Div = styled.div`
    min-width: 400px;
    max-width: 800px;

    @media screen and (max-width: 480px) {
        min-width: 280px;
        max-width: 400px;
    }
`;

const LabelError = styled(LabelLogin)`
    color: #a00;
`;

const validator = Yup.object().shape({
    email: Yup.string().email("Email não valido.").required("Email é necessário."),
    pass: Yup.string().min(6, "A senha deve conter no mínimo 6 caracteres.").required("Senha é necessária."),
});

const initialValues = {
    email: "",
    pass: "",
};

const LoginCard: React.FC = ({}) => {
    const navigate = useNavigate();
    return (
        <>
            <LoginBackView>
                <BodyLoginCard>
                    <Formik
                        validationSchema={validator}
                        onSubmit={async ({ email, pass }, helper) => {
                            try {
                                helper.setStatus(false);
                                await signInWithEmailAndPassword(getAuth(), email, pass);
                                navigate("/products");
                            } catch (error: any) {
                                helper.setStatus(authMessage[error.code] || "Error ao fazer a sua solicitação verifique e tente novamente mais tarde");
                                // console.error("Error[signInWithEmailAndPassword] -", error.code);
                            }
                        }}
                        initialValues={initialValues}
                    >
                        {({ handleChange, values: { email, pass }, isValid, submitForm, dirty, errors, touched, isSubmitting, status }) => {
                            const emailError = touched.email && errors.email;
                            const passError = touched.pass && errors.pass;
                            return (
                                <>
                                    <Login>
                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <BtnLogin />
                                        </div>
                                        <div style={{margin: "11% 0 2%"}}>
                                            <LabelLogin>Boas vindas a XCENE Parceria</LabelLogin>
                                            <Title>Login</Title>
                                        </div>
                                        <div style={{margin: "0 0 5%"}}>
                                            <div style={{marginBottom: "1%"}}>
                                                <LabelLogin>Email</LabelLogin>
                                            </div>
                                            <Div>
                                                <Input error={!!emailError} type="email" name="email" onChange={handleChange("email")} value={email} />
                                                {emailError && <LabelError>{emailError}</LabelError>}
                                            </Div>
                                        </div>
                                        <div style={{margin: "0 0 5%"}}>
                                            <div><LabelLogin>Senha</LabelLogin></div>
                                            <Div style={{margin: "1% 0"}}>
                                                <Input error={!!passError} name="password" type="password" onChange={handleChange("pass")} value={pass} />
                                                {passError && <LabelError>{passError}</LabelError>}
                                            </Div>
                                            <div>
                                                <LabelLogin style={{textAlign: "end", fontSize: "11px", letterSpacing: "0.01em", lineHeight: "140%"}}>Esqueceu a senha?</LabelLogin>
                                            </div>
                                            
                                        </div>
                                        <div>
                                            <Button
                                            loading={isSubmitting}
                                            disable={!(dirty && isValid)}
                                            title="Login"
                                            color="#202E59"
                                            style={{ width: "auto" }}
                                            onClick={submitForm}
                                            />
                                        {status && <LabelError>{status}</LabelError>}
                                        </div>
                                        
                                    </Login>
                                </>  
                            );
                        }}
                    </Formik>
                </BodyLoginCard>
            </LoginBackView>
        </>
    );
};

export default LoginCard;
