import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../../../components/button";

export const ProductContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
    width: 100%;
    height: 100%;
    /* padding: 10px 0 15px 0; */
    /* padding: 1rem 0 2rem 0; */

`;
export const ProductSession = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin: 20px 0 20px 0; */
`;

export const TitleSession = styled.h2`
    /* Title/H2-48 */

    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 64px;
    line-height: 50%;
    letter-spacing: -0.02em;

    color: #1e272f;

    @media screen and (max-width: 480px) {
        font-size: 22px;
        align-items: center;
        text-align: center;
    }

   @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 36px;
    }
`;

export const DivCategoria = styled.div`
    display: flex;
    /* flex-direction: column; */
    /* width: 1200px; */
    width: 100%;
    /* height: 450px; */
    /* background-color: #787D8214; */
    /* border-radius: 16px; */
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 480px) {}

    @media screen and (min-width: 481px) and (max-width: 890px) {}
`;

export const Img = styled.img`
    min-height: 264px;
    max-height: 264px;
    /* height: 264px; */

    @media screen and (max-width: 480px) {
        /* height: 167px; */
        min-height: 80px;
        max-height: 200px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        /* height: 211px; */
        min-height: 130px;
        max-height: 211px;
    }
`;

export const Text = styled.h3`
    font-family: "Rubik";
    font-size: 32px; //32px
    font-weight: 500;
    /* line-height: 30px; */
    line-height: 0;
    color: #1e272f;
    flex-wrap: wrap;
    /* min-width: 200px; */

    @media screen and (max-width: 480px) {
        font-size: 18px;
        line-height: 100%;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 22px;
        line-height: 25px;
    }
`;

export const Text2 = styled.h3`
    font-family: "Rubik";
    font-size: 24px;
    font-weight: 300;
    line-height: 0px;
    /* line-height: 32.4px; */
    color: #4A4C4E;
    min-width: 200px;

    @media screen and (max-width: 480px) {
        font-size: 16px;
        line-height: 100%;
        min-width: 50px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 18px;
        min-width: 100px;
    }
`;

export const Line = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 339px;
    max-width: 450px;
    height: 1px;
    background-color: #00000087;
    margin: 10px 0;
    /* width: 339px; */

    @media screen and (max-width: 480px) {
        min-width: 100px;
        max-width: 150px;
        margin: 5px 0;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        /* width: 180px; */
        min-width: 180px;
        max-width: 280px;
        margin: 5px 0;
    }
`;

export const LineV = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 1px;
    height: 950px;
    background-color: #0000001A;
    /* margin: 0 50px; */

    @media screen and (max-width: 480px) {
        /* height: ; */
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        height: 800px;
    }
`;

export const Check = styled.input`
    display: flex;
    align-items: center;
    justify-content: center;
    /* min-width: ;
    max-width: ; */
    /* height: 1px; */
    width: 23px;
    height: 23px;
    border: 1px solid;
    cursor: pointer;
    /* background-color: #00000087; */
    /* width: 100%; */

    @media screen and (max-width: 480px) {
        /* font-size: 18px; */
    }
`;

export const DivFlex = styled.div`
    display: flex;
    /* align-items: flex-start; */
    /* justify-content: space-around; */
    width: 100%;
    /* background-color: red; */
    /* flex-wrap: wrap; */

    @media screen and (max-width: 480px) {
        /* font-size: 18px; */
    }
`;

export const DivColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: yellow; */
    padding: 0 10px;
    cursor: pointer;
    /* height: 100%; */

    @media screen and (max-width: 480px) {
        /* font-size: 18px; */
    }
`;

export const Produtos = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-width: 350px;
    max-width: 450px;
    min-height: 400px;
    max-height: 500px;
    /* width: 350px;
    height: 401px; */
    margin: 15px 0;
    background-color: #0002;
    
    @media screen and (max-width: 480px) {
        /* width: 285px;
        height: 331px; */
        min-width: 190px;
        max-width: 280px;
        min-height: 265px;
        max-height: 331px;
        /* margin: 15px 0; */
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 250px;
        max-width: 350px;
        min-height: 300px;
        max-height: 420px;
        /* width: 315px;
        height: 360px; */
        /* background-color: red; */
    }
`;

export const Div1 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* width: 100%;
    height: 260px; */
    min-width: 300px;
    max-width: 381px;
    min-height: 190px;
    max-height: 310px;
    /* background-color: red; */

    @media screen and (max-width: 480px) {
        min-width: 190px;
        max-width: 360px;
        min-height: 150px;
        max-height: 180px;
        /* width: 200px; */
        /* height: 200px; */
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 160px;
        max-width: 280px;
        min-height: 150px;
        max-height: 250px;
        /* width: 220px;
        height: 200px; */
    }

    div {
        display: flex;
        flex-wrap: wrap;
        /* width: 250px;
        height: 400px; */
        min-width: 200px;
        max-width: 360px;
        min-height: 200px;
        max-height: 600px;
        align-items: center;
        justify-content: center;
        /* background-color: red; */

        @media screen and (max-width: 480px) {
            /* width: 188px;
            height: 200px; */
            min-width: 160px;
            max-width: 250px;
            min-height: 150px;
            max-height: 250px;
            /* background-color: red; */
        }

        @media screen and (min-width: 481px) and (max-width: 890px) {
            /* width: 225px;
            height: 360px; */
            min-width: 190px;
            max-width: 360px;
            min-height: 200px;
            max-height: 450px;
        }

        img {
            width: auto;
            height: auto;

            @media screen and (max-width: 480px) {
                /* height: 60%; */
            }
        }
    }
`;

export const Div2 = styled.div`
    display: flex;
    flex-direction: column;
    /* width: 350px; */
    min-width: 300px;
    max-width: 400px;
    height: 100%;
    /* padding: 0 15px 20px; */
    align-items: flex-start;
    justify-content: flex-end;
    /* background: yellow; */
    /* margin-bottom: 3%; */
    /* margin-left: 3%; */

    @media screen and (max-width: 480px) {
        min-width: 190px;
        max-width: 280px;
        /* width: 280px; */
        /* background-color: red; */
        margin-left: 0;
        /* padding: 0 8px 10px; */
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        /* width: 315px; */
        margin-left: 0;
        min-width: 220px;
        max-width: 340px;
    }
`;

export const Div3 = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin: 10px;
    /* gap: -10px; */
    /* background: red; */

    @media screen and (max-width: 480px) {
        /* gap: 0px; */
    }
`;

export const Div4 = styled.div`
    display: flex;
    gap: 2%;
    flex-wrap: wrap;
    min-width: 200px;
    /* background-color: red; */

    @media screen and (max-width: 480px) {
        min-width: 170px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 210px;
    }
`;

export const Produto = styled.h3`
    font-family: "Rubik";
    font-size: 20px;
    font-weight: 400;
    /* line-height: 0px; */
    color: #1e272f;
    flex-wrap: wrap;
    /* min-width: 200px; */
    text-align: start;

    @media screen and (max-width: 480px) {
        font-size: 14px;
        line-height: 0px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 16px;
        line-height: 0%;
    }
`;

export const TextCard = styled.h3`
    font-family: "Rubik";
    font-size: 14px;
    font-weight: 400;
    line-height: 0%;
    color: #787d82;

    @media screen and (max-width: 480px) {
        font-size: 12px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 13px;
    }
`;

export const Btn = styled(Button)`
    /* width: 6.8vw; */
    min-width: 48px;
    max-width: 100px;
    /* height: 3.2vw; */
    min-height: 40px;
    max-height: 50px;
    border-radius: 4px;
    background: #202e59;
    /* margin-left: -20px; */

    @media screen and (max-width: 480px) {
        min-width: 40px;
        max-width: 75px;
        min-height: 30px;
        max-height: 45px;
    }

    span {
        color: #fafbff;
        /* font-size: 16px; */
        /* font-weight: 400; */

        @media screen and (max-width: 480px) {
            font-size: 14px;
        }
    }

    :hover {
        background-color: #1b3480;
        border-color: transparent;
        color: #444;
    }
`;

export const NavIten2 = styled(Link)`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    color: #1E272F;
    font-family: "Rubik";
    font-size: 16px;
    line-height: 145%;
    font-weight: 500;
    text-decoration: none;
    /* background-color: red; */
    height: 120px;

    @media screen and (max-width: 480px) {
        font-size: 8px;
    }
`;