// Import the functions you need from the SDKs you need
import firebase, { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";
// import "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyBo2GFvX5STNYAm2RHVVhO_RfbaUJAcaJM",
    authDomain: "xcene-web.firebaseapp.com",
    projectId: "xcene-web",
    storageBucket: "xcene-web.appspot.com",
    messagingSenderId: "968315506774",
    appId: "1:968315506774:web:881e2ff8e82c9384f32bf7",
    measurementId: "G-5ZDRH8H2NT",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firestore = getFirestore(app);
const auth = getAuth(app);

// export default firebase.initializeApp(firebaseConfig);

// export default firebase.database();

// if (process.env.NODE_ENV === "development" && process.env.REACT_APP_EMULATOR_HOST) {
//     connectFirestoreEmulator(firestore, process.env.REACT_APP_EMULATOR_HOST, 9000);
//     connectAuthEmulator(auth, `http://${process.env.REACT_APP_EMULATOR_HOST}:9099`);
// }
