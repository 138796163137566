import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/button";
import Input from "../../components/input";
//import { LogoComp } from "../../components/root/navbar";
import logo from "../../assets/logo.png";
import Arrow from "../../assets/Arrow.svg";
import LoginCard from "./loginCard";
import { BodyLoginCard, LabelLogin, LoginBackView, LoginContainer, LoginSession } from "./styled";


const Logo = styled.div`
    margin: 3% 0 0 7.5%;
`;

const TextSession = styled.div`
    width: 516px;
    display: grid;
    align-items: center;

    @media screen and (max-width: 480px) {
        width: 350px;
    }
`;
const PromText = styled.text`
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;
    color: #1e272f;

    @media screen and (max-width: 480px) {
        font-size: 18px;
    }
`;

const TitleText = styled.h1`
    font-family: "Rubik";
    font-style: normal;
    font-weight: 600;
    font-size: 56px;
    line-height: 130%;
    letter-spacing: -0.0125em;
    color: #202e59;

    @media screen and (max-width: 480px) {
        font-size: 42px;
    }
`;

const LinkBack = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    flex-grow: 0;
`;

const Text = styled.h4`
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #1c70bb;

    @media screen and (max-width: 480px) {
        font-size: 12px;
    }
`;

const Img = styled.img`
    width: 266px;

    @media screen and (max-width: 480px) {
        width: 180px;
    }
`;

const handleClick = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
};

const LoginPage: React.FC = ({}) => {
    return (
        <LoginContainer>
            <Logo>
                    <Img src={logo} alt="logo xcene" />
            </Logo>
            <LoginSession>
                <TextSession>
                    <div>
                        <TitleText>Junte-se a Familia XCENE</TitleText>
                        <PromText>
                            Não perca a oportunidade de ampliar sua oferta de produtos de automação residencial e se destacar no mercado com a qualidade e
                            tecnologia dos produtos da Xcene Automação. Seja um revendedor hoje e junte-se a nós nessa jornada de sucesso.
                        </PromText>
                    </div>
                    {/* <br/> */}
                    <LinkBack onClick={handleClick} to={"/"}>
                        <img style={{transform: "matrix(-1, 0, 0, -1, 0, 0)"}} src={Arrow} alt="Arrow" />
                        <Text>Voltar para a página inicial</Text>
                    </LinkBack>
                </TextSession>
                <div style={{alignItems: "center"}}>
                    <LoginCard />
                </div>
            </LoginSession>
        </LoginContainer>
    );
};

export default LoginPage;
