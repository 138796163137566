import React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { Formik } from "formik";
import { getAuth } from "firebase/auth";
// import { useNavigate } from "react-router-dom";
import Button from "../../../components/button";
import { authMessage } from "../../login/menssagesCode";

const CaixaEmailContainer = styled.div`
    width: 520px;
    height: 64px;
    background: #FAFBFF;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: absolute; */
    /* min-width: 300px; */
    max-width: 800px;
    //margin-left: 78%;
    //margin-top: 3%;
    //padding-right: 0.3em;

    @media screen and (max-width: 480px) {
        width: 60%;
        height: 27px;
        position: absolute;
    }
`;

const CaixaEmailInput = styled.input`
    flex: 1;
    border: 0;
    outline: 0;
    background-color: transparent;
    font-family: 'Rubik';
    font-size: 16px;
    height: 64px;
    /* width: ; */
    padding-inline: 10px;
    /* border-radius: 4px; */
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 480px) {
        font-size: 8px;
        padding-inline: 5px;
        height: 32px;
    }
`;

const CaixaEmailButton = styled.input`
    width: 22%;
    height: 86%;
    background: #1C70BB;
    // border-radius: 4px;
    // position: absolute;
    /* margin-right: 1%; */
    color: #FFFFFF;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline: 0px;
    margin-right: 4px;
    border: 0 none;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    text-align: center;
    // color: ${({ theme: { textContrast } }) => textContrast};
    z-index: 1;
    cursor: pointer;
    inset: 0;
    transform-origin: right;

    @media screen and (max-width: 480px) {
        font-size: 8px;
        border-radius: 4px;
        margin-right: 2px;
    }
`;

const LabelError = styled(CaixaEmailContainer)`
    margin-top: 90px;
    padding: 10px 350px 0 0;
    justify-content: center;
    /* position: absolute; */
    color: #a00;
    background: transparent;
`;

// const Agradecimento = styled(CaixaEmailContainer)`
//     font-family: "Rubik";
//     color: #189877;
//     font-size: 15px;
//     font-weight: 500;
//     background: transparent;
//     margin-top: 19%;
//     justify-content: center;
// `;

const validator = Yup.object().shape({
    EMAIL: Yup.string().email("Email não é valido.").required("Email é necessário.")
});

const initialValues = {
    EMAIL: "",
};

const agradecimento = "Obrigado por se inscrever em nossa newsletter!"

const BotaoContinue: React.FC = () => {
    // const mostrar = String ();
    return (
        <>
            <Formik
                validationSchema={validator}

                onSubmit={async ({ EMAIL }, helper) => {
                    // try {
                    //     helper.setStatus(false);
                    //     await signInWithEmailAndPassword(getAuth(), email, pass);
                    //     navigate("/products");
                    try {
                        helper.setStatus(false);
                        // await (getAuth(), email);
                        // mostrar("Obrigado por se inscrever em nossa newsletter!");
                        // helper.setStatus(agradecimento);

                        // ("Obrigado por se inscrever em nossa newsletter! 
                        // ");
                    } catch (error: any) {
                        helper.setStatus(authMessage[error.code] || "Erro ao fazer a sua solicitação verifique e tente novamente mais tarde");
                        // console.error("Error[signInWithEmailAndPassword] -", error.code);
                    }
                }}
                initialValues={initialValues}
            >
                {({ handleChange, values: { EMAIL }, isValid, submitForm, dirty, errors, touched, isSubmitting, status }) => {
                    const emailError = touched.EMAIL && errors.EMAIL;
                    return (
                        <>
                            {/* <div id="mc_embed_shell">
                                    <form action="https://xcene.us13.list-manage.com/subscribe/post?u=eb508c02270c5ed5b4347456b&amp;id=155087c9e5&amp;f_id=009490e2f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_self" noValidate>
                                                <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" required  />
                                                <input type="text" hidden
                                                    name="b_eb508c02270c5ed5b4347456b_155087c9e5"
                                                    // tabindex="-1"
                                                    value="" />
                                                <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" value="Subscribe" />
                                    </form>
                                </div> */}
                            <form action="https://xcene.us13.list-manage.com/subscribe/post?u=eb508c02270c5ed5b4347456b&amp;id=155087c9e5&amp;f_id=009490e2f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                                <CaixaEmailContainer>
                                    <CaixaEmailInput placeholder="Enter your email" type="email" name="EMAIL" className="required email" id="mce-EMAIL" onChange={handleChange("EMAIL")} required
                                        // value="" required=""
                                        value={EMAIL}
                                    />
                                    {emailError && <LabelError>{emailError}</LabelError>}

                                    <input
                                        type="text"
                                        name="b_eb508c02270c5ed5b4347456b_155087c9e5"
                                        //  tabIndex="-1" 
                                        value={""}
                                        hidden
                                    />

                                    <CaixaEmailButton
                                        // <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" value="Continue"/>
                                        type="submit"
                                        name="subscribe"
                                        className="button"
                                        value="Continue"
                                        id="mc-embedded-subscribe"
                                    />
                                    {status && <LabelError>{status}</LabelError>}

                                </CaixaEmailContainer>
                            </form>
                        </>
                    );
                }}
            </Formik>
        </>
    );
};

export default BotaoContinue;

{/* <Button
        loading={isSubmitting}
        disable={!(dirty && isValid)}
        title="Login"
        color="#202E59"
        style={{ width: "auto" }}
        onClick={submitForm}
    /> 
*/}

{/* <form action="https://xcene.us13.list-manage.com/subscribe/post?u=eb508c02270c5ed5b4347456b&amp;id=155087c9e5&amp;f_id=009490e2f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_self" novalidate="">
    <div id="mc_embed_signup_scroll">
    <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
    <div className="mc-field-group"><label for="mce-EMAIL">
        Email Address <span className="asterisk">*</span></label>
        <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" required="" value="">
    </div>
    <div id="mce-responses" className="clearfalse">
        <div className="response" id="mce-error-response" style="display: none;"></div>
        <div className="response" id="mce-success-response" style="display: none;"></div>
    </div>
    <div aria-hidden="true" style="position: absolute; left: -5000px;">
        <input type="text" name="b_eb508c02270c5ed5b4347456b_155087c9e5" tabindex="-1" value="">
    </div>
    <div className="clear">
        <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" value="Subscribe">
    </div>
    </div>
</form> */}