import React, { useContext } from "react";
import styled, { css } from "styled-components";
import "react-activity/dist/library.css";
// import logo from "../../../assets/logo.png";
import { Empresa, Container } from "./style";
import { Link } from "react-router-dom";
import Button from "../../../components/button";
import RootCadastro from "../../../components/rootCadastro";
import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { authMessage } from "../../login/menssagesCode";
import RootCadastro2 from "../../../components/rootCadastro/rootb";
import { cnpj } from "cpf-cnpj-validator";
import { FormContext } from "../root";
import { getAuth } from "firebase/auth";

const PreencherContainer = styled.div`
    //width: 1322px;
    //height: 319px;
    display: grid;
    flex-wrap: wrap;
    flex: 1;
`;

const Containers = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    @media screen and (max-width: 480px) {
      display: grid;
    }
`;

const TitleRevenda = styled.h3`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 145%;
    color: #000000;

    @media screen and (max-width: 480px) {
        font-size: 14px;
    }
`;

const Title = styled.h4`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 145%;
    color: #787D82;

    @media screen and (max-width: 480px) {
        font-size: 11px;
    }
`;

const CaixaContainer = styled.div`
    border-radius: 4px;
`;

const Input = styled.input`
    background: #F2F7FD;
    font-family: 'Rubik';
    font-size: 16px;
    min-height: 45px;
    max-height: 60px;
    min-width: 300px;
    max-width: 650px;
    border: 0;
    padding: 0 13px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    border-radius: 4px;
    outline: 0;

    @media screen and (max-width: 480px) {
        min-width: 250px;
        max-width: 350px;
        min-height: 35px;
        max-height: 45px;
        padding: 0 10px;
        font-size: 14px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 150px;
        max-width: 350px;
        min-height: 40px;
        max-height: 60px;
        padding: 0 10px;
        font-size: 15px;
    }
`;

const InputEmpresa = styled.input`
    background: #F2F7FD;
    font-family: 'Rubik';
    font-size: 16px;
    min-height: 45px;
    max-height: 60px;
    min-width: 850px;
    max-width: 1800px;
    border: 0;
    padding: 0 13px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    border-radius: 4px;
    outline: 0;

    @media screen and (max-width: 480px) {
        min-width: 250px;
        max-width: 350px;
        min-height: 35px;
        max-height: 45px;
        padding: 0 10px;
        font-size: 14px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 460px;
        max-width: 600px;
        min-height: 40px;
        max-height: 60px;
        padding: 0 10px;
        font-size: 15px;
    }
`;

const InputCargo = styled.input`
    background: #F2F7FD;
    font-family: 'Rubik';
    font-size: 16px;
    min-height: 45px;
    max-height: 60px;
    min-width: 470px;
    max-width: 800px;
    border: 0;
    padding: 0 13px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    border-radius: 4px;
    outline: 0;

    @media screen and (max-width: 480px) {
        min-width: 250px;
        max-width: 350px;
        min-height: 35px;
        max-height: 45px;
        padding: 0 10px;
        font-size: 14px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 250px;
        max-width: 450px;
        min-height: 40px;
        max-height: 60px;
        padding: 0 10px;
        font-size: 15px;
    }
`;

const InputCnpj = styled.input`
    background: #F2F7FD;
    font-family: 'Rubik';
    font-size: 16px;
    min-height: 45px;
    max-height: 60px;
    min-width: 400px;
    max-width: 800px;
    border: 0;
    padding: 0 13px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    border-radius: 4px;
    outline: 0;

    @media screen and (max-width: 480px) {
        min-width: 250px;
        max-width: 350px;
        min-height: 35px;
        max-height: 45px;
        padding: 0 10px;
        font-size: 14px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 250px;
        max-width: 400px;
        min-height: 40px;
        max-height: 60px;
        padding: 0 10px;
        font-size: 15px;
    }
`;

const ButtonVoltar = styled(Button)`
    /* width: 17.7vw; */
    min-width: 230px;
    max-width: 310px;
    min-height: 45px;
    max-height: 60px;
    /* height: 6vh; */
    background: rgba(32, 46, 89, 0.2);
    border-radius: 4px;
    position: unset;
    display: flex;
   //margin-left: 3em;

   span{
    color: #1E272F;
   }

    @media screen and (max-width: 480px) {
        min-width: 100px;
        max-width: 150px;
        min-height: 35px;
        max-height: 60px;
        font-size: 12px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 200px;
        max-width: 300px;
        min-height: 40px;
        max-height: 60px;
        font-size: 14px;
    }
`;

const ButtonSalvar = styled(Button)`
    /* width: 30.95vw; */
    min-width: 400px;
    max-width: 600px;
    /* height: 6vh; */
    min-height: 45px;
    max-height: 60px;
    /* width: 520px;
    height: 56px; */
    background: #202E59;
    border-radius: 4px;
    position: unset;
    display: flex;
   //margin-left: 3em;
    align-items: center;
    justify-content: center;
    font-family: 'Rubik';
    color: #FFFFFF;
    font-size: 16px;

    @media screen and (max-width: 480px) {
        min-width: 100px;
        max-width: 150px;
        min-height: 35px;
        max-height: 60px;
        font-size: 12px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 200px;
        max-width: 300px;
        min-height: 40px;
        max-height: 60px;
        font-size: 14px;
    }
`;

const LabelError = styled(CaixaContainer)`
    margin-left: 0;
    display: grid;
    padding: 5px 20px;
    color: #a00;
    background: transparent;

    @media screen and (max-width: 480px) {
        padding: 3px 10px;
        font-size: 12px;
    }
`;

const validator = Yup.object().shape({
    empresa: Yup.string().min(2, "Nome da empresa não é valido").required("Nome da empresa é necessário."),
    cargo: Yup.string().min(2, "Cargo não é valido").required("Cargo é necessário."),
    departamento: Yup.string().min(2, "Departamento não é valido").required("Departamento é necessário."),
    inscricao: Yup.string().min(5, "Inscrição Estadual não é valida").required("Inscrição Estadual é necessário."),
    cnpj: Yup.string().required("CNPJ é necessário.").test((value) => cnpj.isValid(value)),
});

const initialValues = {
    empresa: "",
    cargo: "",
    departamento: "",
    inscricao: "",
    cnpj: "",
};

const PaginaEmpresa: React.FC = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const { formData, setFormData } = useContext(FormContext);


    return (
        <>
            <Formik
                validationSchema={validator}
                onSubmit={async ({ empresa, cargo, departamento, inscricao, cnpj }, helper) => {
                    try {
                        setFormData((old) => {
                            return (
                                { ...old, empresa, cargo, departamento, inscricao, cnpj }
                            )
                        })
                        helper.setStatus(false);
                        await getAuth(); //empresa, cargo, departamento, inscricao, cnpj);
                        navigate("../revendaCadastro");
                    } catch (error: any) {
                        helper.setStatus(authMessage[error.code] || "Erro ao fazer a sua solicitação verifique e tente novamente mais tarde");
                    }
                }}
                initialValues={initialValues}
            >
                {({ handleChange, values: { empresa, cargo, departamento, inscricao, cnpj }, isValid, submitForm, dirty, errors, touched, isSubmitting, status }) => {
                    const empresaError = touched.empresa && errors.empresa;
                    const cargoError = touched.cargo && errors.cargo;
                    const departamentoError = touched.departamento && errors.departamento;
                    const inscricaoError = touched.inscricao && errors.inscricao;
                    const cnpjError = touched.cnpj && errors.cnpj;
                    return (
                        <>
                            <Empresa>
                                <RootCadastro2 />
                                <TitleRevenda>Empresa</TitleRevenda>
                                <PreencherContainer>
                                    <div>
                                        <div>
                                            <Title>Empresa</Title>
                                            {/* <CaixaContainer style={{width: "78.5vw"}}> */}
                                            <InputEmpresa
                                                // type="text" 
                                                name="empresa" onChange={handleChange("empresa")} value={empresa}>
                                            </InputEmpresa>
                                            {empresaError && <LabelError>{empresaError}</LabelError>}
                                            {/* </CaixaContainer> */}
                                        </div>
                                        <Containers>
                                            <div>
                                                <Title>Cargo</Title>
                                                {/* <CaixaContainer style={{width: "44.25vw"}}> */}
                                                <InputCargo type="text" name="cargo" onChange={handleChange("cargo")} value={cargo}>
                                                </InputCargo>
                                                {cargoError && <LabelError>{cargoError}</LabelError>}
                                                {/* </CaixaContainer> */}
                                            </div>
                                            <div>
                                                <Title>Departamento</Title>
                                                {/* <CaixaContainer style={{width: "30.95vw"}}> */}
                                                <Input
                                                    type="text" name="departamento" onChange={handleChange("departamento")} value={departamento}
                                                    style={{}}>
                                                </Input>
                                                {departamentoError && <LabelError>{departamentoError}</LabelError>}
                                                {/* </CaixaContainer> */}
                                            </div>
                                        </Containers>
                                        <Containers>
                                            <div>
                                                <Title>Inscr. Estadual</Title>
                                                {/* <CaixaContainer style={{width: "28.15vw"}}>  */}
                                                <Input
                                                    // type="text"
                                                    name="inscricao" onChange={handleChange("inscricao")} value={inscricao}
                                                    style={{}}>
                                                </Input>
                                                {inscricaoError && <LabelError>{inscricaoError}</LabelError>}
                                                {/* </CaixaContainer> */}
                                            </div>
                                            <div>
                                                <Title>CNPJ</Title>
                                                {/* <CaixaContainer style={{width: "47.5vw"}}>  */}
                                                <InputCnpj type="cnpj" name="cnpj" onChange={handleChange("cnpj")} value={cnpj}>
                                                </InputCnpj>
                                                {cnpjError && <LabelError>{cnpjError}</LabelError>}
                                                {/* </CaixaContainer> */}
                                            </div>
                                        </Containers>
                                    </div>
                                </PreencherContainer>
                                <Container>
                                    <Link to="/register" style={{ textDecoration: "none" }}>
                                        <ButtonVoltar onClick={handleClick} title="Voltar página" />
                                    </Link>

                                    <ButtonSalvar
                                        // type="submit"
                                        loading={isSubmitting}
                                        title="Salvar e continuar"
                                        onClick={submitForm}
                                    >Salvar e continuar</ButtonSalvar>
                                    {status && <LabelError>{status}</LabelError>}


                                </Container>

                            </Empresa>
                        </>
                    );
                }}
            </Formik>
        </>
    );
};

export default PaginaEmpresa;