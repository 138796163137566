import React, { useState, useEffect, useRef, useCallback } from "react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { initializeApp } from "firebase/app";
import styled from "styled-components";
import { firebaseConfig } from "../../../middlewares/firebase";
import imageIcon from '../../../assets/image.png';
import plus2 from '../../../assets/plus2.png';

const FileInput = styled.input`
  display: none;  //tira o botão escolher arquivo
`;

const FileLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ::before {
    content: url("assets/image.png");
    /* content: ${({imageIcon})}; */
  }
`;

const Button = styled.button`
  display: flex;
  width: 2.2vw;
  height: 2.2vw;
  border: 0 none;
  border-radius: 0.5vw;
  background: #1C70BB;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  font-family: 'Rubik';
  font-size: 1.5vw;
  font-weight: 500;
  cursor: pointer;
  :hover{
    background: #054f91;
  }

  @media screen and (max-width: 480px) {
    width: 15px;
    height: 15px;
    /* border-radius: 2px; */
  }
`;

const BtnText = styled.h4`
  font-family: 'Rubik';
  font-size: 1.5vw;
  font-weight: 500;
  color: #fff;
  /* line-height: 0%; */

  @media screen and (max-width: 480px) {
    font-size: 12px;
    text-align: center;
    line-height: 0%;
  }
`;

const Message = styled.span`
  font-family: 'Rubik';
  font-size: 14px;
  font-weight: 500;
  color: #67cf12;
`;

const Img = styled.img`
  width: 65%;

  @media screen and (max-width: 480px) {
    width: 40%;
  }
`;

const Image = styled.img`
  width: 2.9vw; 

  @media screen and (max-width: 480px) {
    width: 20px;
  }
`;

const storage = getStorage(initializeApp(firebaseConfig));

function UploadImage({name, imageSRC, onImgUpload}: { name: string, imageSRC?: string, onImgUpload: (ImageUrl: string) => void}) {
  const [image, setImage] = useState<File>();
  const [imageUrl, setImageUrl] = useState<string>(imageSRC || '');
  const [imgSRC, setImgSRC] = useState<string | undefined>(imageSRC);
  const [uploadStatus, setUploadStatus] = useState<string>();
  // const [message, setMessage] = useState<Array<string>>([]);
  // const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {

  useEffect(() => {
    setImgSRC(imageSRC);
  }, [imageSRC])
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const f = event.target.files[0]
      setImage(f);
      setImgSRC(URL.createObjectURL(f));
    }
  }

  const handleUpload = async () => {
    if (image) {
      try {
        const storageRef = ref(storage, `Web/imagens/${image.name.split('.')[0]}${Date.now()}`);
          await
        uploadBytes(storageRef, image);
          const url = await
        getDownloadURL(storageRef);
          setImageUrl(url);
          onImgUpload(url);
          setUploadStatus('Imagem carregada com sucesso!');
      }catch (error) {
        if (error instanceof Error) {
        setUploadStatus(`Falha ao carregar imagem: ${error.message}`);
        } else {
          setUploadStatus('Falha ao carregar imagem');
        }
      }
    }
  }

  return (
      <div style={{display: "flex", flexDirection: "column-reverse", alignItems: "center", justifyContent: "center"}}>
          <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
              <input name={name} type="text" value={imageUrl} hidden/>
            <FileLabel 
            >
            <Image src={imageIcon} alt="" />
              <FileInput 
               type="file" onChange={handleFileChange} />
            </FileLabel>
            {/* <div> */}
            <Button type="button" onClick={handleUpload}>
              <BtnText>+</BtnText>
            </Button>
          </div>
          { imgSRC && <Img src={imgSRC} alt="Uploaded"/> }
          <Message>{uploadStatus}</Message>
          {/* {imageUrl && <img src={imageUrl} alt="uploaded" />} */}
      </div>
  );
};
export default UploadImage;