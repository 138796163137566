import React from "react";
import styled, { css } from "styled-components";
import "react-activity/dist/library.css";
// import Button from "../../../components/button";
import BotaoContinue from "./Button";
//import ButtonMessage from "./Button";

// const CaixaEmailContainer = styled.div`
//     width: 520px;
//     height: 64px;
//     background: #FAFBFF;
//     border-radius: 4px;
//     display: flex;
//     //justify-content: center;
//     align-items: center;
//     position: absolute;
//     //margin-left: 78%;
//     //margin-top: 3%;
//     //padding-right: 0.3em;
// `;

// const CaixaEmailInput = styled.input`
//     flex: 1;
//     border: 0;
//     outline: 0;
//     background-color: transparent;
//     font-family: 'Rubik';
//     font-size: 16px;
//     height: 100%;
//     padding-inline: 1em;
    
// `;

// const CaixaEmailButton = styled(Button)`
//     width: 120px;
//     height: 54px;
//     background: #1C70BB;
//     border-radius: 4px;
//     position: unset;
//     margin-right: 1%;
// `;

const SessionNewsletter = styled.div `
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* min-width: 300px; */
    /* max-width: 800px; */
    /* background: red; */
    width: 96vw;

    @media screen and (max-width: 480px) {
        width: 96vw;
    }
`;            
                                                                                  
const Newsleter = styled.div `
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /* width: 1240px; */
    height: 251px;
    background-color: #1E272F;
    border-radius: 10px;
    margin-bottom: 10%;
    align-items: center;
    padding: 20px 20px 50px 20px;
    justify-content: space-around;

    div {
        @media screen and (max-width: 480px) {
            /* justify-content: center; */
        }
    }

    @media screen and (max-width: 480px) {
        width: 80%;
        height: 100px;
        padding: 10px 10px 35px 10px;
        /* justify-content: center; */

    }
`;

const TittleNewsletter = styled.h2`
    font-family: "Rubik";
    color: #FFFFFF;
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 2%;
    margin-top: 0;

    /* font-size: 26px; */

    @media screen and (max-width: 480px) {
        font-size: 16px;
        margin-bottom: 1%;
    }
    
`;

const TextNewsletter = styled.text`
    font-family: "Rubik";
    color: #EAEDF6;
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;

    @media screen and (max-width: 480px) {
        font-size: 8px;
    }

    /* flex: none;
    order: 1;
    flex-grow: 0; */
    /* width: 36vw;
    height: 8.4vh; */

    /* font-size: 14px; */
    
`;

const Session = styled.div`
    display: grid; 
    align-items: center;
    width: 583px;
    padding: 0 15px 0 30px;
    /* min-width: 250px; */
    /* max-width: 800px; */

    @media screen and (max-width: 480px) {
        /* width: 783px; */
        padding: 0 6px 0 8px;
        text-align: center;
        /* justify-content: center; */
    }
`; 

const Div = styled.div`
    display: flex; 
    align-items: center;
    width: 550px;

    @media screen and (max-width: 480px) {
        width: 250px;
    }
`;

// const Text = styled.h4`
//     font-family: "Rubik";
//     color: #189877;
//     font-size: 15px;
//     font-weight: 500
// `;


    {/* const CaixaEmail: React.FC = ({}) => {
        return ( 
        <CaixaEmailContainer>
            <CaixaEmailInput placeholder="Enter your email">
                
            </CaixaEmailInput>
            <BotaoContinue />
            {<CaixaEmailButton title="Continue"/>}

        </CaixaEmailContainer>
        );

        //1215px
    }; */}


    /*
    <div id="mc_embed_shell">
      <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css">
        <style type="text/css">
        </style>
        <div id="mc_embed_signup">
            <form action="https://xcene.us13.list-manage.com/subscribe/post?u=eb508c02270c5ed5b4347456b&amp;id=155087c9e5&amp;f_id=009490e2f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_self" novalidate="">
                <div id="mc_embed_signup_scroll">
                    <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
                    <div class="mc-field-group">
                        <label for="mce-EMAIL">
                            Email Address 
                        <span class="asterisk">*</span>
                        </label>
                        <input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="" value="">
                    </div>
                    <div id="mce-responses" class="clearfalse">
                    <div class="response" id="mce-error-response" style="display: none;"></div>
                    <div class="response" id="mce-success-response" style="display: none;"></div>
                    </div>
                <div aria-hidden="true" style="position: absolute; left: -5000px;">
                    <input type="text" name="b_eb508c02270c5ed5b4347456b_155087c9e5" tabindex="-1" value="">
                </div>
                <div class="clear">
                    <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Subscribe">
                </div>
            </div>
        </form>
        </div>
        </div>

     */


const Newsletter: React.FC = ({}) => {
    return (
        <SessionNewsletter>
            <Newsleter>
                <Session
                //  style={{ display: "grid" , alignItems: "center",
                //   width: "583px",
                //   padding: "0 15px 0 30px",
                //   minWidth: "250px",
                //   maxWidth: "800px"
                // //   textAlign: "center"
                // //    background: "red",
                // //    justifyContent: "space-evenly"
                //  }}
                >
                    <TittleNewsletter>
                        Inscreva-se em nossa Newsletter
                    </TittleNewsletter>
            
                    <TextNewsletter>
                        Fique por dentro das últimas novidades e promoções exclusivas da nossa empresa. Mantenha-se informado sobre as tendências e tecnologias de automação residencial. Inscreva-se agora e não perca nenhuma atualização.
                    </TextNewsletter>
                </Session>
                <Div>
                    <BotaoContinue />
                </Div>
            </Newsleter>
        </SessionNewsletter>
    )
}

export default Newsletter;