import React, { useContext, useState } from "react";
import styled from "styled-components";
import "react-activity/dist/library.css";
import { Container, Cadastro } from "./style";
import { Form, Link } from "react-router-dom";
import Button from "../../../components/button";
import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { authMessage } from "../../login/menssagesCode";
import RootCadastro3 from "../../../components/rootCadastro/rootc";
import { getAuth } from "firebase/auth";
import { FormContext } from "../root";
// import { Steps, StepsProvider, useSteps } from "react-step-builder";


const PreencherContainer = styled.div`
    display: grid;
    flex-wrap: wrap;
    flex: 1;
`;

const TitleRevenda = styled.h3`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 145%;
    color: #000000;

    @media screen and (max-width: 480px) {
        font-size: 14px;
    }
`;

const Title = styled.h4`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 145%;
    color: #787D82;

    @media screen and (max-width: 480px) {
        font-size: 11px;
    }
`;

const CaixaContainer = styled.div`
    border-radius: 4px;
`;

const Containers = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    @media screen and (max-width: 480px) {
      display: grid;
    }
`;

const Input = styled.input`
    /* flex: 1; */
    display: flex;
    position: relative;
    background: #F2F7FD;
    font-family: 'Rubik';
    font-size: 16px;
    /* height: 3.5vw; */
    min-height: 45px;
    max-height: 60px;
    /* width: 34.3vw; */
    min-width: 380px;
    max-width: 820px;
    border: 0;
    //align-items: center;
    //text-align: center;
    /* padding: 0 1vw; */
    padding: 0 13px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    border-radius: 4px;
    outline: 0;

    @media screen and (max-width: 480px) {
        min-width: 250px;
        max-width: 350px;
        min-height: 35px;
        max-height: 45px;
        padding: 0 10px;
        font-size: 14px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 200px;
        max-width: 300px;
        min-height: 40px;
        max-height: 60px;
        padding: 0 10px;
        font-size: 15px;
    }
`;

const InputEmail = styled.input`
    display: flex;
    position: relative;
    background: #F2F7FD;
    font-family: 'Rubik';
    font-size: 16px;
    min-height: 45px;
    max-height: 60px;
    min-width: 850px;
    max-width: 1800px;
    border: 0;
    padding: 0 13px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    border-radius: 4px;
    outline: 0;

    @media screen and (max-width: 480px) {
        min-width: 250px;
        max-width: 350px;
        min-height: 35px;
        max-height: 45px;
        padding: 0 10px;
        font-size: 14px;
    }

     @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 460px;
        max-width: 600px;
        min-height: 40px;
        max-height: 60px;
        padding: 0 10px;
        font-size: 15px;
    }
`;

const InputCelular = styled.input`
    display: flex;
    position: relative;
    background: #F2F7FD;
    font-family: 'Rubik';
    font-size: 16px;
    min-height: 45px;
    max-height: 60px;
    min-width: 295px;
    max-width: 730px;
    border: 0;
    padding: 0 13px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    border-radius: 4px;
    outline: 0;

    @media screen and (max-width: 480px) {
        min-width: 150px;
        max-width: 250px;
        min-height: 35px;
        max-height: 45px;
        padding: 0 10px;
        font-size: 14px;
    }

     @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 160px;
        max-width: 300px;
        min-height: 40px;
        max-height: 60px;
        padding: 0 10px;
        font-size: 15px;
        width: 100px;
    }
`;

const ButtonVoltar = styled(Button)`
    /* width: 17.7vw; */
    min-width: 230px;
    max-width: 310px;
    /* height: 6vh; */
    /* width: 297px;
    height: 56px; */
    min-height: 45px;
    max-height: 60px;
    background: rgba(32, 46, 89, 0.2);
    border-radius: 4px;
    position: unset;
    display: flex;
   //margin-left: 3em;

    span{
        color: #1E272F;
    }

    @media screen and (max-width: 480px) {
        min-width: 100px;
        max-width: 150px;
        min-height: 35px;
        max-height: 60px;
        font-size: 12px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 200px;
        max-width: 300px;
        min-height: 40px;
        max-height: 60px;
        font-size: 14px;
    }
`;

const ButtonSalvar = styled.button`
    /* width: 30.95vw; */
    min-width: 400px;
    max-width: 600px;
    /* height: 6vh; */
    min-height: 45px;
    max-height: 60px;
    /* width: 520px;
    height: 56px; */
    background: #202E59;
    border-radius: 4px;
    position: unset;
    display: flex;
    //margin-left: 3em;
    align-items: center;
    justify-content: center;
    font-family: 'Rubik';
    color: #FFFFFF;
    font-size: 16px;

    @media screen and (max-width: 480px) {
        min-width: 100px;
        max-width: 150px;
        min-height: 35px;
        max-height: 60px;
        font-size: 12px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 200px;
        max-width: 300px;
        min-height: 40px;
        max-height: 60px;
        font-size: 14px;
    }
`;

const LabelError = styled(CaixaContainer)`
    margin-left: 0;
    display: grid;
    padding: 5px 20px;
    color: #a00;
    background: transparent;
`;

const validator = Yup.object().shape({
    email: Yup.string().email("Email não é valido.").required("Email é necessário."),
    senha: Yup.string().min(6, "A senha deve ter pelo menos 6 caracteres").required("Senha é necessária."),
    confirmarSenha: Yup.string().oneOf([Yup.ref('senha')], "Confirmação incorreta").required("Confirme sua senha!"),
    // celular: Yup.number().min(8, "Celular não é valido").required("Celular é necessário."),
    telefone: Yup.number().min(8, "Telefone invalido.").required("Telefone é necessário."),
    // ddd: Yup.number().max(3, "DDD invalido.").required("DDD é necessário."),

});


// interface Celular {
//     ddd: string;
//     num: string;
// }

const initialValues = {
    email: "",
    senha: "",
    confirmarSenha: "",
    ddd: "",
    celular: "",
    telefone: "",
    num: "",
    ddd2: "", 
    num2: "",
};

const PaginaRevendaCadastro: React.FC = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const { setFormData, sendToFormtoDB } = useContext(FormContext);
    // const [celular, setCelular] = useState('');
    // const [ddd, setDDD] = useState('');
    // const [num, setNum] = useState('');
    // const [cel, setCel] = useState<Celular | null>(null);

    
    // const handleChangeDDD = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setDDD(event.target.value);
    // };

    // const handleChangeNum = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setNum(event.target.value);
    // };

    // function handleCelularChange(event: React.ChangeEvent<HTMLInputElement>) {
    //     const { value } = event.target;
    //     const celular = `${ddd}${num}${value}`;
    //     setCelular(celular);
    // }

    // function handleCelularChange(event) {
    //     const { value } = event.target;
    //     const celular = `${ddd}${num}${value}`;
    //     setCelular(celular);
    // }
    
    //   const handleSubmit = (event) => {
    //     event.preventDefault();
    //     const celular = ddd.concat(num);
    //     alert('Valor do celular: ' + celular);
    //   };
    
    //   return (
    //     <div>
    //       {/* <form onSubmit={handleSubmit}> */}
    //         <label>
    //           DDD:
    //           <input type="text" value={ddd} onChange={handleChangeDdd} />
    //         </label>
    //         <br />
    //         <label>
    //           Número:
    //           <input type="text" value={num} onChange={handleChangeNum} />
    //         </label>
    //         <br />
    //         <button type="submit">Enviar</button>
    //       {/* </form> */}
    //     </div>
    //   );const handleSubmit = (event) => {
    //     event.preventDefault();
    //     const celular = ddd.concat(num);
    //     alert('Valor do celular: ' + celular);
    //   };
    // }

    return (
        <>
            <Formik
                validationSchema={validator}
                onSubmit={async ({ email, senha, confirmarSenha, telefone, ddd, num, ddd2, num2 }, helper) => {
                    try {
                        // const celular = ddd + num;
                        setFormData((old) => {
                            return (
                                { ...old, email, senha, confirmarSenha, celular: ddd + num, telefone, celular2: ddd2 + num2 }
                            )
                        })
                        helper.setStatus(false);
                        navigate("../revendaConclusao");
                    } catch (error: any) {
                        helper.setStatus(authMessage[error.code] || "Erro ao fazer a sua solicitação verifique e tente novamente mais tarde");
                    }
                }}
                initialValues={initialValues}
            >
                {({ handleChange, values: { email, senha, confirmarSenha, celular, telefone, ddd, num, ddd2, num2 }, isValid, submitForm, dirty, errors, touched, isSubmitting, status }) => {
                    const emailError = touched.email && errors.email;
                    const senhaError = touched.senha && errors.senha;
                    const confirmarSenhaError = touched.confirmarSenha && errors.confirmarSenha;
                    // const celularError = touched.celular && errors.celular;
                    const telefoneError = touched.telefone && errors.telefone;
                    // const dddError = touched.ddd && errors.ddd;

                    return (
                        <>
                            {/* <Form method="post" onSubmit={submitForm}> */}
                            <Cadastro>
                                <RootCadastro3 />
                                <TitleRevenda>Cadastro</TitleRevenda>
                                <PreencherContainer>
                                    <div>
                                        <div style={{ flex: 1, height: "auto" }}>
                                            <Title>Email</Title>
                                            {/* <CaixaContainer style={{width: "78.5vw"}}> */}
                                            <InputEmail
                                                type="email" name="email" onChange={handleChange("email")} value={email}>
                                            </InputEmail>
                                            {emailError && <LabelError>{emailError}</LabelError>}
                                            {/* </CaixaContainer> */}
                                        </div>
                                        <Containers>
                                            <div>
                                                <Title>Senha</Title>
                                                {/* <CaixaContainer style={{width: "37.5vw"}}> */}
                                                <Input
                                                    type="password" name="senha" onChange={handleChange("senha")} value={senha}>
                                                </Input>
                                                {senhaError && <LabelError>{senhaError}</LabelError>}
                                                {/* </CaixaContainer> */}
                                            </div>
                                            <div>
                                                <Title>Confirmar senha</Title>
                                                {/* <CaixaContainer style={{width: "37.5vw"}}> */}
                                                <Input
                                                    type="password" name="confirmarSenha" onChange={handleChange("confirmarSenha")} value={confirmarSenha}>
                                                </Input>
                                                {confirmarSenhaError && <LabelError>{confirmarSenhaError}</LabelError>}
                                                {/* </CaixaContainer> */}
                                            </div>
                                        </Containers>
                                        <Containers>
                                            <div>
                                                <input type="text" name="celular" value={ddd + num} onChange={handleChange("celular")} hidden/>
                                                <Title>Celular</Title>
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <div style={{ marginRight: "10px" }}>
                                                        <Input
                                                            type="tel" name="ddd" onChange={handleChange("ddd")} id="ddd" value={ddd}
                                                            style={{
                                                                // width: "3vw",
                                                                minWidth: "20px",
                                                                maxWidth: "40px"
                                                            }}>
                                                        </Input>
                                                    </div>
                                                    <div>
                                                        <InputCelular
                                                            type="tel" name="num" onChange={handleChange("num")} id="num" value={num}>
                                                        </InputCelular>
                                                        {/* {celularError && <LabelError>{celularError}</LabelError>} */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <Title>Telefone fixo</Title>
                                                {/* <CaixaContainer style={{width: "37.5vw"}}>  */}
                                                <Input
                                                    type="tel" name="telefone" onChange={handleChange("telefone")} value={telefone}>

                                                </Input>
                                                {telefoneError && <LabelError>{telefoneError}</LabelError>}
                                                {/* </CaixaContainer> */}
                                            </div>
                                        </Containers>
                                        <Containers>
                                            <div>
                                                <input type="text" name="celular2" value={ddd2 + num2} hidden />
                                                <Title>Celular 2 (Opcional)</Title>
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <div style={{ marginRight: "10px" }}>
                                                        {/* <CaixaContainer style={{width: "4.95vw"}}>  */}
                                                        <Input name="ddd2"  onChange={handleChange("ddd2")} value={ddd2} style={{
                                                            // width: "3vw",
                                                            minWidth: "20px",
                                                            maxWidth: "40px"
                                                        }}></Input>
                                                        {/* </CaixaContainer> */}
                                                    </div>
                                                    <div>
                                                        {/* <CaixaContainer style={{width: "30.95vw"}}>  */}
                                                        <InputCelular name="celular2"  onChange={handleChange("num2")} value={num2}></InputCelular>
                                                        {/* </CaixaContainer> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </Containers>
                                    </div>
                                </PreencherContainer>
                                <Container>
                                    <Link to="/empresa" style={{ textDecoration: "none" }}>
                                        <ButtonVoltar onClick={handleClick} title="Voltar página" />
                                    </Link>

                                    <ButtonSalvar //"/conclusao"
                                        type="submit"
                                        // loading={isSubmitting}
                                        title="Salvar e finalizar"
                                        onClick={submitForm}
                                    >Salvar e finalizar</ButtonSalvar>
                                    {status && <LabelError>{status}</LabelError>}

                                </Container>
                            </Cadastro>
                            {/* </Form> */}
                        </>
                    );
                }}
            </Formik>
        </>
    );
};

export default PaginaRevendaCadastro;


{/* <Title>Descrição</Title> */ }

{/* font-size: 16px;
                height: 100%;
                width: 100%;
                border: 0;
                padding-left: 2em;
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 160%; */}
{/*  */ }



{/* <CaixaContatoButton title="Enviar"></CaixaContatoButton> */ }

{/*  */ }