import React from "react";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import RootElement from "../components/root";
import { ThemeProvider } from "../contexts/theme";
import PaginaAssistencia from "./assistencia";
import PaginaContato from "./contato";
import HomePage from "./home";
import LoginPage from "./login";
import ProductsPage from "./products";
import PaginaRevenda from "./revenda/dadosPessoais";
import PaginaSobre from "./sobre";
import PaginaEmpresa from "./revenda/empresa";
import PaginaRevendaCadastro from "./revenda/revendaCadastro";
import PaginaRevendaConclusao from "./revenda/revendaConclusao";
import {
  addProduct,
  edtProduct,
  loaderProductByID,
  loaderProducts,
  loaderProductsCat,
  // loaderProductsByCategory,
} from "../controllers/Products";
import { PageProduto } from "./products/produto";
import AdicionarProduto from "./addProduto";
import EditarProduto from "./edtProduto";
import EditProduto from "./edtProduto/produto";
import FormCadastro from "./revenda/formulario";
import RootRevenda from "./revenda/root";
import ErrorPage from "../components/errorPage/errorPage";
import PrivateRouter from "../components/PrivateRouter";
import PoliticaPrivacidade from "./termos/privacidade";
import TermosdeUso from "./termos/termosuso";
import PulsadorPage from "./pulsador";
import CriarPulsadorPage from "./pulsador/criarPulsador";
import FinalizarPulsadorPage from "./pulsador/finalizar";
import SlideProvider from "../contexts/currentSlide";
import ProductsCatPage from "./products/categoria";
// import Form from "../components/barraProgresso";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/registro",
    // action: addCadastro,
    // loader: loaderCadastroByID,
    element: <FormCadastro />,
  },
  {
    path: "product",
    element: <PrivateRouter />,
    children: [
      {
        path: "new",
        action: addProduct,
        // loader: loaderProducts,
        element: <AdicionarProduto />,
      },
      {
        path: "edit",
        loader: loaderProducts,
        element: <EditarProduto />,
      },
      {
        path: "edit/:productID",
        loader: loaderProductByID,
        action: edtProduct,
        element: <EditProduto />,
        // element: <ProductsPage />,
      },
    ],
  },
  {
    path: "register",
    element: <RootRevenda />,
    children: [
      {
        path: "",
        // action: addCadastro,
        // loader: loaderCadastroByID,
        element: <PaginaRevenda />,
        // element: <ProgressBar />,
      },
      {
        path: "empresa",
        // action: addCadastro,
        // loader: loaderCadastroByID,
        element: <PaginaEmpresa />,
      },
      {
        path: "revendaCadastro",
        // loader: loaderCadastroByID,
        // action: addCadastro,
        element: <PaginaRevendaCadastro />,
      },
      {
        path: "revendaConclusao",
        element: <PaginaRevendaConclusao />,
      },
    ],
  },
  {
    path: "/",
    element: <RootElement />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <HomePage />,
      },
      {
        path: "products",
        loader: loaderProducts,
        element: <ProductsPage />,
      },
      {
        path: "products/:productCategory",
        loader: loaderProductsCat,
        element: <ProductsCatPage />,
      },
      {
        path: "products/:productCategory/:productID",
        loader: loaderProductByID,
        element: <PageProduto />,
        // element: <ProductsPage />,
      },
      {
        path: "sobre",
        element: <PaginaSobre />,
      },
      {
        path: "assistencia",
        element: <PaginaAssistencia />,
      },
      {
        path: "contato",
        element: <PaginaContato />,
      },
      {
        path: "privacidade",
        element: <PoliticaPrivacidade />,
      },
      {
        path: "termosdeuso",
        element: <TermosdeUso />,
      },
      {
        path: "makeYourPulsador",
        element: <PulsadorPage />,
      },
      {
        path: "criarPulsador",
        element: (
          <SlideProvider>
            <CriarPulsadorPage />,
          </SlideProvider>
        ),
      },
      {
        path: "pulsadoresFinalizados",
        element: (
          <SlideProvider>
            <FinalizarPulsadorPage />,
          </SlideProvider>
        ),
      },
    ],
  },
]);

export default function Routers() {
  return (
    <ThemeProvider>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}
