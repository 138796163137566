import React, { createContext, useContext } from "react";
import { useForm } from "react-hook-form";
import { Form } from "react-router-dom";
import PaginaRevenda from "./dadosPessoais";
import PaginaRevendaCadastro from "./revendaCadastro";
import PaginaEmpresa from "./empresa";



const FormCadastro = () => {
  const { register, handleSubmit } = useForm<FormData>();

  const onSubmit = async () => {
    try {
      // await formData
      alert("Dados enviados com sucesso!");
    } catch (error) {
      console.error(error);
      alert("Ocorreu um erro ao enviar os dados.");
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {/* <FormContext.Provider value={{ formData, setFormData }}> */}
        <label>
          <PaginaRevenda/>
        </label>
        <label>
          <PaginaEmpresa/>
        </label>
        <label>
          <PaginaRevendaCadastro/>
        </label>

    </Form>
  );
};

export default FormCadastro;