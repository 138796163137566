import styled from "styled-components";

export const LoginContainer = styled.div`
    min-height: 500px;
    max-height: 1000px;
    min-width: 400px;
    max-width: 800px;
    align-items: center;

    @media screen and (max-width: 480px) {
        /* min-width: 200px;
        max-width: 350px;  */
    }
`;

export const LoginSession = styled.div`
    flex: 1;
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    @media screen and (max-width: 480px) {
        width: 96vw;
    }
`;

export const LoginBackView = styled.div`
    width: 575px;
    height: 760px;
    background: #1e272f;
    box-shadow: 0px 370px 148px rgba(0, 0, 0, 0.01), 0px 208px 125px rgba(0, 0, 0, 0.05), 0px 93px 93px rgba(0, 0, 0, 0.09), 0px 23px 51px rgba(0, 0, 0, 0.1),
        0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 70px;
    display: grid;
    align-items: start;

    @media screen and (max-width: 480px) {
        width: 350px;
        height: 500px;
        border-radius: 40px;
    }
`;

export const BodyLoginCard = styled.div`
    width: 470px;
    align-items: center;
    margin-left: 9%;

    @media screen and (max-width: 480px) {
        width: 0px;
        margin-left: 0%;
    }
`;

export const LabelLogin = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 145%;
    color: #787d82;

    @media screen and (max-width: 480px) {
        font-size: 12px;
    }
`;
