import styled from "styled-components";

export const HomePageContainer = styled.div`
    /* min-height: 100vh; */
    display: grid;
    flex-wrap: wrap;
    /* flex-direction: column; */
    /* flex-wrap: nowrap; */
    align-items: center;
    width: 100%;
    /* margin-top: 2.5vw; */
`;

export const Session = styled.div`
    /* width: 100%; */ //
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 3rem;

    div {
        align-items: center;
    }

    @media screen and (max-width: 480px) {
        /* margin-bottom: -30%; */
        text-align: center;
        margin-bottom: 1rem;
    }
`;

export const Session2 = styled.div`
    /* width: 100%; */ //
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: row; */
    justify-content: space-evenly;
    /* flex-wrap: wrap; */
    min-width: 400px;

    @media screen and (max-width: 480px) {
        min-width: 190px;
        /* max-width: 270px; */
        /* margin-top: -51%; */
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 300px;
        /* max-width: 400px; */
    }
`;

// export const SessionMarck = styled(Session)`
//     /* background-color: green; */
//     position: relative;
//     /* align-items: center; */
//     /* justify-content: center; */
//     /* display: flex; */
//     ::after {
//         content: "";
//         width: 200vw;
//         height: 334px;
//         padding: 1.35%;
//         top: 0;
//         right: calc(40% - 27%);
//         position: absolute;

//         background-color: #F3F3F3;
//         /* background-color: red; */
//         border-radius: 45px;
//         z-index: -1;
//     }

//     @media screen and (max-width: 890px) {
//         ::after {
//             right: calc(40% - 39%);
//         }
//     }

//     @media screen and (max-width: 480px) {

//         ::after {
//         right: calc(40% - 35%);
//         height: 235px; 
//         border-radius: 25px;
//         }
//     }
// `;

export const Line = styled.div`
    width: 1150px;
    /* border: 1px; */
    height: 2px;
    background-color: #00000087;
`;

export const SessionMarc = styled(Session)`
    /* position: relative; */
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 50px;
    margin-top: 50px;
    flex-wrap: wrap;
    
    @media screen and (max-width: 480px) {
        gap: 30px;
        margin-top: 30px;
        flex-direction: column;
        /* margin-bottom: 50px; */
    }
    
    @media screen and (min-width: 481px) and (max-width: 890px) {
        gap: 40px;
        margin-top: 40px;
        /* margin-top: 10px;
        text-align: start;
        font-size: 22px; */
    }
`;

export const TitleSession = styled.h2`
    /* Title/H2-48 */

    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 135%;
    /* identical to box height, or 65px */

    letter-spacing: -0.02em;

    color: #1e272f;

    @media screen and (max-width: 480px) {
        font-size: 28px;
        align-items: center;
        text-align: center;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 36px;
    }
`;

export const TextSession = styled.text`
    /* Body/Medium-16 */

    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    min-width: 400px;
    /* or 26px */

    /* Text & icon/60 */

    color: #787d82;
    
    @media screen and (max-width: 480px) {
        font-size: 12px;
        /* min-width: 200px; */
    }
`;

export const Produtos = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 350px;
    height: 401px;
    /* background-color: #1c71bb44; */
    
    @media screen and (max-width: 480px) {
        /* font-size: 12px; */
        /* min-width: 200px; */
    }
`;
