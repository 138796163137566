import * as React from "react";
import styled, { css } from "styled-components";
import Stroke from "../../assets/Stroke.png";
import createContext from "../../utils/createContex";
import { useSliderContext } from "../../contexts/currentSlide";

interface SlideContext {
  currentSlide: number;
}

const [sContext, Provider] = createContext<SlideContext>();

const SCarouselWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  /* background: #ff0077; */
  align-items: center;
  justify-content: center;
  /* justify-content: flex-end; */
  /* position: static; */
`;

interface ICarouselSlide {
  active?: boolean;
}

const SCarouselSlide = styled.div<ICarouselSlide>`
  flex: 0 0 auto;
  opacity: ${(props) => (props.active ? 1 : 0)};
  transition: left 0.5s ease;
  width: 100%;
  /* background: #88ff00; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* display: ${(props) => (props.active ? "flex" : "none")}; */
  position: relative;
`;

interface ICarouselProps {
  currentSlide: number;
  active?: boolean;
}

const SCarouselSlides = styled.div<ICarouselProps>`
  display: flex;
  /* display: ${(props) => (props.active ? "flex" : "none")}; */
  flex-direction: row-reverse;
  ${(props) =>
    props.currentSlide &&
    css`
      transform: translateX(${props.currentSlide * 100}%);
    `};
  transition: all 0.5s ease;
  /* gap: 200px; */
  /* justify-content: right; */
  /* background: #2f00ff; */
  width: 800px;
  /* max-width: 600px; */
  /* margin-left: -30%; */
  position: static;
`;

const Text = styled.h1`
  font-family: "Rubik";
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  border: 0;
  text-align: center;
  outline: 0 none;
  max-width: 200px;
  word-wrap: break-word;
  /* flex-wrap: wrap; */

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* [type=number] {
        -moz-appearance: textfield;
    } */

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;

interface IProps {
  children: JSX.Element[];
  cena?: string;
  ch?: React.ReactNode;
}

// const CSlideContext = React.createContext();

// export const cSlide = currentSlide;

// export async function Current( current: number ) {
//   const currentSlide = await current;
//   return currentSlide;
// }

// console.log(Current);

// export const getCadastroByID = async (id: string) => {
//     console.log("getCadastroByID");
//     const ref = doc(collection(db, 'cadastros'), id);
//     const listCadastros = await getDoc(ref);
//     return listCadastros;
// }

const Carousel = ({ children, cena, ch }: IProps) => {
  const { slider: currentSlide, setSlider: setCurrentSlide } =
    useSliderContext();
  // const [currentSlide, setCurrentSlide] = React.useState(0);
  const activeSlide = React.useMemo(() => {
    return children.map((slide, index) => (
      <SCarouselSlide active={currentSlide === index} key={index}>
        {/* <h1>{index}</h1> */}
        {slide}
      </SCarouselSlide>
    ));
  }, [children, currentSlide]);

  // export const CSlide: number = currentSlide;

  React.useEffect(() => {
    if (children.length) {
      setCurrentSlide(children.length - 1);
    }
  }, [children.length]);

  // return function currentS() {
  //   const cSlide = currentSlide;
  //   return cSlide;
  // }

  // React.useEffect(() => {
  //   if (currentSlide) {
  //     // setCurrentSlide(children.length - 1);
  //     export const currentS = currentSlide;
  //   }
  // }, [currentSlide]);

  // console.log("activeSlide: ", activeSlide);
  // console.log("activeSlide.length: ", activeSlide.length);
  // console.log(
  //   ">: ",
  //   (currentSlide > 0
  //     ? currentSlide - 1
  //     : currentSlide + activeSlide.length - 1) % activeSlide.length
  // );
  // console.log(
  //   "<: ",
  //   (currentSlide + 1 + activeSlide.length) % activeSlide.length
  // );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        overflow: "hidden",
        // background: "#2f00ff"
      }}
    >
      <SCarouselWrapper>
        <SCarouselSlides currentSlide={currentSlide}>
          {activeSlide}
        </SCarouselSlides>
      </SCarouselWrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "2%",
          gap: "10%",
        }}
      >
        <button
          // value={esquerda}
          style={{
            border: "0 none",
            background: "transparent",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => {
            setCurrentSlide(
              (currentSlide + 1 + activeSlide.length) % activeSlide.length
            );
          }}
        >
          <img width={10} src={Stroke} alt="esquerda" />
        </button>
        <Text>{cena}</Text>
        <button
          // value={direita}
          style={{
            border: "0 none",
            background: "transparent",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => {
            setCurrentSlide(
              (currentSlide > 0
                ? currentSlide - 1
                : currentSlide + activeSlide.length - 1) % activeSlide.length
            );
          }}
        >
          <img
            width={10}
            src={Stroke}
            style={{ transform: "rotate(180deg)" }}
            alt="direita"
          />
        </button>
      </div>
      <Provider value={{ currentSlide }}>{ch}</Provider>
    </div>
  );
};

export default Carousel;

export { sContext as useSContext };
