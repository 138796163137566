import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
// import image from '../../assets/image.png';
import Dropdown from "../../addProduto/dropdownCat/dropCat";
import logo from "../../../assets/logo.png";
import DropdownVar from "../../addProduto/dropdownVar/dropVar";
import { Form, Link, useLoaderData } from "react-router-dom";
import Arrow from "../../../assets/Arrow.svg";
import UploadImage from "../../addProduto/addImagem/ImagesUpload";
// import { getFirestore, collection, addDoc, updateDoc, doc } from "firebase/firestore";
// import { initializeApp } from "firebase/app";
// import { firebaseConfig } from '../../../middlewares/firebase';
// import EspComponente from '../../addProduto/addEsp';
import Products from "../../../controllers/getProducts";
// import EspComponent from './esp';
import { useVariacoes } from "../../../hooks/variacoes";
// import UploadArquivo from '../../addProduto/addArquivo';
import FileComponent from "./file";
import Modelo from "./modelo";

const PaginaEdt = styled.div`
  display: flex;
  flex-direction: column;
`;

const BtnAddEdt = styled.button`
  display: flex;
  width: 11.8vw;
  height: 4.5vw;
  border-radius: 4px;
  background: #1c70bb;
  align-items: center;
  justify-content: center;
  border: 0;
  cursor: pointer;

  :hover,
  :focus {
    background: #1c70bb;
    color: #1c70bb;
    /* transform: translateY(-0.25em); */
  }

  @media screen and (max-width: 480px) {
    width: 60px;
    height: 40px;
  }
`;

const Texto = styled.h3`
  font-family: "Rubik";
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;

  @media screen and (max-width: 480px) {
    font-size: 11px;
  }
`;

const Img = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 56.3vw;
    height: 38.45vw; */
  width: 650px;
  height: 583px;
  background: #f5f7f9;
  box-shadow: -8px 26px 40px rgba(131, 155, 177, 0.08);
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 480px) {
    width: 230px;
    height: 160px;
  }
`;

const Image = styled.div`
  display: flex;
  width: 140px;
  height: 140px;
  background: #f5f7f9;
  align-items: center;
  justify-content: center;
  box-shadow: -8px 26px 40px rgba(131, 155, 177, 0.08);

  @media screen and (max-width: 480px) {
    width: 130px;
    height: 80px;
  }
`;

const Text = styled.h4`
  font-family: "Rubik";
  font-size: 14px;
  font-weight: 400;
  line-height: 0%;
  color: #4b5259;

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
`;

const Title = styled.h3`
  font-family: "Rubik";
  font-size: 20px;
  font-weight: 600;
  color: #9cafc1;
  line-height: 135%;

  @media screen and (max-width: 480px) {
    font-size: 15px;
    flex-wrap: wrap;
    line-height: 100%;
    padding: 0 5px;
  }
`;

const Input = styled.input`
  background: #f2f7fd;
  height: 3.15vw;
  border-radius: 10px;
  padding: 0 1.5vw;
  border: 0;
  outline: 0 none;
  font-family: "Rubik";
  font-size: 14px;

  @media screen and (max-width: 480px) {
    font-size: 11px;
    height: 30px;
  }
`;

// const DropDown = styled.input`
//     background: #F2F7FD;
//     height: 3.15vw;
//     border-radius: 10px;
//     border: 0;
//     outline: 0 none;
//     padding: 0 1vw;
// `;

const TextArea = styled.textarea`
  background: #f2f7fd;
  border-radius: 10px;
  width: 29.25vw;
  height: 14.4vw;
  border: 0;
  outline: 0 none;
  padding: 1.5vw 2vw;
  font-family: "Rubik";
  font-size: 14px;
  resize: none;

  @media screen and (max-width: 480px) {
    font-size: 12px;
    height: 90px;
  }
`;

export const NavIten = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 20px;
  z-index: 3;

  font-family: "Rubik";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  line-height: 145%;
  text-decoration: none;
  color: #fff;

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;

// const A = styled.div<{ url: string; fixed?: boolean; }>`
//     background-image: url(${({ url }) => url});
//     background-repeat: no-repeat;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     background-size: cover;
//     width: 65%;
//     height: 65%;
//     /* min-height: 500px; */
//     background-position: bottom;
//     overflow: hidden;
//     position: relative;

//     ::before {
//         content: "";
//         position: absolute;
//         inset: 0;
//     }
// `;

const Div = styled.div`
  display: flex;
  gap: 3vw;
  /* background-color: aliceblue; */
  /* width: 100%; */
  /* justify-content: center; */
  /* padding-right: 6.3vw; */

  @media screen and (max-width: 480px) {
    /* font-size: 14px; */
    /* gap: 40px; */
    /* padding-right: 0px; */
  }
`;

// const Div2 = styled.div`
//     display: flex;
//     gap: 3vw;

//     @media screen and (max-width: 480px) {
//         display: grid;
//         gap: 0;
//     }
// `;

const Div3 = styled.div`
  display: flex;
  gap: 3vw;
  /* padding-right: 1vw; */

  @media screen and (max-width: 480px) {
    /* padding-right: 13vw; */
  }
`;

export const Check = styled.input`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  border: 1px solid;
  cursor: pointer;

  @media screen and (max-width: 480px) {
  }
`;

// export function AddProduto() {
//     const product = useLoaderData() as Products | undefined;

//     // if (!product) {
//     //     return (
//     //         <>
//     //             <>Produto nao existe</>
//     //         </>
//     //     )
//     // }

// function EspecificacoesList({ especificacoes }: { especificacoes: Record<string, string> }) {
//     // const [esp, setEsp] = useState<string>("");
//     return (<>
//         {/* <EspComponent onChangeEsp={(e) => {
//             setEsp(JSON.stringify(e));
//         }} /> */}
//     </>
//     )
// }

// function EspecificacoesList({ especificacoes }: { especificacoes: Record<string, string> }) {
//     const [esp, setEsp] = useState<string>("");
//     return (<>
//         {Object.keys(especificacoes).map(key => {
//             return (
//                 <>
//                     {/* <div style={{ display: "flex", flexDirection: "row", gap: "1.2vw" }}> */}
//                         <input name="esp" type='text'
//                             value={key}
//                         />
//                         <EspComponent
//                         //  defaultValue={key}
//                          onChangeEsp={(e) => {
//                             setEsp(JSON.stringify(e));
//                         }} />
//                     {/* </div> */}
//                 </>
//             )
//         })}

//     </>)
// }

// const firestore = getFirestore(initializeApp(firebaseConfig));

const EditProduto: React.FC = () => {
  const product = useLoaderData() as Products;
  // const [formData, setFormData] = useState({ name: "", category: "", desc: "", datasheets: "", esp: "", preco: "", quant: "", variants: "" });
  // const [uploadStatus, setUploadStatus] = useState<string>();
  const [categorys, setCategory] = useState(product.category);
  // const [esps, setEsp] = useState<string>("");
  // const [color, setColor] = useState<string>("");
  const [img, setImg] = useState<string[]>([]);
  const [vars, setVars] = React.useState<Array<[string, string]>>([]);
  const [datasheet, setDatasheets] = useState<string>("");
  const [model, setModel] = useState<string>(product.modelo);
  const [persiana, setPersianas] = useState<string>("false");

  // console.log("product.modelo: ", product.modelo)
  console.log("persiana: ", persiana);

  useEffect(() => {
    let esp: Record<string, string> = {};
    vars.forEach((e) => {
      esp[e[0]] = e[1];
    });
    // onChangeEsp?.(esp);
  }, [vars]);

  //
  const {
    name,
    category,
    variants,
    modelo,
    quantTeclas,
    persianas,
    // variants: [{
    //     color,
    //     img
    // }],
    // quant,
    // preco,
    // esp,
    datasheets,
    desc,
    imgs = [0, 1, 2, 3, 4],
    // id,
  } = product;

  const {
    listColors,
    setvaricao,
    varicaoSender,
    updateVariacao,
    varicaoSeleted,
    newVariacao,
  } = useVariacoes({ listVariacao: variants });

  useEffect(() => {
    setPersianas(persianas);
  }, [persianas]);

  //   const U;

  console.log("persianas: ", persianas);

  // console.log("listColors:", listColors)
  // function ChangeImg({imgs, img}: {imgs: string, img: string}) {
  //     imgs = img
  // }

  // const changeImg = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     if (event.target.files && event.target.files[0]) {}
  // }

  // const handleEditSubmit = async (event: React.FormEvent<HTMLFormElement>) => { }

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => { };

  const handleImgUpdate = useCallback(
    (numberImg: number) => (ImageUrl: string) => {
      if (varicaoSeleted) {
        let newImages = varicaoSeleted.img;
        newImages[numberImg] = ImageUrl;
        updateVariacao(varicaoSeleted.color, newImages);
      } else {
        setImg((old) => {
          let newImages = [...old];
          newImages[numberImg] = ImageUrl;
          return newImages;
        });
      }
    },
    [varicaoSeleted, updateVariacao]
  );

  const handlesUploadManual = useCallback((data: Array<[string, string]>) => {
    const obj: Record<string, string> = {};
    data.forEach(([key, value]) => {
      obj[key] = value;
    });
    setDatasheets(JSON.stringify(obj));
  }, []);

  return (
    <Form method="post">
      <PaginaEdt>
        <div
          style={{
            display: "flex",
            padding: "3vw 30vw 5vw 3.5vw",
            justifyContent: "space-between",
          }}
        >
          <Link to="/">
            <img
              src={logo}
              alt=""
              style={{ width: "", height: "4.2vw", justifyContent: "initial" }}
            />
          </Link>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "24.8vw",
              height: "5.4vw",
              background: "#FAFBFF",
              justifyContent: "center",
              borderRadius: "4px",
              gap: "0.4vw",
            }}
          >
            <Link to="/product/new" style={{ textDecoration: "none" }}>
              <BtnAddEdt
                style={{
                  background: "#FAFBFF",
                }}
              >
                <Texto style={{ color: "#787D82" }}>Adicionar Produto</Texto>
              </BtnAddEdt>
            </Link>
            <Link to="/product/edit" style={{ textDecoration: "none" }}>
              <BtnAddEdt>
                <Texto>Editar Produto</Texto>
              </BtnAddEdt>
            </Link>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            justifyContent: "center",
            marginBottom: "5vw",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "grid", gap: "0.6vw" }}>
              <Image>
                <input
                  type="text"
                  name="image2"
                  defaultValue={imgs[1]}
                  value={img[1]}
                  hidden
                />
                <UploadImage
                  onImgUpload={handleImgUpdate(1)}
                  name="image2"
                  imageSRC={
                    varicaoSeleted ? varicaoSeleted.img[1] : String(imgs[1])
                  }
                />
              </Image>
              <Image>
                <input
                  type="text"
                  name="image3"
                  defaultValue={imgs[2]}
                  value={img[2]}
                  hidden
                />
                <UploadImage
                  onImgUpload={handleImgUpdate(2)}
                  name="image3"
                  imageSRC={
                    varicaoSeleted ? varicaoSeleted.img[2] : String(imgs[2])
                  }
                />
              </Image>
              <Image>
                <input
                  type="text"
                  name="image4"
                  defaultValue={imgs[3]}
                  value={img[3]}
                  hidden
                />
                <UploadImage
                  onImgUpload={handleImgUpdate(3)}
                  name="image4"
                  imageSRC={
                    varicaoSeleted ? varicaoSeleted.img[3] : String(imgs[3])
                  }
                />
              </Image>
              <Image>
                <input
                  type="text"
                  name="image5"
                  defaultValue={imgs[4]}
                  value={img[4]}
                  hidden
                />
                <UploadImage
                  onImgUpload={handleImgUpdate(4)}
                  name="image5"
                  imageSRC={
                    varicaoSeleted ? varicaoSeleted.img[4] : String(imgs[4])
                  }
                />
              </Image>
            </div>
            <div>
              <Img>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <input
                    type="text"
                    name="image1"
                    defaultValue={imgs[0]}
                    value={img[0]}
                    hidden
                  />
                  <UploadImage
                    onImgUpload={handleImgUpdate(0)}
                    name="image1"
                    imageSRC={
                      varicaoSeleted ? varicaoSeleted.img[0] : String(imgs[0])
                    }
                  />
                </div>
              </Img>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // width: "100%"
            // background: "red"
          }}
        >
          <Div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Title>Nome do produto</Title>
              <Input
                type="text"
                name="name"
                defaultValue={name}
                style={{ width: "29.25vw" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Title>Categoria</Title>
              <input
                type="text"
                value={categorys === "e" ? category : categorys}
                // defaultValue={category}
                name="category"
                hidden
              />
              <div
              // style={{ marginRight: "19vw" }}
              >
                <Dropdown
                  //  cate={category}
                  //  name="category"
                  onChangeCategoria={setCategory}
                  // categ={category}
                />
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {/* <Title>{varicaoSeleted?.color}</Title> */}
              <Title>Cores</Title>
              <input type="text" name="variants" value={varicaoSender} hidden />
              <div>
                <DropdownVar
                  listColors={listColors}
                  onChangeVariacao={setvaricao}
                  onChangeNewVariacao={(e) => {
                    newVariacao(e, []);
                  }}
                />
              </div>
            </div>
          </Div>

          <div
            style={{ display: "flex", marginTop: "1vw", alignItems: "center" }}
          >
            <Div3>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Title>Descrição do produto</Title>
                <TextArea name="desc" defaultValue={desc} />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", gap: "3vw" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Title>Modelo</Title>
                    <input
                      type="text"
                      value={model === "e" ? modelo : model}
                      name="modelo"
                      hidden
                    />
                    <div>
                      <Modelo onChangeModelo={setModel} />
                    </div>
                    {/* <Input defaultValue={modelo} type="text" name="modelo" placeholder="Modelo do Produto" style={{ width: "30.25vw" }}/> */}
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Title>Teclas</Title>
                    <div>
                      <Input
                        type="number"
                        name="quantTeclas"
                        defaultValue={quantTeclas}
                        // value={formData.quant}
                        // onChange={handleInputChange}
                        placeholder="quant teclas"
                        style={{
                          // minWidth: "50px",
                          maxWidth: "90px",
                          // width: "10vw",
                          height: "40px",
                          // width: "12.8vw"
                        }}
                      ></Input>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          gap: "3%",
                        }}
                      >
                        <input name="persianas" hidden value={persiana} />
                        <Check
                          type="checkbox"
                          value={persianas}
                          onClick={() => {
                            setPersianas((old) =>
                              old === "true" ? "false" : "true"
                            );
                          }}
                          checked={persiana === "true"}
                          defaultValue={persianas}
                        />
                        <Text style={{ color: "#9cafc1" }}>Persianas</Text>
                      </div>
                      {/* <Modelo onChangeModelo={setModelo}/> */}
                    </div>
                  </div>
                </div>
                {/* <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Title>Especificações</Title>
                                    <input name="esp" type='text'
                                        value={esps} hidden
                                    /> */}
                {/* <EspecificacoesList especificacoes={esp} /> */}
                {/* <EspComponent especificacoes={esp} onChangeEsp={(e) => {
                                        setEsp(JSON.stringify(e));
                                    }} />
                                </div> */}
                {/* <Div2>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <Title>Quantidade Mínima</Title>
                                        <Input
                                            type="number"
                                            name="quant"
                                            defaultValue={quant}
                                            style={{ minWidth: "50px", maxWidth: "145px" }}
                                        />
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <Title>Preço</Title>
                                        <Input
                                            type="text"
                                            name="preco"
                                            defaultValue={preco}
                                            style={{ minWidth: "50px", maxWidth: "145px" }}
                                        />
                                    </div>
                                </Div2> */}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Title>Manual</Title>
                  <input
                    type="text"
                    hidden
                    name="datasheets"
                    value={datasheet}
                  />
                  {/* <UploadArquivo onChange={(e) => {handlesUploadManual(e)}}/> */}
                  <FileComponent
                    onChange={(e) => {
                      handlesUploadManual(e);
                    }}
                    file={datasheets}
                  />
                  {/* <Input
                                        type="url"
                                        name="datasheets"
                                        defaultValue={datasheets}
                                        style={{ minWidth: "50px", maxWidth: "145px" }}
                                    /> */}
                </div>
              </div>
            </Div3>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            padding: "3.5vw 4vw",
            justifyContent: "space-between",
          }}
        >
          <NavIten
            onClick={handleClick}
            to="/"
            style={{ display: "flex", textDecoration: "none" }}
          >
            <img
              style={{ transform: "matrix(-1, 0, 0, -1, 0, 0)" }}
              src={Arrow}
              alt="Arrow"
            />
            <Text
              style={{
                fontSize: "16px",
                lineHeight: "160%",
                color: "#1C70BB",
                fontWeight: 500,
              }}
            >
              Voltar para a página inicial
            </Text>
          </NavIten>
          <BtnAddEdt
            onClick={handleClick}
            type="submit"
            style={{
              width: "20.8vw",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            }}
          >
            <Texto>Editar</Texto>
          </BtnAddEdt>
        </div>
      </PaginaEdt>
    </Form>
  );
};

export default EditProduto;
