import styled from "styled-components";
import { Link } from "react-router-dom";

export const ContentFooter = styled.footer`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    color: ${({ theme: { background } }) => background};
    /* padding: 0 0 0 4vw; // */
    /* height: 100%; */
    width: 100%;
    /* background-size: cover; */
    /* min-width: 300px; */
    /* max-width: 1000px; */

    /* Background/Card */
    background: ${({ theme: { footer } }) => footer};
    
    * {
        color: ${({ theme: { paper } }) => paper};
    }

    @media screen and (max-width: 890px) {
        gap: 40px;
        margin-top: 40px;
    }
`;

export const Pree = styled.pre`
    gap: 16px;
    color: #1E272F;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 14px;
    line-height: 145%;
    /* padding: 0 120px; */
    display: flex; 
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 480px) {
        gap: 10px;
        /* font-size: 1px; */
        /* padding: 0 70px; */
        /* line-height: 145%;            //25px; */
    }

    @media screen and (max-width: 890px) {
        gap: 20px;
    }
`;

export const Pre = styled.pre`
    display: grid;
    align-items: flex-start;
    gap: 15px;
    color: #1E272F;
    font-family: 'Rubik';
    font-weight: 400;
    line-height: 145%;
    /* padding-left: 100px; // */
    font-weight: 500; 
    font-size: 16px;
    
    @media screen and (max-width: 480px) {
        gap: 0px;
        font-size: 12px;
        padding-left: 0;
        /* line-height: 145%;            //25px; */
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        padding: 0;
    }
`;

export const Line = styled.div`
    height: 273,75px;
    border: 1px;
    width: 1px;
    background-color: #00000061;

`;

export const Session = styled.div`
    display: flex; 
    justify-content: space-between; 
    padding: 100px 0;
    gap: 50px;
    /* width: 100%; */

    @media screen and (max-width: 480px) {
        padding: 10px 0;
        gap: 10px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        gap: 15px;
        padding: 0;
    }
`;

export const A = styled.a`
    font-family: 'Rubik'; 
    font-weight: 500; 
    font-size: 16px; 
    line-height: 145%; 
    text-decoration: none;
    text-decoration-color: none;

    @media screen and (max-width: 480px) {
        font-size: 10px;
    }
`;

export const NavIten = styled(Link)`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    color: #1E272F;
    font-family: "Rubik";
    font-size: 16px;
    line-height: 145%;
    font-weight: 500;
    text-decoration: none;
    /* height: 120px; */

    @media screen and (max-width: 480px) {
        font-size: 11px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        /* font-size: 14px; */
    }
`;

export const RedesSociais = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    padding-left: 10px;

    @media screen and (max-width: 480px) {
        gap: 5px;
        justify-content: center;
    }
`;

export const Tit = styled.div`
    color: #1E272F;
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 32px;
    line-height: 145%;
    /* background: red; */

    @media screen and (max-width: 480px) {
        font-size: 18px;
        margin-top: 10px;
        text-align: start;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        margin-top: 10px;
        text-align: start;
        font-size: 22px;
    }
`;

export const Contato = styled.div`
    color: #1E272F;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 16px;
    line-height: 145%;
    /* background: red; */

    @media screen and (max-width: 480px) {
        font-size: 10px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 14px;
    }
`;

export const Sobre = styled.h1`
    display: flex;
    color: #1E272F;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 16px;
    line-height: 145%;
    flex-wrap: wrap;
    max-width: 250px;
    /* background: red; */

    @media screen and (max-width: 480px) {
        font-size: 12px;
        min-width: 190px;
        max-width: 300px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        max-width: 450px;
        /* font-size: 14px; */
    }
`;

export const SobreDiv = styled.div`
    display: flex;
    flex-direction: column;
    /* background: red; */

    @media screen and (max-width: 480px) {
        /* padding-top: 10px; */
        margin-bottom: -40px;
    }

    @media screen and (max-width: 890px) {
        /* font-size: 9px; */
    }
`;