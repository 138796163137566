import React, { useEffect, useState } from "react";
import { ProductContainer, TitleSession, DivCategoria, Text, Text2, DivFlex, Line, Check, LineV } from "./styles";
import { useLoaderData } from "react-router-dom";
import Products, { getCategorys, getColors, getModelos, getVar } from "../../../controllers/getProducts";
import { ProductCard } from "../../../components/pesquisa/Widget/WidgetCard";
import { categoriasType } from "../../addProduto/dropdownCat/Dropdown";
import { useVariacoes } from "../../../hooks/variacoes";
// import { ProductCard } from "../../edtProduto/editProduct";

const ProductsCatPage: React.FC = ({ params }: any) => {
    const product = useLoaderData() as Products | undefined;
    const products = useLoaderData() as Products;
    const productsList = useLoaderData() as Products[];
    const [filterCategory, setFilterCategory] = useState<string>("");
    const [modelo, setModelo] = useState<string>("");
    const [cor, setCor] = useState<string>("");
    const [quantTeclas, setQuantTeclas] = useState<string>("");
    const [modelos, setModelos] = useState<categoriasType[]>();
    // const [categoria, setCategoria] = useState<categoriasType[]>();
    const [colors, setColors] = useState<categoriasType[]>();
    const [quantiTeclas, setQuantiTeclas] = useState<categoriasType[]>();
    const { variants } = products;
    const { listColors } = useVariacoes({ listVariacao: variants });
    // const category = params.productCategory;
    const category = productsList.find(e => e.category);
    const cores = productsList.map(e => e.variants.map(e => e.color))
    let listColor: Array<string> = []
    cores.forEach(element => {
        if (element) {
            listColor = [...listColor, ...element];
        }
    });
    listColor = listColor?.filter((item, pos, a) => {
        return a.indexOf(item) == pos && item != "" && item;
    });
    // const listColor = cores.filter((item, pos, a) => {
    //     return a.indexOf(item) == pos && item[0] !== "" && item;
    // });

    // console.log("getVar: ", getVar(category?.category))
    console.log("cores: ", cores)
    console.log("cor: ", cor)
    // console.log("aaaaaaaaaa: ", aaaaaaaaaa)
    console.log("aaa: ", listColor)
    // console.log("getColors: ", getColors(category?.category))
    // console.log("listColor: ", listColor)


    // useEffect(() => {
    //     getModelos().then((cat) => {
    //       const arratCat: categoriasType[] = cat.map(e => ({
    //         label: e,
    //         element: <Text>{e}</Text>
    //       }));
    //       setModelos(arratCat);
    //     });
    //   }, []);

    useEffect(() => {
        getModelos(category?.category).then((mod) => {
          const arratMod: categoriasType[] = mod.map(e => ({
            label: e,
            element: <Text>{e}</Text>
          }));
          setModelos(arratMod);
        });
    }, []);

    useEffect(() => {
        // getColors(category?.category).then((cor) => {
          const arratCor: categoriasType[] = listColor.map(e => ({
            label: e,
            element: <Text>{e}</Text>
          }));
          setColors(arratCor);
        // });
    }, []);

    // const teclas = productsList.map(e => e.qua)

    // useEffect(() => {
    //     // getModelos(category?.category).then((mod) => {
    //       const arratMod: categoriasType[] = mod.map(e => ({
    //         label: e,
    //         element: <Text>{e}</Text>
    //       }));
    //       setQuantiTeclas(arratMod);
    //     // });
    // }, []);
    
    //   useEffect(() => {
    //     let modelo: string = ""
    //     modelos && modelos.forEach((e) => {
    //         modelo = "e";
    //     })
    //     // onChangeModelo?.(modelo);
    // }, [modelos]);


    if (!product) {
        return (
            <>
                <>Produto nao existe</>
            </>
        );
    }
    // console.log("modelos: ", modelos)
    // console.log("categoria: ", categoria)


    const resultProducts = productsList
        .filter((product) => filterCategory === "" || (product.category || "Outros") === filterCategory);

    // console.log("modelo: ", modelo)
    // console.log("cor: ", cor)
    // console.log("quantTeclas: ", quantTeclas)

    // const result = () => {
    //     if (modelo) {
    //         return (
    //             resultProducts.filter(e => e.modelo === modelo)
    //         )
    //     }
    //     // if (cor) {
    //     //     return (
    //     //         resultProducts.filter(e => e.modelo === modelo)
    //     //     )
    //     // }
    //     if (quantTeclas) {
    //         return (
    //             resultProducts.filter(e => e.quantTeclas === quantTeclas)
    //         )
    //     }
    // }

    
    const resultProductsM = modelo ? resultProducts.filter(e => e.modelo === modelo) : resultProducts;

    // const { modelo, color, quantTeclas } = resultProductsM;
    
    const resultProductsC = cor ? resultProductsM.filter(e => e.variants.findIndex(e => e.color === cor) !== -1 ) : resultProductsM;

    const resultProductsQ = quantTeclas ? resultProductsC.filter(e => {
        if(quantTeclas === "1a2") {
            return e.quantTeclas == 1 || e.quantTeclas == 2
        }
        if(quantTeclas === "3a4") {
            return e.quantTeclas == 3 || e.quantTeclas == 4
        }
        if(quantTeclas === "6") {
            return e.quantTeclas == 6
        }
        if(quantTeclas === "persianas") {
            return e.persianas == "true"
        }
    }) : resultProductsC;

    const quant = resultProductsC.map( e => e.quantTeclas);
    console.log("quant: ", quant)
    
    console.log("quantTeclas: ", quantTeclas)
    console.log("resultProductsQ: ", resultProductsQ)
    console.log(resultProducts.map(e => e.quantTeclas))

    return (
        <>
            <ProductContainer>
                <DivCategoria>
                    <DivFlex>
                        <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", padding: "2%"}}>
                            <TitleSession>{category?.category}</TitleSession> {/* categoria */}
                            {/* { modelo &&  */}
                                {/* <> */}
                                {modelos && (
                                    <>
                                        <Text>Modelo</Text>
                                        {modelos?.map((product) => (
                                            <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "3%"}}>
                                                <Check type="checkbox" value={product.label} onClick={() => {modelo === product.label ? setModelo("") : setModelo(product.label)}} checked={modelo === product.label ? true : false}/>
                                                <Text2>{product.label}</Text2>
                                            </div>
                                        ))}
                                        <Line/>
                                    </>
                                )}

                                {resultProducts.map(e => e.variants.map(e => e.color)).length > 1 && (
                                    <>
                                        <Text>Cor</Text>
                                        {colors?.map((product) => (
                                            <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "3%"}}>
                                                <Check type="checkbox" value={product.label} onClick={() => {cor === product.label ? setCor("") : setCor(product.label)}} checked={cor === product.label ? true : false}/>
                                                <Text2>{product.label}</Text2>
                                            </div>
                                        ))}
                                        <Line/>
                                    </>
                                )}

                                {resultProducts.map(e => e.quantTeclas).length > 1 && (
                                    <>
                                        <Text>Quantidade de Teclas</Text>
                                        <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "3%"}}>
                                            <Check type="checkbox" value={"1a2"} onClick={() => {quantTeclas === "1a2" ? setQuantTeclas("") : setQuantTeclas("1a2")}} checked={quantTeclas === "1a2" ? true : false}/>
                                            <Text2>1 a 2 Botões</Text2>
                                        </div>
                                        <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "3%"}}>
                                            <Check type="checkbox" value={"3a4"} onClick={() => {quantTeclas === "3a4" ? setQuantTeclas("") : setQuantTeclas("3a4")}} checked={quantTeclas === "3a4" ? true : false}/>
                                            <Text2>3 a 4 Botões</Text2>
                                        </div>
                                        <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "3%"}}>
                                            <Check type="checkbox" value={"6"} onClick={() => {quantTeclas === "6" ? setQuantTeclas("") : setQuantTeclas("6")}} checked={quantTeclas === "6" ? true : false}/>
                                            <Text2>6 Botões</Text2>
                                        </div>
                                        <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "3%"}}>
                                            <Check type="checkbox" value={"persianas"} onClick={() => {quantTeclas === "persianas" ? setQuantTeclas("") : setQuantTeclas("persianas")}} checked={quantTeclas === "persianas" ? true : false}/>
                                            <Text2>Persianas</Text2>
                                        </div>
                                    </>
                                )}

                        </div>
                        <div style={{display: "flex", alignItems: "flex-start", justifyContent: "center", marginTop: "8%"
                        // background: "yellow"
                        }}>
                            <LineV/>
                        </div>
                        <div style={{display: "flex", alignItems: "flex-start", justifyContent: "center", flexWrap: "wrap", width: "70%", paddingTop: "8%"}}>
                            
                            {resultProducts.length > 0 && (
                                <div
                                    style={{display: "flex", alignItems: "flex-start", justifyContent: "center", flexWrap: "wrap", gap: "70px", height: "100%"}}
                                >
                                    {resultProductsQ.map((product) => (
                                        <ProductCard key={product.id} {...product} />
                                    ))}
                                    {/* {resultProducts.map((product) => (
                                        <ProductCard key={product.id} {...product} />
                                    ))} */}
                                </div>
                            )}

                            {/* <Produtos>
                                <Div1>
                                    <div>
                                    </div>
                                </Div1>
                                <Div2>
                                    <Div3>
                                        <div style={{}}>
                                            <Produto>Keypad Pulsador Blinker</Produto>
                                            <Div4>
                                                <TextCard> Quantidade Mínima: </TextCard>
                                                <TextCard style={{color: "#498DC9", cursor: "pointer"}}>Consultar</TextCard>
                                            </Div4>
                                            <div style={{ display: "flex", gap: "2%" }}>
                                                <TextCard> Preço: </TextCard>
                                                <TextCard style={{color: "#498DC9", cursor: "pointer"}}>Consultar</TextCard>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "flex-end",
                                                justifyContent: "end",
                                            }}
                                        >
                                            <NavIten2 to={`/products`}>
                                                <Btn title="Detalhes"></Btn>
                                            </NavIten2>
                                        </div>
                                    </Div3>
                                </Div2>
                            </Produtos> */}
                        </div>
                    </DivFlex>
                </DivCategoria>
                {/* <Destaques/> */}
                {/* <TitleSession>Destaques</TitleSession> */}
                {/* <BarraPesquisa productsList={productsList} categorys={Object.keys(categorys)} /> */}
            </ProductContainer>
        </>
    );
};

export default ProductsCatPage;
