import styled from "styled-components";
import Button from "../button";

export const ContainerButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: unset;
    /* width: 15.45vw; */
    /* width: 260px; */
    min-width: 200px;
    max-width: 800px;
    /* height: 5.95vh; */
    min-height: 50px;
    max-height: 70px;
    background: #FAFBFF;
    /* background: red; */
    border-radius: 4px;
    margin-top: 2em;
    
    @media screen and (max-width: 480px) {
        min-width: 100px;
        max-width: 400px;
        min-height: 35px;
        max-height: 45px;
        padding: 3px 5px;
        margin-left: 2em;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 160px;
        max-width: 640px;
        min-height: 40px;
        max-height: 56px;
        padding: 3px 5px;
        /* margin-left: 2em; */
    }
`;

export const ButtonLogin = styled(Button)`
    display: flex;
    /* width: 7.5vw; */
    min-width: 97.5px;
    max-width: 195px;
    /* height: 5.1vh; */
    min-height: 40px;
    max-height: 55px;
    background: #FAFBFF;
    border-radius: 4px;
    position: unset;

    span {
        color: #787D82;
    }
    
    @media screen and (max-width: 480px) {
        min-width: 45px;
        max-width: 190px;
        min-height: 30px;
        max-height: 40px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 60px;
        max-width: 190px;
        min-height: 40px;
        max-height: 55px;
        /* margin-left: 2em; */
    }
`;

export const ButtonRegister = styled(Button)`
    display: flex;
    /* width: 7.5vw; */
    min-width: 97.5px;
    max-width: 195px;
    /* height: 5.1vh; */
    min-height: 40px;
    max-height: 55px;
    background: #1C70BB;
    border-radius: 4px;
    position: unset;
    margin-left: 0.2vw;

    @media screen and (max-width: 480px) {
        min-width: 45px;
        max-width: 190px;
        min-height: 30px;
        max-height: 40px;
        margin-left: 2%;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 60px;
        max-width: 190px;
        min-height: 40px;
        max-height: 55px;
        /* padding: 3px 5px; */
        margin-left: 2%;
    }
`;