import { useCallback, useEffect, useMemo, useState } from "react"
import Products from "../controllers/getProducts";

interface useVariacoesProps {
    listVariacao?: Products['variants']
};

export const useVariacoes = ({ listVariacao }: useVariacoesProps) => {
    const [variacao, setvaricao] = useState<string>('');
    const [variacaoList, setvaricaoList] = useState<Products['variants']>([]);
    const [variacaoNew, setvaricaoNEW] = useState<Products['variants'][0]>();


    useEffect(() => {
        if (listVariacao) setvaricaoList(listVariacao);
    }, [listVariacao]);

    const varicaoSeleted = variacaoList.find(e => e.color == variacao);

    const updateVariacao = useCallback((color: string, img: Array<string>) => {
        const find = variacaoList.find(e => e.color == color);
        if (find) {
            console.log("updateVariacao", { color, find });
            setvaricaoList(old => {
                const newList = [...old.filter(e => e.color != color)];
                newList.push({
                    color, img,
                })
                return newList;
            });
        }
    }, [variacaoList]);

    const listColors = useMemo(() => {
        const colors: Array<string> = [];
        variacaoList?.forEach(v => {
            colors.push(v.color);
        })
        return colors;
    }, [variacaoList]);

    const newVariacao = useCallback((color: string, img: Array<string>) => {
        setvaricaoNEW({ color, img });
    }, [])

    const varicaoSender = JSON.stringify([...variacaoList, variacaoNew].filter(e => !!e));


    return {
        variacao,
        setvaricao,
        varicaoSeleted,
        varicaoSender,
        updateVariacao,
        newVariacao,
        listColors,
    }

}