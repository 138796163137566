import React, { FC, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useUserContex } from "../../contexts/user";

export interface PrivateRouterProps {}

const PrivateRouter: FC<PrivateRouterProps> = ({}) => {
    const { load, isAdmin } = useUserContex();

    // if (load === true) {
    //     return <>Carregando ...</>;
    // } 

    // if (!isAdmin) {
    //     return <>Acesso Restrito!</>
    // }

    return (
        <>
            <Outlet />
        </>
    );
};

export default PrivateRouter;
