import React from "react";
import styled from "styled-components";

export const PPrivacidade = styled.div`
    min-width: 300px;
    /* max-width: 1500px; */
    /* min-height: 150vh; */
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
`;

export const Session = styled.div`
    min-width: 300px;
    max-width: 1300px;
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: row; */
    justify-content: space-evenly;
    /* flex-wrap: wrap; */
    /* max-width: 1500px; */
    margin-bottom: 40px;
    
    @media screen and (max-width: 480px) {
        margin-bottom: 0px;
        /* text-align: center; */
    }
`;

export const TitleSession = styled.h2`

    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    /* line-height: 64.8px; */
    margin: 50px 50px;

    /* identical to box height, or 65px */

    letter-spacing: -0.01em;

    color: #1e272f;

    @media screen and (max-width: 480px) {
        font-size: 36px;
        margin: 30px 37px;
        margin-bottom: 0px;
    }
    
`;

export const At = styled.h2`

    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    /* line-height: 64.8px; */
    margin: 50px 50px;
    margin-top: -3.5%;

    /* identical to box height, or 65px */

    letter-spacing: -0.01em;

    color: #1e272f;

    @media screen and (max-width: 480px) {
        font-size: 36px;
        margin: 30px 37px;
        margin-bottom: 0px;
    }
    
`;

export const TextSession = styled.text`
    display: flex;
    padding: 32px;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.01em;
    margin: -40px 10px;
    color: #787d82;

    @media screen and (max-width: 480px) {
        font-size: 15px;
        margin: 0px 17px;
        padding: 10px 25px;
    }
    
`;

export const Sub = styled.h2`

display: flex;
    padding: 30px;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.01em;
    margin: 15px 10px -40px;
    color: #1e272f;

    @media screen and (max-width: 480px) {
        font-size: 15px;
        margin: 0px 17px;
        padding: 10px 25px;
    }
    
`;

export const Sub2 = styled.h2`

display: flex;
    padding: 30px;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.01em;
    margin: -40px 10px -40px 50px;
    color: #1e272f;

    @media screen and (max-width: 480px) {
        font-size: 15px;
        margin: 0px 17px;
        padding: 10px 25px;
    }
    
`;

export const Text = styled.text`
    display: flex;
    padding: 32px;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.01em;
    margin: -60px 10px -40px 80px;
    color: #1e272f;

    @media screen and (max-width: 480px) {
        font-size: 15px;
        margin: 0px 17px;
        padding: 10px 25px;
    }
    
`;

export const email = "comercial@ibsaudio.com.br";

const Emailibs = <a style=
    {{
        color: "#1E272F",
        fontFamily: "Rubik",
        fontWeight: "700",
        textDecoration: "none",
        paddingLeft: "7px"
    }}
    href={`mailto:${email}?body=Vim%20pelo%20site`}>{email}</a>;

const PoliticaPrivacidade: React.FC = () => {
    const Data = '29 Nov 2023 09:36:12 (Horário Padrão de Brasília)';
    return (
        <>
            <PPrivacidade>
                <Session>
                    <div style={{ flex: 1, minWidth: "300px" }}>
                        <TitleSession>Política de Privacidade do Aplicativo IBS</TitleSession>
                        <At>Última atualização: {Data}</At>
                        <TextSession>
                            A IBS (nome fictício), doravante referida como "nós" ou "nosso", está comprometida em proteger a privacidade dos usuários de nosso aplicativo de controle de equipamentos de som. Esta Política de Privacidade descreve como coletamos, usamos e protegemos as informações fornecidas pelos usuários.
                        </TextSession>
                        <Sub>1. Informações Coletadas</Sub>
                        <Sub2>1.1 Informações de Conta:</Sub2>
                        <Text>
                            Coletamos e armazenamos informações fornecidas pelo usuário durante o processo de registro, incluindo nome, endereço de e-mail e senha.
                        </Text>
                        <Sub2>1.2 Informações de Equipamento:</Sub2>
                        <Text>
                            Ao utilizar o aplicativo, podemos coletar informações sobre os dispositivos de som na rede local, incluindo identificadores únicos e configurações personalizadas.
                        </Text>
                        <Sub>2. Uso das Informações</Sub>
                        <Sub2>2.1 Personalização:</Sub2>
                        <Text>
                            As informações coletadas são utilizadas para personalizar a experiência do usuário, permitindo o controle eficiente dos equipamentos de som.
                        </Text>
                        <Sub2>2.2 Comunicação:</Sub2>
                        <Text>
                            Podemos utilizar o endereço de e-mail fornecido para enviar informações sobre atualizações, novos recursos ou outras comunicações relevantes relacionadas ao aplicativo.
                        </Text>
                        <Sub>3. Compartilhamento de Informações</Sub>
                        <Sub2>3.1 Parceiros e Prestadores de Serviço:</Sub2>
                        <Text>
                            Podemos compartilhar informações com parceiros e prestadores de serviços necessários para fornecer e manter o aplicativo.
                        </Text>
                        <Sub2>3.2 Requisitos Legais:</Sub2>
                        <Text>
                            Reservamo-nos o direito de divulgar informações se exigido por lei ou se acreditarmos de boa-fé que tal divulgação é necessária para proteger nossos direitos legais.
                        </Text>
                        <Sub>4. Segurança</Sub>
                        <Sub2></Sub2>
                        <Text>
                            Implementamos medidas de segurança para proteger as informações contra acesso não autorizado, alteração, divulgação ou destruição não autorizada.
                        </Text>
                        <Sub>5. Exclusão de Conta</Sub>
                        <Sub2></Sub2>
                        <Text>
                            Os usuários podem solicitar a exclusão de suas contas a qualquer momento. Após a exclusão, retemos apenas as informações necessárias para cumprir obrigações legais.
                        </Text>
                        <Sub>6. Alterações na Política de Privacidade</Sub>
                        <Sub2></Sub2>
                        <Text>
                            Reservamo-nos o direito de atualizar esta Política de Privacidade conforme necessário. Recomendamos revisar periodicamente para estar ciente de quaisquer alterações.
                        </Text>
                        <Sub>7. Contato</Sub>
                        <Sub2></Sub2>
                        <Text>
                            Para dúvidas ou preocupações relacionadas à privacidade, entre em contato conosco pelo e-mail {Emailibs}.
                        </Text>
                        <TextSession>
                            Ao utilizar o aplicativo IBS, você concorda com os termos desta Política de Privacidade.
                        </TextSession>
                    </div>
                </Session>
            </PPrivacidade>
        </>
    );
};

export default PoliticaPrivacidade;
