import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import Arrow from "../../../assets/Arrow.svg";
import Stroke from "../../../assets/Stroke.png";
import { Link, useNavigate } from "react-router-dom";
import Carousel, { useSContext } from "../../../components/carousel";
import AddKeypad from "./addKeypad";
import { useSliderContext } from "../../../contexts/currentSlide";

const Page = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 480px) {
  }
`;

const Text = styled.input`
  font-family: "Rubik";
  font-weight: 400;
  font-size: 16px;
  line-height: 0%;
  border: 0;
  text-align: center;
  outline: 0 none;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* [type=number] {
        -moz-appearance: textfield;
    } */

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;

const TextKeypad = styled.h2`
  font-family: "Rubik";
  font-weight: 400;
  font-size: 10px;
  line-height: 100%;
  color: #202e5944;
  text-align: center;

  @media screen and (max-width: 480px) {
    font-size: 8px;
    width: 50px;
  }
`;

const Btn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 223px;
  height: 54px;
  background: #202e59;
  font-family: "Rubik";
  font-size: 16px;
  font-weight: 400;
  border-radius: 7px;
  border: 0;
  cursor: pointer;
  color: #fff;

  @media screen and (max-width: 480px) {
    min-width: 100px;
    max-width: 150px;
    min-height: 35px;
    max-height: 60px;
    font-size: 12px;
  }

  @media screen and (min-width: 481px) and (max-width: 890px) {
    min-width: 100px;
    max-width: 250px;
    min-height: 40px;
    max-height: 60px;
    font-size: 14px;
  }
`;

export const DivBtnAddKp = styled.div<{ addKeypad: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 6% 0;

  @media screen and (max-width: 480px) {
    margin: 21% 0;
  }

  @media screen and (min-width: 481px) and (max-width: 890px) {
    /* margin: 15% 0; */
  }

  ${({ addKeypad }) => {
    if (addKeypad === true) {
      return css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        margin-left: 850px;
        margin-top: 82px;

        @media screen and (max-width: 480px) {
          margin-left: 340px;
          margin-top: 230px;
        }

        @media screen and (min-width: 481px) and (max-width: 890px) {
          margin-left: 500px;
          margin-top: 280px;
        }
      `;
    }
  }}
`;

const Btn2 = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85px;
  height: 85px;
  background: #bfd7ec;
  border-radius: 50px;
  border: 0;
  margin: 6% 0 10%;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    width: 50px;
    height: 50px;
    margin: 0;
  }

  @media screen and (min-width: 481px) and (max-width: 890px) {
    width: 70px;
    height: 70px;
    margin: 0;
  }
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* position: absolute; */
  width: 100%;

  @media screen and (max-width: 480px) {
  }
`;

export const DivTexto = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 15%;
  margin-top: 3%;

  @media screen and (max-width: 480px) {
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
  padding-bottom: 100px;
  width: 90%;

  @media screen and (max-width: 480px) {
    padding-bottom: 50px;
  }
`;

const ButtonSalvar = styled.button`
  width: 335px;
  height: 69px;
  background: #eaedf6;
  color: #4b5259;
  border-radius: 4px;
  position: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Rubik";
  font-size: 16px;
  border: 0;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    min-width: 100px;
    max-width: 150px;
    min-height: 35px;
    max-height: 60px;
    font-size: 12px;
  }
`;

export const NavIten = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 20px;
  z-index: 3;

  font-family: "Rubik";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  line-height: 145%;
  text-decoration: none;
  color: #fff;

  @media screen and (max-width: 480px) {
    min-width: 15px;
    font-size: 14px;
  }
`;

export const TextBtn = styled.h3`
  font-family: "Rubik";
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  color: #1c70bb;

  @media screen and (max-width: 480px) {
    font-size: 12px;
    flex-wrap: wrap;
  }
`;

export const PlusHor = styled.div`
  background: #1c70bb;
  width: 25px;
  height: 3px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 480px) {
    width: 20px;
  }
`;

export const PlusVer = styled.div`
  background: #1c70bb;
  width: 3px;
  height: 25px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 480px) {
    height: 20px;
  }
`;

export const Menos = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <PlusHor />
    </div>
  );
};

export const Plus = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <PlusHor>
        <PlusVer />
      </PlusHor>
    </div>
  );
};

interface Props {
}

type keypadData = {
  name?: string;
  quant?: number;
};

const CriarPulsadorPage: React.FC<Props> = ({}) => {
  const navigate = useNavigate();
  const [keypad, setKeypad] = React.useState<Array<keypadData>>([]);

  const {
    slider: currentSlide,
    setSlider: setCurrentSlide,
    keypads,
    updateDateKeypad,
    addKeypad,
  } = useSliderContext();

  const cenaNome = useMemo(
    () => keypads[currentSlide]?.cenaNome || "",
    [currentSlide, keypads]
  );
  const addKeypadFirst = keypads.length > 0;

  const quantidade = useMemo(
    () => keypads[currentSlide]?.quantidade || 0,
    [currentSlide, keypads]
  );

  const quantBtn = useMemo(
    () => keypads[currentSlide]?.quantBtn || 0,
    [currentSlide, keypads]
  );

  const tipo = useMemo(
    () => keypads[currentSlide]?.tipo || "",
    [currentSlide, keypads]
  );

  console.log("keypads: ", keypads);

  const handlesAddKeyp = useCallback(() => {
    addKeypad();
  }, [addKeypad]);

  const AumentarQuantidade = () => {
    updateQuantidade(quantidade + 1);
  };

  const DiminuirQuantidade = () => {
    if (quantidade > 0) {
      updateQuantidade(quantidade - 1);
    }
  };

  const updateCenaName = useCallback(
    (cenaNome: string) => {
      updateDateKeypad(currentSlide, {
        cenaNome,
      });
    },
    [currentSlide, updateDateKeypad]
  );

  const updateQuantidade = useCallback(
    (quantidade: number) => {
      updateDateKeypad(currentSlide, {
        quantidade,
      });
    },
    [currentSlide, updateDateKeypad]
  );

  const updateTipo = useCallback(
    (tipo: string) => {
      updateDateKeypad(currentSlide, {
        tipo,
      });
    },
    [currentSlide, updateDateKeypad]
  );

  
  const updateQuantBtn = useCallback(
    (quantBtn: number) => {
      updateDateKeypad(currentSlide, {
        quantBtn,
      });
    },
    [currentSlide, updateDateKeypad]
  );

  const AumentarQuantBtn = () => {
    if (quantBtn < 6 && tipo === "Keypad") {
      updateQuantBtn(quantBtn + 1);
    }
    if (quantBtn === 4 && tipo === "Keypad") {
      updateQuantBtn(quantBtn + 2);
    }
    if (quantBtn === 6 && tipo === "Keypad") {
      updateTipo("Keypad 4x4")
      updateQuantBtn(8);
      // tipo === "keypad" ? updateTipo("keypad 4x4") : console.log("quantidade maxima de botões atingida")
    }
    if (quantBtn < 12 && tipo === "Keypad 4x4") {
      updateQuantBtn(quantBtn + 2);
    }
    if (quantBtn === 8 && tipo === "Keypad 4x4") {
      updateQuantBtn(quantBtn + 4);
    }
  };

  const DiminuirQuantBtn = () => {
    if (quantBtn > 1 && tipo === "Keypad") {
      updateQuantBtn(quantBtn - 1);
    }
    if (quantBtn === 6 && tipo === "Keypad") {
      updateQuantBtn(quantBtn - 2);
    }
    if (quantBtn === 2 && tipo === "Keypad 4x4") {
      updateTipo("Keypad");
      updateQuantBtn(1);
      // tipo === "keypad" ? updateTipo("keypad 4x4") : console.log("quantidade maxima de botões atingida")
    }
    if (quantBtn > 2 && tipo === "Keypad 4x4") {
      updateQuantBtn(quantBtn - 2)
    }
    if (quantBtn === 12 && tipo === "Keypad 4x4") {
      updateQuantBtn(quantBtn - 4)
    }
  };

  return (
    <>
      <Page>
        <Div>
          <DivTexto>
            <img
              src={Stroke}
              alt=""
              onClick={() => {
                setCurrentSlide(
                  (currentSlide + 1 + keypads.length) % keypads.length
                );
              }}
            />
            <Text
              placeholder="Nomeie uma cena"
              type="text"
              value={cenaNome}
              onChange={(event) => {addKeypadFirst === true ?
                updateCenaName(event.currentTarget.value) : console.log("adicione um keypad");
              }}
            />
            <img
              style={{ transform: "rotate(180deg)" }}
              src={Stroke}
              alt=""
              onClick={() => {
                setCurrentSlide(
                  (currentSlide > 0
                    ? currentSlide - 1
                    : currentSlide + keypads.length - 1) % keypads.length
                );
              }}
            />
          </DivTexto>
          <Btn
            style={
              addKeypadFirst === false
                ? { margin: "3% 0 6%" }
                : { margin: "3% 0 3%" }
            }
            onClick={AumentarQuantBtn}
          >
            Adicionar Botão
          </Btn>
          <div
            style={
              addKeypadFirst === true
                ? {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%",
                  }
                : { display: "none" }
            }
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Carousel
                cena={cenaNome}
              >
                {keypads.map(({ cenaNome, quantidade, id }, index) => {
                  return (
                    <>
                      <input
                        hidden
                        key={`key-${index}`}
                        type="text"
                        value={(cenaNome || "").concat(String(currentSlide))}
                        defaultValue={cenaNome}
                        onChange={(e) => {
                          const value = e.target.value;
                          setKeypad((old) => {
                            let newKeyp = [...old];
                            newKeyp[index].name = value;
                            return newKeyp;
                          });
                        }}
                      />
                      <AddKeypad />
                    </>
                  );
                })}
              </Carousel>
            </div>
          </div>
          <DivBtnAddKp addKeypad={addKeypadFirst}>
            <Btn2 onClick={handlesAddKeyp}>
              <Plus />
            </Btn2>
            <TextKeypad>Adicionar um novo keypad</TextKeypad>
          </DivBtnAddKp>
          <Btn
            style={
              addKeypadFirst === false
                ? { margin: "6% 0 0" }
                : { margin: "3% 0 0" }
            }
            onClick={DiminuirQuantBtn}
          >
            Reduzir Botão
          </Btn>
          <DivTexto
            style={addKeypadFirst ? { margin: "3% 0 0" } : { display: "none" }}
          >
            <img
              style={{ transform: "rotate(90deg)", cursor: "pointer" }}
              src={Stroke}
              alt=""
              onClick={AumentarQuantidade}
            />
            <Text
              type="number"
              value={addKeypadFirst ? quantidade : "Quantidade"}
              placeholder="Quantidade"
              onChange={(event) => {
                updateQuantidade(event.currentTarget.valueAsNumber);
              }}
            />
            <img
              style={
                quantidade > 1
                  ? { transform: "rotate(270deg)", cursor: "pointer" }
                  : { display: "none" }
              }
              src={Stroke}
              alt=""
              onClick={DiminuirQuantidade}
            />
          </DivTexto>
        </Div>
        <Container>
          <NavIten
            to="/makeYourPulsador"
            style={{ display: "flex", textDecoration: "none" }}
          >
            <img
              style={{ transform: "matrix(-1, 0, 0, -1, 0, 0)" }}
              src={Arrow}
              alt="Arrow"
            />
            <TextBtn>Voltar para a página inicial</TextBtn>
          </NavIten>
          <ButtonSalvar onClick={() => navigate("/pulsadoresFinalizados")}>
            Salvar e continuar
          </ButtonSalvar>
        </Container>
      </Page>
    </>
  );
};

export default CriarPulsadorPage;
