import React, { FC, useCallback, useEffect, useState } from "react";
import createContext from "../../utils/createContex";
import { collection, doc, getDoc, getDocs, getFirestore, setDoc } from "firebase/firestore";

const db = getFirestore();

type keypadData = {
  id?: number;
  cenaNome: string;
  tipo: string;
  colorEspelho: string;
  colorBtn: string;
  quantBtn: number;
  textBtn: Record<string, string>;
  quantidade: number;
};

interface SliderContext {
  load: boolean;
  keypads: keypadData[];
  slider: number;
  setSlider: React.Dispatch<React.SetStateAction<number>>;
  addKeypad: () => void;
  removeKeypad: (id: number) => void;
  updateDateKeypad: (id: number, data: Partial<keypadData>) => void;
}

const [useContex, Provider] = createContext<SliderContext>();

interface SlideProviderProps {
  children: React.ReactNode;
}

export const setCadastroKp = async (keypad: keypadData) => {
  console.log("setCadastroKp");
  const ref = doc(collection(db, 'keypads'));
  await setDoc(ref, keypad);
}

const SlideProvider: FC<SlideProviderProps> = ({ children }) => {
  const [load, setload] = useState(true);
  const [slider, setSlider] = useState<number>(0);
  const [keypads, setKeypads] = useState<keypadData[]>([]);
  
  // const sendToFormtoDB = useCallback(async () => {
  //   await setCadastroKp(keypads);
  // }, [keypads]);

  const addKeypad = useCallback(() => {
    setKeypads((old) => [
      ...old,
      {
        id: old.length,
        cenaNome: "",
        tipo: "Keypad",
        colorEspelho: "#F8FCFF",
        colorBtn: "#F8FCFF",
        quantBtn: 3,
        textBtn: { 0: "", 1: "", 2: "" },
        quantidade: 1,
      },
    ]);
  }, []);

  const removeKeypad = useCallback((id: number) => {
    setKeypads((old) =>
      old.filter((e) => e.id !== id).map((e, i) => ({ ...e, id: i }))
    );
  }, []);

  const updateDateKeypad = useCallback(
    (id: number, data: Partial<keypadData>) => {
      console.log({
        id,
        data,
      });
      setKeypads((old) => {
        const newOld = [...old];
        Object.assign(newOld[id], data, { id });
        const copy = Object.assign({}, newOld[id]);
        if (copy.quantBtn) {
          copy.textBtn = {};
          for (let index = 0; index < copy.quantBtn; index++) {
            copy.textBtn[String(index)] = newOld[id].textBtn[index];
          }
          newOld[id] = copy;
        }
        return newOld;
      });
    },
    []
  );

  // const removeDateKeypad = useCallback(
  //   (id: number, data: Partial<keypadData>) => {
  //     console.log({
  //       id,
  //       data,
  //     });
  //     setKeypads((old) =>
  //       old.filter((e) => e.id !== id).map((e, i) => ({ ...e, id: i }))
  //       // Object.assign
  //     );
  //     // setKeypads((old) => {
  //     //   old.filter((e) => e.id !== id).map((e, i) => ({ ...e, id: i }))
  //     // })
  //   }, []);

  return (
    <Provider
      value={{
        load,
        slider,
        setSlider,
        keypads,
        addKeypad,
        removeKeypad,
        updateDateKeypad,
      }}
    >
      {children}
    </Provider>
  );
};

export default SlideProvider;

export { useContex as useSliderContext };
