import React from "react";
import { ContentFooter, RedesSociais, NavIten, Contato, Pre, Pree, Session, A, Tit, Sobre, SobreDiv, Line } from "./styled";
import Facebook from "../../assets/Facebook.png";
import Instagram from "../../assets/Instagram.png";
import LinkedIn from "../../assets/LinkedIn.png";
import { idText } from "typescript";
import { Link } from "react-router-dom";

export const email = "comercial@xcene.com.br";
export const number = "+5511958746055";

const Numero = (
    <A href={`https://wa.me/${number}`} rel="noreferrer" target="_blank">
        +55 (11) 95874-6055
    </A>
);

const EmailX = (
    <A id="emailX" style={{ color: "#1C70BB" }} href={`mailto:${email}?body=Vim%20pelo%20site`}>
        {email}
    </A>
);

// const Endereco = (
//     <A id="enderecoX" href={`mailto:${email}?body=Vim%20pelo%20site`}>
//         {email}
//     </A>
// );

const Footer: React.FC = () => {
    const handleClick = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return (
        <ContentFooter>
            <SobreDiv>
                <Tit>Sobre nós</Tit>
                <Sobre>
                    Somos uma empresa de automação residencial. Atualmente nos destacamos no mercado com nossos diversos modelos de Keypads.
                </Sobre>
            </SobreDiv>

            <Session>
                {/* <Pre>
                    <Tit>Sobre nós</Tit>
                    <SobreDiv>
                        <Sobre>
                            Somos uma empresa de automação residencial. Atualmente nos destacamos no mercado com nossos diversos modelos de Keypads.
                        </Sobre>
                    </SobreDiv>
                </Pre> */}
                <Pre>
                    {/* <NavIten onClick={handleClick} to="/sobre">
                        Sobre
                    </NavIten> */}
                    <NavIten onClick={handleClick} to="/products">
                        Produtos
                    </NavIten>
                    <NavIten onClick={handleClick} to="/register">
                        Revenda
                    </NavIten>
                    {/* <NavIten onClick={handleClick} to="/assistencia">
                        Assistência
                    </NavIten> */}
                    <NavIten onClick={handleClick} to="/contato">
                        Contato
                    </NavIten>
                    {/* <NavIten onClick={handleClick} to="/makeYourPulsador">
                        Faça seu Pulsador
                    </NavIten> */}
                    {/* <NavIten onClick={handleClick} to="/product/new">
                        edt
                    </NavIten> */}
                </Pre>

                <Line/>

                <Pree>
                    <Tit>Contato</Tit>
                    <Contato>Whatsapp: {Numero}</Contato>
                    <Contato>Email: {EmailX}</Contato>
                    <Contato>Endereço: Rua Dr. Maurício De Lacerda, 522</Contato>

                    <RedesSociais>
                        <a href="https://www.facebook.com/Xcenecontrole/">
                            <img src={Facebook} alt="Facebook" />
                        </a>
                        <a href="https://www.instagram.com/oficialxcenebr/">
                            <img style={{ padding: "1vw" }} src={Instagram} alt="Instagram" />
                        </a>
                    </RedesSociais>
                </Pree>
            </Session>
        </ContentFooter>
    );
};

export default Footer;
