import React, { useEffect } from "react";
import styled from "styled-components";

import plus from "../../../assets/plus.png";
import UploadArq from "../../addProduto/addArquivo/arquivoUpload";
const Input = styled.input`
  background: #f2f7fd;
  width: 13vw;
  height: 2.15vw;
  border-radius: 10px;
  padding: 0 1.5vw;
  border: 0;
  outline: 0 none;
  font-family: "Rubik";
  font-size: 14px;

  @media screen and (max-width: 480px) {
    font-size: 11px;
    height: 25px;
    width: 80px;
    border-radius: 12px;
  }
`;

const Input1 = styled.input`
  background: #f2f7fd;
  width: 7.7vw;
  height: 2.15vw;
  border-radius: 21px;
  padding: 0 1.5vw;
  border: 0;
  outline: 0 none;
  font-family: "Rubik";
  font-size: 14px;

  @media screen and (max-width: 480px) {
    font-size: 11px;
    /* width: ; */
    height: 25px;
    width: 40px;
    border-radius: 12px;
  }
`;

const Add = styled.button`
  border: 0 none;
  border-radius: 4vw;
  background: transparent;
`;

const Plus = styled.img`
  width: 3vw;
  height: 3vw;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    width: 20px;
    height: 20px;
  }
`;

interface FileComponenteProps {
  onChange?: (Datasheets: Array<[string, string]>) => void;
  file: Record<string, string>;
}

const FileComponent: React.FC<FileComponenteProps> = ({ onChange, file }) => {
  // const [vars, setVars] = React.useState<Array<[string, string]>>([]);
  const [arqs, setArqs] = React.useState<Array<[string, string]>>([]);

  useEffect(() => {
    setArqs(Object.entries(file));
  }, [file]);

  const handleArqUpdate = (numberImg: number) => (ImageUrl: string) => {
    setArqs((old) => {
      const newarry = [...old];
      newarry[numberImg][1] = ImageUrl;
      onChange?.(newarry);
      return newarry;
    });
  };

  const handlesAddArq = () => {
    setArqs((old) => [...old, ["", ""]]);
  };

  // useEffect(() => {
  //     setArqs( Object.keys(especificacoes).map((key) => {
  //         return [key, especificacoes[key]]
  //     }))
  // }, [especificacoes])

  // useEffect(() => {
  //     let esp: Record<string, string> = {}
  //     vars.forEach((e) => {
  //         esp[e[0]] = e[1];
  //     })
  //     onChangeEsp?.(esp);
  // }, [vars]);

  return (
    <>
      <div style={{ display: "inline-table", gap: "1vw" }}>
        {arqs.map(([key, value], index) => {
          return (
            <>
              <div style={{ display: "flex", gap: "1vw", margin: "1vw 0" }}>
                {/* <Input1
                                    key={`key-${index}`}
                                    type="text"
                                    name="esp"
                                    defaultValue={key}
                                    onChange={(e) => {
                                        setArqs(old => {
                                            let newArq = old;
                                            newArq[index][0] = e.target.value;
                                            return [...newArq];
                                        })
                                    }}
                                >
                                </Input1> */}
                <Input
                  key={`key-${index}`}
                  type="text"
                  value={key}
                  defaultValue={key}
                  onChange={(e) => {
                    setArqs((old) => {
                      let newArq = old;
                      newArq[index][0] = e.target.value;
                      return [...newArq];
                    });
                  }}
                />
                <UploadArq
                  onArqUpload={handleArqUpdate(index)}
                  name=""
                  arqSRC={String(value)}
                />
              </div>
            </>
          );
        })}
        <Add type="button" onClick={handlesAddArq}>
          <Plus src={plus} alt="" />
        </Add>
      </div>
    </>
  );
};

export default FileComponent;
