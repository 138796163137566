import React, { FC, useEffect, useState } from "react";
import createContext from "../../utils/createContex";
import { IdTokenResult, User, getAuth } from "firebase/auth";

interface UserContex {
    load: boolean;
    user: User | null;
    tokenId: IdTokenResult | null;
    isAdmin: boolean;
}

const [useContex, Provider] = createContext<UserContex>();

interface UserProviderProps {
    children: React.ReactNode;
}

const UserProvider: FC<UserProviderProps> = ({ children }) => {
    const [load, setload] = useState(true);
    const [user, setUser] = useState<User | null>(null);
    const [tokenId, setTokenId] = useState<IdTokenResult | null>(null);
    const [isAdmin, setAdmin] = useState(false);

    useEffect(() => {
        setAdmin(tokenId?.claims.admin === true);
    }, [tokenId?.claims.admin]);

    useEffect(() => {
        if (!!tokenId && !!user) {
            setload(false);
        }
    }, [tokenId, user]);

    useEffect(() => {
        user?.getIdTokenResult().then(setTokenId);
    }, [user]);

    useEffect(() => {
        getAuth().onIdTokenChanged(setUser);
    }, []);

    return <Provider value={{ user, isAdmin, tokenId, load }}>{children}</Provider>;
};

export default UserProvider;

export { useContex as useUserContex };
