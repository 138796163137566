import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import DropDown, { variacoesType } from "./dropdown";
import Drop from '../../../assets/Drop.svg';
import Products, { getColors } from "../../../controllers/getProducts";
// import { getVar } from "../../../controllers/getProducts";
// import { bool } from "yup";

export const Btn = styled.button`
   display: flex;
   align-items: center;
   justify-content: space-between;
   position: absolute;
   width: 10vw;
   height: 3.15vw;
   padding: 0 1.3vw;
   color: #333;
   background-color: #F2F7FD;
   font-family: 'Rubik';
   font-weight: 400;
   font-size: 16px;
   line-height: 160%;
   text-align: left;
   white-space: nowrap;
   vertical-align: middle;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   border: 0;
   border-radius: 4px;
   cursor: pointer;

    :hover {
    background-color: #F2F7FD;
    }
    .active {
      background-color: #F2F7FD;
    }

    @media screen and (max-width: 480px) {
      font-size: 11px;
      height: 30px;
      width: 60px;
    }
`;

const Div2 = styled.div`
  width: 10vw;
  height: 3.15vw;

  @media screen and (max-width: 480px) {
    height: 30px;
    width: 60px;
  }
`;

const Input = styled.input`
  background: transparent; 
  font-family: 'Rubik'; 
  font-weight: 400; 
  font-size: 16px; 
  border: 0 none; 
  outline: 0; 
  width: 6vw;

  @media screen and (max-width: 480px) {
    font-size: 12px;
    /* width: 60px; */
  }
`;

const Div = styled.h1`
  /* background: {color};  */ 
  /* width: 1.5vw; 
  height: 1.5vw; 
  border-radius: 2vw; */
  font-size: 16px;
  font-weight: 400;
  font-family: Rubik;
  color: #000;
  line-height: 0%;

  @media screen and (max-width: 480px) {
    /* width: 14px; 
    height: 14px; 
    border-radius: 10px; */
  }
`;

const DropD = <img src={Drop} style={{ width: "1vw" }} alt=" " />;

interface DropdownVarProps {
  listColors: Array<string>;
  onChangeVariacao?: (variacao: string) => void;
  onChangeNewVariacao?: (variacao: string) => void;
  onChangeColor?: (variacao: string) => void;
};

const DropdownVar: React.FC<DropdownVarProps> = ({ listColors, onChangeVariacao, onChangeNewVariacao }): JSX.Element => {
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const [selectVariacao, setSelectVariacao] =  useState<variacoesType>();

  // const [newImg, setNewImg] = useState(''); 

  const [newVar, setNewVar] = useState('');

  const [variacoes, setVariacoes] = useState<variacoesType[]>([]);
  
  const VariacaoSelection = (variacao: variacoesType): void => {
    setSelectVariacao(variacao);
    onChangeVariacao?.(variacao.label);
  };
  
  const AddNew = 
  useMemo(() =>
    <Input type="text" key={"AddNewVar"} value={newVar} placeholder="Nova Cor" onChange={e => {
      setNewVar(e.target.value);
      onChangeNewVariacao?.(e.target.value);
    }} />
    , [newVar, onChangeNewVariacao]
  )
  
  useEffect(() => {
    console.log("listColors: ", listColors)
    const arratVar: variacoesType[] = listColors?.map(color => ({
      label: color,
      element: <Div>{color}</Div>
    })) || [];
    setVariacoes(arratVar);
    // getColors().then((cor) => {
    //   const arratVar: variacoesType[] = cor?.map(color => ({
    //     label: color,
    //     element: <Div>{color}</Div>
    //   }));
    //   setVariacoes(arratVar);
    // })
  }, [listColors]);
  
  useEffect(() => {
    let color: string = ""
    variacoes.forEach((e) => {
        color = "e";
    })
    onChangeVariacao?.(color);
  }, [variacoes, onChangeVariacao]);
  
//   const arrayAddLement = useMemo(() => [...variacoes, {
//     label: 'Nova Cor',
//     element: selectVariacao?.label ? AddNew : "Nova Cor",
//     // selectVariacao?.label ? AddNew : 'Nova Cor',
//   }
// ], [AddNew, variacoes, selectVariacao])
  


//   const listColors = useMemo(() => {
//     const colors: Array<string> = [];
//     variacaoList?.forEach(v => {
//         colors.push(v.color);
//     })
//     return colors;
// }, [variacaoList]);

  // const AddNew = useMemo(() => {
    // const AddNew =  <Input value={newVar} onChange={e => {
    //   setNewVar(e.target.value);
    //   onChangeNewVariacao?.(e.target.value);
    // }} placeholder="nova cor" type="text" />;
    // return AddNew;
  // }, [setNewVar, onChangeNewVariacao, newVar])

  // const ChangeImage = <input value={newImg} onChange={e => {
  //   setNewImg(e.target.value);
  //   onChangeColor?.(e.target.value);
  // }} />
  // const variacoes: variacoesType[] = [
  //   // "#D1C361", "#8D6A60", "#545355", 
  //   {
  //     label: "#FFFFFF",
  //     element: <div style={{ background: "#FFFFFF", width: "1.5vw", height: "1.5vw", borderRadius: "2vw" }} />
  //   },
  //   {
  //     label: "#D1C361",
  //     element: <div style={{ background: "#D1C361", width: "1.5vw", height: "1.5vw", borderRadius: "2vw" }} />
  //   },
  //   {
  //     label: "#8D6A60",
  //     element: <div style={{ background: "#8D6A60", width: "1.5vw", height: "1.5vw", borderRadius: "2vw" }} />
  //   },
  //   {
  //     label: "#545355",
  //     element: <div style={{ background: "#545355", width: "1.5vw", height: "1.5vw", borderRadius: "2vw" }} />
  //   },
  //   // {
  //   //   label: `${}`,
  //   //   element: <div style={{ background: `${}`, width: "1.5vw", height: "1.5vw", borderRadius: "2vw" }}></div>
  //   // }

  //   // "+ adicionar nova cor"
  // ];

  /**
   * Toggle the drop down menu
   */
  const toggleDropDown = () => {
    setShowDropDown(!showDropDown);
  };

  /**
   * Hide the drop down menu if click occurs
   * outside of the drop-down element.
   *
   * @param event  The mouse event
   */
  const dismissHandler = (event: React.FocusEvent<HTMLButtonElement>): void => {
    if (event.currentTarget === event.target) {
      setShowDropDown(false);
    }
  };


  // const addColor = <button title="Nova cor"></button>
  // const addColor: variacoesType[] = listColors?.map(color => ({
  //   label: "Nova cor",
  //   element: <button title="Nova cor"></button>
  // })) || [];

  const arrayAddLement = useMemo(() => [...variacoes, {
    label: 'Nova Cor',
    element: selectVariacao?.label ? AddNew : "Nova Cor",
    // selectVariacao?.label ? AddNew : 'Nova Cor',
  }
], [AddNew, variacoes, selectVariacao])

  return (
    <>
      <Div2>
        <Btn
          type="button"
          className={showDropDown ? "active" : undefined}
          onClick={(): void => toggleDropDown()}
          onBlur={(e: React.FocusEvent<HTMLButtonElement>): void =>
            dismissHandler(e)
          }
        >
          <div style={{fontFamily: "Rubik"}}>
            {/* {selectVariacao && arrayAddLement.find(e => e.label == selectVariacao.label)?.element } </div> */}
            {/* {
              selectCategoria && arrayAddLement.find(e => e.label === selectCategoria.label)?.element
            } */}
            { 
              selectVariacao ? arrayAddLement.find(e => e.label === selectVariacao.label)?.element : "" //"variants.map(e => e.color)"
            } </div>
          {DropD}
          {showDropDown && (
            <DropDown
              variacoes={arrayAddLement}
              showDropDown={false}
              toggleDropDown={(): void => toggleDropDown()}
              variacaoSelection={VariacaoSelection}
            />
          )}
        </Btn>
      </Div2>
    </>
  );
};

export default DropdownVar;