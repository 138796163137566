import React, { useEffect, useMemo, useState } from "react";
import { ProductContainer, ProductSession, TitleSession, DivCategoria, Categoria, DivFlex, DivColumn, Img } from "./styles";
import Newsletter from "../sobre/Newsletter";
import styled from "styled-components";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import Button from "../../components/button";
import BarraPesquisa from "../../components/pesquisa/Search/barraPesquisa";
import Products, { getCategorys } from "../../controllers/getProducts";
import Destaques from "../../components/destaques";
import Keypad from "../../assets/3BFlatBranco-remove.png";
import Modulo from "../../assets/FrenteRelee.png";
import KeypadWifi from "../../assets/keypadWifi.png";
import { Filters } from "../../components/pesquisa/Filters/Filters";
import { categoriasType } from "../addProduto/dropdownCat/Dropdown";
// import ProductPage from "../../components/pesquisa/Filters/ProductPage";

// const BtnCategory = styled.button`
//     display: flex;
//     flex-direction: row;
//     margin-top: 5%;
//     justify-content: center;
//     align-items: center;
//     width: 35.4vw;
//     height: 6.9vw;

//     background: #EAEDF6;
//     border-radius: 4px;
//     border: 0;
//     cursor: pointer;

//     /* span{
//         font-weight: 500;
//         font-size: 24px;
//         color: #1E272F;
//     } */
// `;

// const CategoriaBtn = styled.h2`
//     font-family: 'Rubik';
//     font-weight: 500;
//     font-size: 24px;
//     color: #1E272F;
// `;

const Text = styled.h3`
  font-family: 'Rubik';
  font-weight: 400;
  font-size: 16px;
  line-height: 0%;

  @media screen and (max-width: 480px) {
    font-size: 12px;
    flex-wrap: wrap;
  }
`;

const ProductsPage: React.FC = ({}) => {
    const productsList = useLoaderData() as Products[];
    // const [filterCategory, setFilterCategory] = useState<string>("");
    const [categorias, setCategorias] = useState<categoriasType[]>();
    const navigate = useNavigate();

    useEffect(() => {
        getCategorys().then((cat) => {
          const arratCat: categoriasType[] = cat.map(e => ({
            label: e,
            element: <Text>{e}</Text>
          }));
          setCategorias(arratCat);
        });
      }, []);

    // const categorys = useMemo(() => {
    //     const cat: Record<string, Array<Object>> = {};

    //     productsList.forEach(({ category, ...rest }) => {
    //         const ct = category || "Outros";
    //         if (cat[ct] === undefined) {
    //             cat[ct] = [];
    //         }
    //         cat[ct].push(rest);
    //     });
    //     return cat;
    // }, [productsList]);
    // const { category } = productsList;

    // const resultProducts = productsList.filter((product) => filterCategory === "" || (product.category || "Outros") === filterCategory);


    // console.log("resultProducts: ", resultProducts.map(e => e.category))
    // console.log("productsList: ", productsList.map(e => e.category))
    // console.log("categorys: ", categorys)
    // console.log("categorias: ", categorias)

    return (
        <>
            <ProductContainer>
                {/* <BarraPesquisa productsList={productsList} categorys={Object.keys(categorys)} /> */}
                {/* <div style={{ display: "flex", maxWidth: "1700px", overflow: "hidden", flexWrap: "wrap", justifyContent: "space-around" }}>
                    <Filters<Products>
                        onChangeFilter={(e) => {
                            setFilterCategory(e.value);
                        }}
                        list={Object.keys(categorys).map((e) => ({
                            label: e,
                            value: e,
                        }))}
                    />
                </div> */}
                <DivCategoria>
                    <TitleSession>Categorias</TitleSession>
                    <DivFlex>
                        {/* {categorias?.map(e => 
                            (<DivColumn onClick={() => {navigate(e.label)}}>
                                <Img src={Keypad} alt="" />
                                <Categoria>{e.label}</Categoria>
                            </DivColumn>)
                        )} */}
                        {/* <DivColumn onClick={() => {navigate("Acessórios")}}>
                            <Img src={Keypad} alt="" />
                            <Categoria>Acessórios</Categoria>
                        </DivColumn> */}
                        <DivColumn onClick={() => {navigate("Keypads")}}>
                            <Img src={KeypadWifi} alt="" />
                            <Categoria>Keypads</Categoria>
                        </DivColumn>
                        <DivColumn onClick={() => {navigate("Modulos")}}>
                            <Img src={Modulo} alt="" />
                            <Categoria>Módulos</Categoria>
                        </DivColumn>
                        <DivColumn onClick={() => {navigate("Pulsadores")}}>
                            <Img src={Keypad} alt="" />
                            <Categoria>Pulsadores</Categoria>
                        </DivColumn>
                    </DivFlex>
                </DivCategoria>
                {/* <Destaques/> */}
                {/* <TitleSession>Destaques</TitleSession> */}
                {/* <BarraPesquisa productsList={productsList} categorys={Object.keys(categorys)} /> */}
            </ProductContainer>
            <div style={{ marginTop: "10%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Newsletter />
            </div>
        </>
    );
};

export default ProductsPage;
