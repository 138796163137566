import React, { useCallback, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import VectorPulsador from "../../../../assets/VectorPulsador.png";
import { Menos, Plus } from "..";
import { useSliderContext } from "../../../../contexts/currentSlide";
import Buttons from "./quantButtons";

const PulsadorF = styled.div<{ bg?: string; tipo?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ bg }) => (bg ? bg : "#F8FCFF")};
  width: 321px;
  height: 478px;
  border-radius: 21px;
  box-shadow: #25313d44 1px 3px 3px 1px, #25313d16 0px 7px 5px 1px;

  @media screen and (max-width: 480px) {
    width: 177px;
    height: 262px;
    border-radius: 11.5px;
  }

  /* @media screen and (min-width: 481px) and (max-width: 890px) {
    width: 385.7px;
    height: 334.6px;
  } */

  ${({ tipo }) => {
    if (tipo === "Keypad 4x4") {
      return css`
        width: 551px;
        height: 478px;
        gap: 15%;

        @media screen and (max-width: 480px) {
          width: 280px;
          height: 260px;
          gap: 15%;
        }

        @media screen and (min-width: 481px) and (max-width: 890px) {
          width: 385.7px;
          height: 334.6px;
        }
      `;
    }
  }}
`;

const PulsadorBtns = styled.div<{ bg?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ bg }) => (bg ? bg : "#FFFFFF")};
  width: 131.25px;
  height: 268.08px;
  border-radius: 6px;
  box-shadow: #25313d44 4px 3px 7px 3px, #25313d16 -4px 5px 5px 5px;
  border: 5px solid #00000060;

  @media screen and (max-width: 480px) {
    width: 72.6px;
    height: 148.5px;
    border-radius: 3.3px;
    border: 2.75px solid #00000060;
  }

  @media screen and (min-width: 481px) and (max-width: 890px) {
    width: 91.9px;
    height: 187.7px;
    border-radius: 4.2px;
    border: 3.5px solid #00000060;
  }
`;

const BtnFalse = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  width: 91.33px;
  height: 31.7px;
  /* border-radius: 8px; */
  border: 1px solid #00000090;
  
  @media screen and (max-width: 480px) {
    width: 50.5px;
    height: 13.75px;
    /* border-radius: 6.6px; */
    border: 2px solid #00000090;
  }
`;

const BtnP = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  width: 91.33px;
  height: 31.7px;
  border-radius: 8px;
  border: 4px solid #00000090;
  
  @media screen and (max-width: 480px) {
    width: 50.5px;
    height: 13.75px;
    border-radius: 6.6px;
    border: 2px solid #00000090;
  }
`;

const BtnM = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  width: 91.33px;
  height: 63.4px;
  border-radius: 8px;
  border: 4px solid #00000090;
  
  @media screen and (max-width: 480px) {
    width: 50.5px;
    height: 13.75px;
    border-radius: 6.6px;
    border: 2px solid #00000090;
  }
  `;

  const BtnG = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    width: 91.33px;
    height: 95.1px;
    border-radius: 12px;
    border: 4px solid #00000090;
  
    @media screen and (max-width: 480px) {
      width: 50.5px;
      height: 52.8px;
      border-radius: 6.6px;
      border: 2.2px solid #00000090;
    }
  `;

const TextPulsadorBtn = styled.input<{ cor?: string }>`
  font-family: "Rubik";
  font-weight: 400;
  font-size: 10px;
  line-height: 0%;
  border: 0;
  text-align: center;
  outline: 0 none;
  width: 90%;
  height: 90%;
  cursor: pointer;
  background: transparent;

  ::placeholder {
    color: ${({ cor }) => (cor ? cor : "")};
  }

  @media screen and (max-width: 480px) {
    font-size: 6px;
    flex-wrap: wrap;
  }
`;

const BtnAdd = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 70px;
  height: 140px;
  background: #bfd7ec;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  border: 0;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    width: 46.7px;
    height: 93.3px;
  }

  @media screen and (min-width: 481px) and (max-width: 890px) {
    width: 49px;
    height: 110px;
  }
`;

const BtnMenos = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 70px;
  height: 140px;
  background: #bfd7ec;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  border: 0;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    width: 46.7px;
    height: 93.3px;
  }

  @media screen and (min-width: 481px) and (max-width: 890px) {
    width: 49px;
    height: 110px;
  }
`;

export const TextBtn = styled.h3`
  font-family: "Rubik";
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  color: #1c70bb;

  @media screen and (max-width: 480px) {
    font-size: 14px;
    flex-wrap: wrap;
  }
`;

export const DivColor = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 236px;
  height: 221px;
  border-radius: 18px;
  background: #fff;
  box-shadow: #839bb114 1px 1px 5px 1px, #839bb114 -1px 1px 3px 1px;

  @media screen and (max-width: 480px) {
    width: 157.3px;
    height: 147.3px;
    border-radius: 12px;
  }

  @media screen and (min-width: 481px) and (max-width: 890px) {
    width: 165.2px;
    height: 154.7px;
    border-radius: 12.6px;
  }
`;

export const ImgVPulsador = styled.img`
  margin-bottom: 3%;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    margin-bottom: 2%;
    width: 13px;
  }
`;

export const ColorText = styled.h3`
  font-family: "Rubik";
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #1c70bb;

  @media screen and (max-width: 480px) {
    font-size: 13px;
    flex-wrap: wrap;
    line-height: 50%;
  }
`;

export const TextColor = styled.h3`
  font-family: "Rubik";
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #1c70bb;

  @media screen and (max-width: 480px) {
    font-size: 12px;
    flex-wrap: wrap;
  }
`;

const Btn = styled.button`
  width: 28px;
  height: 28px;
  border-radius: 28px;
  background: #ccb86d;
  cursor: pointer;
  border: 0 none;

  @media screen and (max-width: 480px) {
    width: 18.7px;
    height: 18.7px;
    line-height: 0;
    font-size: 9.5px;
  }

  @media screen and (min-width: 481px) and (max-width: 890px) {
    width: 19.9px;
    height: 19.9px;
  }
`;

const DivVPulsador = styled.div<{
  selecionarCor?: boolean;
  tipo?: string;
}>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: absolute;

  @media screen and (max-width: 480px) {
    width: 190px;
    margin-bottom: 220px;
  }

  @media screen and (min-width: 481px) and (max-width: 890px) {
    /* width: 91.9px;
    height: 187.7px; */
  }

  ${({ tipo, selecionarCor }) => {
    if (tipo === "Keypad 4x4" && selecionarCor === false) {
      return css`
        width: 520px;
        margin-bottom: 410px;

        @media screen and (max-width: 480px) {
          width: 290px;
          margin-bottom: 220px;
          z-index: 1;
        }
      `;
    }

    if (tipo === "Keypad 4x4" && selecionarCor === true) {
      return css`
        width: 1050px;
        margin-bottom: 370px;

        @media screen and (max-width: 480px) {
          width: 400px;
          margin-bottom: 100px;
          z-index: 1;
        }
      `;
    }

    if (tipo === "Keypad" && selecionarCor === false) {
      return css`
        width: 290px;
        margin-bottom: 410px;

        @media screen and (max-width: 480px) {
          width: 190px;
          margin-bottom: 220px;
          z-index: 1;
        }
      `;
    }
    if (tipo === "Keypad" && selecionarCor === true) {
      return css`
        width: 820px;
        margin-bottom: 370px;

        @media screen and (max-width: 480px) {
          width: 400px;
          margin-bottom: 100px;
          z-index: 1;
        }
      `;
    }
  }}
`;

const DivAdd4x4 = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  margin-left: 391px;

  @media screen and (max-width: 480px) {
    margin-left: 224px;
  }
`;

const DivKp4x4 = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  margin-right: 621px;

  @media screen and (max-width: 480px) {
    margin-right: 327px;
  }
`;

interface AddKeypadProps {}

const BtnPulsador: React.FC<{
  colorBtn: string;
  textBtn: Record<string, string>;
  updateTextBtn: (data: Record<string, string>) => void;
  k4x4?: string;
}> = ({ colorBtn, textBtn, updateTextBtn, k4x4 }) => {
  return (
    <PulsadorBtns bg={colorBtn}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          height: "90%"
        }}
      >
        <Buttons k4x4={k4x4}/>
      </div>
    </PulsadorBtns>
  );
};

const AddKeypad: React.FC<AddKeypadProps> = ({}) => {
  // const [Keypad4x4, setKeypad4x4] = useState(false);
  const [selecionarCor, setSelecionarCor] = useState<boolean>(false);
  const {
    slider: currentSlide,
    keypads,
    updateDateKeypad,
  } = useSliderContext();

  const colorEspelho = useMemo(
    () => keypads[currentSlide]?.colorEspelho || "",
    [currentSlide, keypads]
  );

  const updateColorEspelho = useCallback(
    (colorEspelho: string) => {
      updateDateKeypad(currentSlide, {
        colorEspelho,
      });
    },
    [currentSlide, updateDateKeypad]
  );

  const colorBtn = useMemo(
    () => keypads[currentSlide]?.colorBtn || "",
    [currentSlide, keypads]
  );

  const updateColorBtn = useCallback(
    (colorBtn: string) => {
      updateDateKeypad(currentSlide, {
        colorBtn,
      });
    },
    [currentSlide, updateDateKeypad]
  );

  const tipo = useMemo(
    () => keypads[currentSlide]?.tipo || "",
    [currentSlide, keypads]
  );

  const updateTipo = useCallback(
    (tipo: string) => {
      updateDateKeypad(currentSlide, {
        tipo,
      });
    },
    [currentSlide, updateDateKeypad]
  );

  const textBtn = useMemo(
    () => keypads[currentSlide]?.textBtn || undefined,
    [currentSlide, keypads]
  );

  const updateTextBtn = useCallback(
    (textBtn: Record<string, string>) => {
      updateDateKeypad(currentSlide, {
        textBtn,
      });
    },
    [currentSlide, updateDateKeypad]
  );

  const quantBtn = useMemo(
    () => keypads[currentSlide]?.quantBtn || 0,
    [currentSlide, keypads]
  );

  const updateQuantBtn = useCallback(
    (quantBtn: number) => {
      updateDateKeypad(currentSlide, {
        quantBtn,
      });
    },
    [currentSlide, updateDateKeypad]
  );

  const colors = () => {
    return ["#CCB86D", "#A67D78", "#404340", "#787D82", "#F8FCFF"];
  };

  const escolherCores = () => {
    setSelecionarCor(!selecionarCor);
  };

  const dismissHandler = (event: React.FocusEvent<HTMLButtonElement>): void => {
    if (event.currentTarget === event.target) {
      setSelecionarCor(false);
    }
  };

  const Color = () => {
    return (
      <DivColor>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "85%",
            gap: "5%",
          }}
        >
          <ImgVPulsador
            src={VectorPulsador}
            alt=""
            onClick={() => {
              setSelecionarCor(false);
            }}
          />
          <ColorText>Escolha uma cor</ColorText>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "85%",
            gap: "5%",
          }}
        >
          <TextColor>Espelho</TextColor>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "85%",
            gap: "6.5%",
          }}
        >
          {colors().map((color: string, index: number): JSX.Element => {
            return (
              <Btn
                style={{ background: color }}
                onClick={() => updateColorEspelho(color)}
              />
            );
          })}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "85%",
            gap: "5%",
          }}
        >
          <TextColor>Botões</TextColor>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "85%",
            gap: "6.5%",
          }}
        >
          {colors().map((color: string, index: number): JSX.Element => {
            return (
              <Btn
                style={{ background: color }}
                onClick={() => updateColorBtn(color)}
              />
            );
          })}
        </div>
      </DivColor>
    );
  };

  return (
    <>
      <PulsadorF bg={colorEspelho} tipo={tipo}>
        <DivVPulsador tipo={tipo} selecionarCor={selecionarCor}>
          <button
            style={{ border: "0 none", background: "transparent" }}
            onClick={(): void => escolherCores()}
          >
            <img
              style={
                selecionarCor === false
                  ? { margin: "7% 0", cursor: "pointer" }
                  : { display: "none" }
              }
              src={VectorPulsador}
              alt=""
            />
            {selecionarCor && (
              <button
                style={{ background: "transparent", border: "0 none" }}
                onBlur={(e: React.FocusEvent<HTMLButtonElement>): void =>
                  dismissHandler(e)
                }
              >
                <Color />
              </button>
            )}
          </button>
        </DivVPulsador>
        <BtnPulsador
          colorBtn={colorBtn}
          textBtn={textBtn}
          updateTextBtn={updateTextBtn}
        />
        {tipo === "Keypad 4x4" && (
          <BtnPulsador
            k4x4="a"
            colorBtn={colorBtn}
            textBtn={textBtn}
            updateTextBtn={updateTextBtn}
          />
        )}

        {tipo === "Keypad" && (
          <DivAdd4x4>
            <BtnAdd
              onClick={() => {
                // setKeypad4x4(true);
                updateTipo("Keypad 4x4");
                updateQuantBtn(quantBtn * 2)
              }}
            >
              <div style={{ marginLeft: "10%" }}>
                <Plus />
              </div>
            </BtnAdd>
          </DivAdd4x4>
        )}

        {tipo === "Keypad 4x4" && (
          <DivKp4x4>
            <BtnMenos
              onClick={() => {
                // setKeypad4x4(false);
                updateTipo("Keypad");
                updateQuantBtn(quantBtn / 2)
              }}
            >
              <div style={{ marginRight: "10%" }}>
                <Menos />
              </div>
            </BtnMenos>
          </DivKp4x4>
        )}
      </PulsadorF>
    </>
  );
};

export default AddKeypad;
