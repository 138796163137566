import React from "react";
import styled from "styled-components";
// import { Link } from "react-router-dom";
import Bg from "../../assets/Bg.png";
import Pulsador from "../../assets/Pulsador.png";
import { useNavigate } from "react-router-dom";

const Page = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 480px) {
    }
`;

const Text = styled.h2`
    font-family: "Rubik";
    font-weight: 500;
    font-size: 40px;
    line-height: 0%;
    color: #202E59;

    @media screen and (max-width: 480px) {
        font-size: 20px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 30px;
    }
`;

const TextPulsador = styled.h2`
    font-family: "Rubik";
    font-weight: 500;
    font-size: 96px;
    line-height: 0%;
    color: #202E59;
    margin-top: 13%;

    @media screen and (max-width: 480px) {
        font-size: 38px;
        margin-top: 8%;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 54px;
    }
`;

const Btn = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 335px;
    height: 69px;
    background: #EAEDF6;
    font-family: "Rubik";
    font-size: 16px;
    font-weight: 400;
    border-radius: 7px;
    border: 0;
    margin: 6% 0;
    cursor: pointer;

    @media screen and (max-width: 480px) {
        width: 200px;
        height: 46px;
        margin: 3% 0;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        width: 280px;
        height: 50px;
        margin: 3% 0;
    }
`;

const Historico = styled.a`
    font-family: "Rubik";
    font-weight: 500;
    font-size: 18px;
    line-height: 145%;
    color: #1C70BB;
    cursor: pointer;

    @media screen and (max-width: 480px) {
        font-size: 14px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 16px;
    }
`;

const Background = styled.img`
    position: relative;
    top: 0;
    width: 100%;
    object-fit: cover;

    @media screen and (max-width: 480px) {
        height: 100vw;
    }
`;

export const Div = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    width: 100%;
    
    @media screen and (max-width: 480px) {
    }
`;

export const Aviso = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: -6% 0;
    
    @media screen and (max-width: 480px) {
    }
`;

export const DivTexto = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    @media screen and (max-width: 480px) {
    }
`;

export const Img = styled.img`
    width: 327px;
    
    @media screen and (max-width: 480px) {
        width: 160px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        width: 200px;
    }
`;

export const TextAviso = styled.h1`
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: red;
    
    @media screen and (max-width: 480px) {
        font-size: 12px;
    }
`;

const PulsadorPage: React.FC = () => {
    const navigate = useNavigate();
    return (
        <>
            <Page>
                <Background src={Bg} alt=""/>
                <Div>
                    <Img src={Pulsador} alt="" />
                    <DivTexto>
                        <Text>FAÇA SEU</Text>
                        <TextPulsador>PULSADOR</TextPulsador>
                        <Btn onClick={() => {navigate("/criarPulsador")}}>Começar</Btn>
                        <Historico>Histórico de pedidos</Historico>
                    </DivTexto>
                </Div>
            </Page>
        </>
    );
};

export default PulsadorPage;
