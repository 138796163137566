import styled from "styled-components";

export const Cadastro = styled.div`
    display: grid;
    justify-content:center ;
    align-items: center;
    flex-wrap: wrap;
    /* min-height: 400px; */
    /* padding: "0 17.3vw"; */
    /* min-width: 600px; */
    /* max-width: 1500px; */
`;

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
    padding-bottom: 10px;
`;

export const Text = styled.h3`
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #1C70BB;
`;



// import React from "react";
// import styled from "styled-components";
// import "react-activity/dist/library.css";
// import { Container, Cadastro } from "./style";
// import { Link } from "react-router-dom";
// import Button from "../../../components/button";
// import * as Yup from "yup";
// import { Formik } from "formik";
// import { useNavigate } from "react-router-dom";
// import { authMessage } from "../../login/menssagesCode";
// import RootCadastro3 from "../../../components/rootCadastro/rootc";

// const PreencherContainer = styled.div`
//     //width: 1318px;
//     //height: 427px;
//     display: grid;
// `;

// const TitleRevenda = styled.h3`
//     font-family: 'Rubik';
//     font-style: normal;
//     font-weight: 500;
//     font-size: 18px;
//     line-height: 145%;
//     color: #000000;
// `;

// const Title = styled.h4`
//     font-family: 'Rubik';
//     font-style: normal;
//     font-weight: 400;
//     font-size: 14px;
//     line-height: 145%;
//     color: #787D82;
// `;

// const CaixaContainer = styled.div`
//     height: 3.5em;
//     /* background: yellow; */
//     border-radius: 4px;
// `;

// const Containers = styled.div`
//     display: flex;
//     justify-content: space-between;
//     margin-top: 1%;
// `;

// const Input = styled.input`
//     flex: 1;
//     background: #F2F7FD;
//     font-family: 'Rubik';
//     font-size: 16px;
//     /* height: 3.5vw; */
//     min-height: 45px;
//     max-height: 60px;
//     /* width: 35.5vw; */
//     width: 335px;
//     border: 0;
//     //align-items: center;
//     //text-align: center;
//     /* padding: 0 1vw; */
//     padding: 0 13px;
//     font-family: 'Rubik';
//     font-style: normal;
//     font-weight: 400;
//     line-height: 160%;
//     border-radius: 4px;
//     outline: 0;
// `;

// const ButtonVoltar = styled(Button)`
//     /* width: 17.7vw; */
//     width: 271px;
//     min-width: 230px;
//     max-width: 310px;
//     /* height: 6vh; */
//     /* width: 297px;
//     height: 56px; */
//     min-height: 45px;
//     max-height: 60px;
//     background: rgba(32, 46, 89, 0.2);
//     border-radius: 4px;
//     position: unset;
//     display: flex;
//    //margin-left: 3em;

//     span{
//         color: #1E272F;
//     }
// `;

// const ButtonSalvar = styled(Button)`
//     /* width: 30.95vw; */
//     width: 520px;
//     min-width: 400px;
//     max-width: 800px;
//     /* height: 6vh; */
//     min-height: 45px;
//     max-height: 60px;
//     /* width: 520px;
//     height: 56px; */
//     background: #202E59;
//     border-radius: 4px;
//     position: unset;
//     display: flex;
//    //margin-left: 3em;
// `;

// const LabelError = styled(CaixaContainer)`
//     margin-left: 0;
//     display: grid;
//     padding: 1% 3%;
//     color: #a00;
//     background: transparent;
// `;

// const validator = Yup.object().shape({
//     email: Yup.string().email("Email não é valido.").required("Email é necessário."),
//     senha: Yup.string().min(6, "A senha deve ter pelo menos 6 caracteres").required("Senha é necessária."),
//     confirmarSenha: Yup.string().oneOf([Yup.ref('senha')], "Confirmação incorreta").required("Confirme sua senha!") ,
//     celular: Yup.number().min(8, "Celular não é valido").required("Celular é necessário."),
//     telefone: Yup.number().min(8, "Telefone invalido.").required("Telefone é necessário."),
    
// });

// const initialValues = {
//     email: "",
//     senha: "",
//     confirmarSenha: "",
//     ddd: "",
//     celular: "",
//     telefone: "",
// };

// const PaginaRevendaCadastro: React.FC = () => {
//     const navigate = useNavigate();

//     const handleClick = () => {
//         window.scrollTo({top: 0, behavior: 'smooth'});
//     };

//     return ( 
//         <>
//             <Formik
//                 validationSchema={validator}
//                 onSubmit={async ({ email, senha, confirmarSenha, celular, telefone }, helper) => {
//                     try {
//                         helper.setStatus(false);
//                         await //(getAuth(), email, senha, confirmarSenha, celular, telefone);
//                         navigate("/revendaConclusao");
//                     } catch (error: any) {
//                         helper.setStatus(authMessage[error.code] || "Erro ao fazer a sua solicitação verifique e tente novamente mais tarde");
//                     }
//                 }}
//                 initialValues={initialValues}
//             >
//                 {({ handleChange, values: { email, senha, confirmarSenha, ddd, celular, telefone }, isValid, submitForm, dirty, errors, touched, isSubmitting, status }) => {
//                     const emailError = touched.email && errors.email;
//                     const senhaError = touched.senha && errors.senha;
//                     const confirmarSenhaError = touched.confirmarSenha && errors.confirmarSenha;
//                     const celularError = touched.celular && errors.celular;
//                     const telefoneError = touched.telefone && errors.telefone;
//                     return (
//                         <>
//                             <Cadastro>
//                                 <RootCadastro3 />
//                                 {/* <div style={{
//                                     // padding: "0 17.3vw 0 0"
//                                     }}> */}
//                                     <TitleRevenda>Cadastro</TitleRevenda>
//                                     <PreencherContainer>
//                                         <div>
//                                             <div>
//                                                 <Title>Email</Title>
//                                                 {/* <CaixaContainer style={{width: "78.5vw"}}> */}
//                                                     <Input 
//                                                     type="email" name="email" onChange={handleChange("email")} value={email}
//                                                     style={{
//                                                         minWidth: "800px",
//                                                         maxWidth: "1800px",
//                                                         // width: "1285px"
//                                                         }}>
//                                                     </Input>
//                                                     {emailError && <LabelError>{emailError}</LabelError>}
//                                                 {/* </CaixaContainer> */}
//                                             </div>
//                                             <Containers>
//                                                 <div>
//                                                     <Title>Senha</Title>
//                                                     {/* <CaixaContainer style={{width: "37.5vw"}}> */}
//                                                         <Input
//                                                         type="password" name="senha" onChange={handleChange("senha")} value={senha}>
//                                                         </Input>
//                                                         {senhaError && <LabelError>{senhaError}</LabelError>}
//                                                     {/* </CaixaContainer> */}
//                                                 </div>
//                                                 <div>
//                                                     <Title>Confirmar senha</Title>
//                                                     {/* <CaixaContainer style={{width: "37.5vw"}}> */}
//                                                         <Input
//                                                         type="password" name="confirmarSenha" onChange={handleChange("confirmarSenha")} value={confirmarSenha}>
//                                                         </Input>
//                                                         {confirmarSenhaError && <LabelError>{confirmarSenhaError}</LabelError>}
//                                                     {/* </CaixaContainer> */}
//                                                 </div>
//                                             </Containers>
//                                             <Containers>
//                                                 <div>
//                                                     <Title>Celular</Title>
//                                                     <div style={{display: "flex", justifyContent: "space-between"}}>
//                                                         <div style={{marginRight: "2em"}}>
//                                                                 <Input 
//                                                                 type="tel" name="ddd" onChange={handleChange("ddd")} value={ddd}
//                                                                 style={{
//                                                                     // width: "45px",
//                                                                     minWidth: "30px",
//                                                                     maxWidth: "45px"
//                                                                     }}></Input>
//                                                         </div>
//                                                         <div>
//                                                             {/* <CaixaContainer style={{width: "30.95vw"}}>  */}
//                                                                 <Input 
//                                                                 type="tel" name="celular" onChange={handleChange("celular")} value={celular}
//                                                                 style={{
//                                                                     // width: "300px",
//                                                                     minWidth: "150px",
//                                                                     maxWidth: "235px"
//                                                                     }}>
//                                                                 </Input>
//                                                                 {celularError && <LabelError>{celularError}</LabelError>}
//                                                             {/* </CaixaContainer> */}
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                                 <div>
//                                                     <Title>Telefone fixo</Title>
//                                                     {/* <CaixaContainer style={{width: "37.5vw"}}>  */}
//                                                         <Input 
//                                                         type="tel" name="telefone" onChange={handleChange("telefone")} value={telefone}>

//                                                         </Input>
//                                                         {telefoneError && <LabelError>{telefoneError}</LabelError>}
//                                                     {/* </CaixaContainer> */}
//                                                 </div>
//                                             </Containers>
//                                             <Containers>
//                                                 <div>
//                                                 <Title>Celular 2 (Opcional)</Title>
//                                                     <div style={{display: "flex", justifyContent: "space-between"}}>
//                                                         <div style={{marginRight: "2em"}}>
//                                                             {/* <CaixaContainer style={{width: "4.95vw"}}>  */}
//                                                                 <Input style={{
//                                                                     minWidth: "30px",
//                                                                     maxWidth: "45px"
//                                                                     }}></Input>
//                                                             {/* </CaixaContainer> */}
//                                                         </div>
//                                                         <div>
//                                                             {/* <CaixaContainer style={{width: "30.95vw"}}>  */}
//                                                                 <Input style={{
//                                                                     // width: "29vw",
//                                                                     minWidth: "150px",
//                                                                     maxWidth: "235px"
//                                                                     }}></Input>
//                                                             {/* </CaixaContainer> */}
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </Containers>
//                                         </div>
//                                     </PreencherContainer>
//                                     <Container>
//                                         <Link to="/email" style={{ textDecoration: "none" }}>
//                                             <ButtonVoltar onClick={handleClick} title="Voltar página"/>
//                                         </Link>

//                                         <ButtonSalvar //"/conclusao"
//                                         loading={isSubmitting}
//                                         title="Salvar e finalizar"
//                                         onClick={submitForm}
//                                         />
//                                         {status && <LabelError>{status}</LabelError>}

//                                     </Container>
//                                 {/* </div> */}
//                             </Cadastro>
//                         </>
//                     );
//                 }}
//             </Formik>   
//         </> 
//     );
// };              

// export default PaginaRevendaCadastro;
                
                
            {/* <Title>Descrição</Title> */}

                {/* font-size: 16px;
                height: 100%;
                width: 100%;
                border: 0;
                padding-left: 2em;
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 160%; */}
            {/*  */}
                
        
        
        {/* <CaixaContatoButton title="Enviar"></CaixaContatoButton> */}

    {/*  */}