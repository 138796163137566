import * as React from "react";
import { useEffect, useState } from "react";
import useDebounce from "../hooks/useDebounce";
import styled from "styled-components";
import Lupa from "../../../assets/Lupa.png";
import Button from "../../button";

const Container = styled.div`
    display: flex;
    min-width: 500px;
    max-width: 1000px;
    /* width: 53.95vw; */
    /* height: 6.5vh;  */
    /* height: 3.6vw; */
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    /* background: red; */
    padding: 0 13.4%;
    margin-bottom: 5%;

    @media screen and (max-width: 480px) {
        min-width: 100px;
        max-width: 300px;
    }
`;

const Input = styled.input`
    /* width: 95%; */
    min-width: 500px;
    max-width: 1000px;
    min-height: 45px;
    max-width: 55px;
    /* height: 3.6vw; */
    border: 0;
    outline: 0;
    border-radius: 4px;
    font-family: "Rubik";
    font-weight: 400;
    font-size: 14px;
    padding-left: 1vw;
    background: #f4fafa;

    @media screen and (max-width: 480px) {
        min-width: 200px;
        max-width: 400px;
        padding-left: 5%;
        min-height: 35px;
    }
`;

const Pesquisar = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1c70bb;
    min-width: 45px;
    max-width: 55px;
    /* width: 3.6vw;
  height: 3.6vw; */
    min-height: 45px;
    max-height: 55px;
    border-radius: 4px;
    border: 0;
    cursor: pointer;

    @media screen and (max-width: 480px) {
        min-width: 35px;
        min-height: 35px;
    }
`;

export interface ISearchProps {
    onChangeSearchQuery: (searchQuery: string) => void;
}

export default function SearchInput(props: ISearchProps) {
    const [searchQuery, setSearchQuery] = useState<string | undefined>();
    const { onChangeSearchQuery } = props;
    const debouncedSearchQuery = useDebounce(searchQuery, 250);

    useEffect(() => {
        if (debouncedSearchQuery !== undefined) {
            onChangeSearchQuery(debouncedSearchQuery);
        }
    }, [debouncedSearchQuery, onChangeSearchQuery]);

    return (
        <>
            <Container>
                <Input
                    id="search"
                    className="form-control full-width"
                    type="search"
                    placeholder="Buscar produto"
                    aria-label="Search"
                    onChange={(event) => setSearchQuery(event.target.value)}
                ></Input>
                <Pesquisar>
                    <img src={Lupa} style={{ minWidth: "10px", maxWidth: "20px" }} alt="" />
                </Pesquisar>
            </Container>
        </>
    );
}
