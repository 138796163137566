import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Products from "../../../controllers/getProducts";
// import Newsletter from "../../sobre/Newsletter";
import { useLoaderData } from "react-router-dom";
// import { NavIten } from "../../revenda/dadosPessoais/styled";
import SaibaMais from "../../../assets/SaibaMais.png";
// import DropdownVar from "../../addProduto/dropdownVar/dropVar";
import { useVariacoes } from "../../../hooks/variacoes";
// import Var from "./variacoes";

const PaginaProduto = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 8vw 0; */
  width: 100vw;

  @media screen and (max-width: 480px) {
  }
`;

const Img = styled.div`
  display: flex;
  /* flex: 1; */
  background: #f5f7f9;
  box-shadow: -8px 26px 40px rgba(131, 155, 177, 0.08);
  align-items: center;
  justify-content: center;
  /* width: 612px; */
  min-width: 568.4px;
  max-width: 650.6px;
  min-height: 580px;
  max-height: 860.5px;
  /* height: 725px; */
  background-color: #d9d9d9;

  @media screen and (max-width: 480px) {
    min-width: 397.9px;
    max-width: 455.42px;
    min-height: 406px;
    max-height: 589.75px;
  }

  @media screen and (min-width: 481px) and (max-width: 890px) {
    min-width: 397.9px;
    max-width: 455.42px;
    min-height: 406px;
    max-height: 589.75px;
  }
`;

const Line = styled.div`
  /* width: 829px; */
  min-width: 740.3px;
  max-width: 1138px;
  height: 1px;
  border: 1;
  background-color: #00000087;
  margin: 48px 0;

  @media screen and (max-width: 480px) {
    min-width: 300px;
    max-width: 450px;
    margin: 48px 0;
  }

  @media screen and (min-width: 481px) and (max-width: 890px) {
    min-width: 300px;
    max-width: 750px;
    margin: 48px 0;
  }
`;

const Image = styled.div`
  background: #f5f7f9;
  /* box-shadow: -8px 26px 40px rgba(131, 155, 177, 0.08); */
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex: 1; */
  /* width: 166px; */
  min-width: 102.8px;
  max-width: 200.8px;
  /* height: 162px; */
  min-height: 129.6px;
  max-height: 200.6px;
  background-color: #d9d9d9;

  @media screen and (max-width: 480px) {
    min-width: 62.3px;
    max-width: 120.3px;
    min-height: 60.24px;
    max-height: 155.6px;
  }

  @media screen and (min-width: 481px) and (max-width: 890px) {
    min-width: 86.3px;
    max-width: 140.3px;
    min-height: 84.24px;
    max-height: 175.6px;
  }

  div {
    display: flex;
    flex-wrap: wrap;
    width: 200px;
    height: 250px;
    /* min-width: 200px;
        max-width: 360px;
        min-height: 200px;
        max-height: 400px; */
    align-items: center;
    justify-content: center;
    /* background-color: red; */

    @media screen and (max-width: 480px) {
      width: 125px;
      height: 200px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
      width: 150px;
      height: 200px;
    }

    img {
      width: auto;
      max-height: 50%;

      @media screen and (max-width: 480px) {
        /* height: 40%; */
      }
    }
  }
`;

const NomeProduto = styled.h1`
  font-family: "Rubik";
  font-size: 64px;
  font-weight: 500;
  color: #1e272f;

  @media screen and (max-width: 480px) {
    font-size: 36px;
  }

  @media screen and (min-width: 481px) and (max-width: 890px) {
    font-size: 42px;
  }
`;

const Informacao = styled.h2`
  font-family: "Rubik";
  font-size: 40px;
  font-weight: 500;
  color: #4b5259;
  line-height: 54px;

  @media screen and (max-width: 480px) {
    font-size: 27px;
  }
`;

const Sobre = styled.h2`
  font-family: "Rubik";
  font-size: 24px;
  font-weight: 300;
  color: #4b5259;
  line-height: 32.4px;

  @media screen and (max-width: 480px) {
    font-size: 27px;
  }
`;

// const Variacoes = styled.div``;

// const Especificacoes = styled.div``;

// const Esp = styled.div`
//     background: rgba(32, 46, 89, 0.1);
//     border-radius: 21px;
//     width: 161px;
//     height: 34px;
//     align-items: center;
//     text-align: center;
//     justify-content: center;

//     @media screen and (max-width: 480px) {
//         width: 75px;
//         height: 25.5px;
//     }
// `;

// const Espec = styled.div`
//     background: rgba(32, 46, 89, 0.1);
//     border-radius: 21px;
//     width: 446px;
//     height: 34px;
//     align-items: center;
//     text-align: center;
//     justify-content: center;

//     @media screen and (max-width: 480px) {
//         width: 240px;
//         height: 25.5px;
//     }
// `;

const Manual = styled.div``;

// const Text = styled.h4`
//     font-family: "Rubik";
//     font-size: 14px;
//     font-weight: 400;
//     line-height: 145%;
//     color: #1c70bb;

//     @media screen and (max-width: 480px) {
//         font-size: 12px;
//         line-height: 0%;
//     }
// `;

// const Sugestoes = styled.div`
//     display: grid;
//     justify-content: center;
//     text-align: center;
// `;

const Title = styled.h3`
  font-family: "Rubik";
  font-size: 32px;
  font-weight: 500;
  color: #1e272f;
  /* text-align: center; */

  @media screen and (max-width: 480px) {
    font-size: 24px;
    /* line-height: 0%; */
  }
`;

// const Color = styled.div`
//     width: 4.5vw;
//     height: 4.5vw;
//     border-radius: 3vw;

//     @media screen and (max-width: 480px) {
//         /* width: 2vw;
//         height: 2vw; */
//     }
// `;

// const Container = styled.div`
//     width: 22.65vw;
//     height: 28.25vw;
//     background: rgba(255, 255, 255, 0.8);
//     box-shadow: 0px 179px 72px rgba(0, 0, 0, 0.01), 0px 101px 60px rgba(0, 0, 0, 0.05), 0px 45px 45px rgba(0, 0, 0, 0.09), 0px 11px 25px rgba(0, 0, 0, 0.1),
//         0px 0px 0px rgba(0, 0, 0, 0.1);
//     border-radius: 67px;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     margin-bottom: 4vw;

//     @media screen and (max-width: 480px) {
//         /* font-size: 18px;
//         margin: 0; */
//     }
// `;

// const Name = styled.h3`
//     font-family: "Rubik";
//     font-size: 16px;
//     font-weight: 700;
//     color: #202e59;
//     line-height: 160%;

//     @media screen and (max-width: 480px) {
//         /* font-size: 12px; */
//     }
// `;

// const Desc = styled.h4`
//     font-family: "Rubik";
//     font-size: 14px;
//     font-weight: 400;
//     color: #1e272f;
//     line-height: 145%;
//     padding: 0 4vw;

//     @media screen and (max-width: 480px) {
//         font-size: 11px;
//     }
// `;

// const NavIten = styled(Link)`
//     display: inline-flex;
//     align-items: center;
//     //text-align: center;
//     color: #1c70bb;
// `;

// const Texto = styled.h3`
//     font-family: "Rubik";
//     font-size: 14px;
//     font-weight: 500;
//     line-height: 50%;
//     color: #1e272f;

//     @media screen and (max-width: 480px) {
//         font-size: 11px;
//     }
// `;

const A = styled.a`
  font-family: "Rubik";
  font-size: 14px;
  font-weight: 400;
  line-height: 145%;
  color: #1c70bb;

  @media screen and (max-width: 480px) {
    font-size: 12px;
    line-height: 0%;
  }
`;

const Text1 = styled.h3`
  font-family: "Rubik";
  font-size: 24px;
  font-weight: 400;
  color: #1e272f94;
  /* line-height: 32.4px; */
  line-height: 0%;

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }

  @media screen and (min-width: 481px) and (max-width: 890px) {
    font-size: 16px;
  }
`;

const Text2 = styled.h3`
  font-family: "Rubik";
  font-size: 24px;
  font-weight: 500;
  color: #1e272f;
  /* line-height: 32.4px; */
  line-height: 0%;

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }

  @media screen and (min-width: 481px) and (max-width: 890px) {
    font-size: 16px;
  }
`;

const Op = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* min-width: 55.2px;
    max-width: 82.8px; */
  width: 65px;
  /* min-height: 58.4px;
    max-height: 87.6px; */
  height: 69px;
  border-radius: 3px;
  border: 1px solid;
  border-color: #0000009c;
  cursor: pointer;
  /* background-color: #0000009C; */

  @media screen and (max-width: 480px) {
    min-width: 55.2px;
    max-width: 82.8px;
    min-height: 58.4px;
    max-height: 87.6px;
  }

  @media screen and (min-width: 481px) and (max-width: 890px) {
    min-width: 35.9px;
    max-width: 52.8px;
    min-height: 38.4px;
    max-height: 57.6px;
  }
`;

const Text3 = styled.h1`
  font-family: "Rubik";
  font-size: 20px;
  font-weight: 400;
  color: #1e272f94;
  /* line-height: 32.4px; */
  line-height: 0%;

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }

  @media screen and (min-width: 481px) and (max-width: 890px) {
    font-size: 16px;
  }
`;

// const Btn = styled.button`
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     min-width: 398.4px;
//     max-width: 597.6px;
//     min-height: 56.8px;
//     max-height: 85.2px;
//     border-radius: 4px;
//     cursor: pointer;
//     border: 0 none;
//     background-color: #202E59;
//     color: #FAFBFF;
//     font-size: 21px;
//     font-weight: 400;
//     font-family: 'Rubik';
// `;

// function EspecificacoesList({ especificacoes }: { especificacoes: Record<string, string> }) {
//     return (
//         <>
//             {Object.keys(especificacoes).map((key) => {
//                 return (
//                     <>
//                         <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
//                             <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
//                                 <Esp>
//                                     <Texto>{key}</Texto>
//                                 </Esp>
//                             </div>
//                             <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
//                                 <Espec>
//                                     <Texto>{especificacoes[key]}</Texto>
//                                 </Espec>
//                             </div>
//                         </div>
//                     </>
//                 );
//             })}
//         </>
//     );
// }

const Consultar = styled.a`
    font-family: "Rubik";
    font-size: 20px;
    font-weight: 400;
    line-height: 0%;
    color: #1C70BB;
    cursor: pointer;
    text-decoration: none;
    text-decoration-color: none;

    @media screen and (max-width: 480px) {
        font-size: 12px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 13px;
    }
`;

function FileList({ datasheets }: { datasheets: Record<string, string> }) {
  return (
    <>
      {Object.keys(datasheets).map((key) => {
        return (
          <>
            <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
              <A href={datasheets[key]}>{key}</A>
            </div>
          </>
        );
      })}
    </>
  );
}

export function PageProduto() {
  const product = useLoaderData() as Products | undefined;
  const [vars, setVars] = React.useState<Array<[string, string]>>([]);
  const [colorSelected, setColorSelected] = useState<string>("");
  // const [file, setFile] = React.useState<Array<[string]>>([]);
  const {
    // listColors, variacao, setvaricao, varicaoSender, updateVariacao,
    varicaoSeleted,
    // newVariacao
  } = useVariacoes({ listVariacao: product?.variants });

  const number = "+5511958746055";

  const Consulta = (
      <Consultar href={`https://wa.me/${number}`} rel="noreferrer" target="_blank">
        Consultar
      </Consultar>
  );

  // const [img, setImg] = useState<string[]>([]);
  // const navigate = useNavigate();

  // const { name, imgs, quant, preco, id } = props;

  useEffect(() => {
    let esp: Record<string, string> = {};
    vars.forEach((e) => {
      esp[e[0]] = e[1];
    });
  }, [vars]);

  if (!product) {
    return (
      <>
        <>Produto nao existe</>
      </>
    );
  }

  const {
    name,
    variants,
    quantTeclas,
    // esp, // {key: value}
    datasheets,
    desc,
    imgs,
    id,
    modelo,
  } = product;

  // const files = Array(3)
  //     .fill(0)
  //     .map((_) => String(file[_]));

  const images = Array(5)
    .fill(0)
    .map((_, i) => (varicaoSeleted ? varicaoSeleted.img[i] : String(imgs[i])));

  console.log(datasheets);
  console.log(images);

  const imagens = variants.map((e) => e.img);
  let listImgs: Array<string> = [];
  imagens.forEach((element) => {
    if (element) {
      listImgs = [...listImgs, ...element];
    }
  });
  const list = listImgs?.filter((item, pos, a) => {
    return a.indexOf(item) == pos && item != "" && item;
  });

  // console.log("cores: ", cores);
  console.log("list: ", list);

  return (
    <PaginaProduto>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          maxWidth: "1200px",
        }}
      >
        <NomeProduto>
          {name}
          {id}
        </NomeProduto>
        <div style={{ display: "flex", gap: "30px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
            }}
          >
            <div
              style={{ display: "flex", justifyContent: "center", gap: "18px" }}
            >
              {/* {images[1] && images[2] && ( */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                {images[1] ? variants.find((e) => e.color === colorSelected)?.img[1] ? (
                  <Image>
                    <div>
                      <img src={
                        colorSelected
                        ? variants.find((e) => e.color === colorSelected)
                            ?.img[1]
                        : images[1]
                        // images[0]
                        } alt="" />
                    </div>
                  </Image>
                ) : (
                  <div style={{display: "none"}}/>
                ) : (
                  <div style={{display: "none"}}/>
                )}

                {images[2] ? variants.find((e) => e.color === colorSelected)?.img[2] ? (
                  <Image>
                    <div>
                      <img src={
                        colorSelected
                        ? variants.find((e) => e.color === colorSelected)
                            ?.img[2]
                        : images[2]
                        // images[1]
                        } alt="" />
                    </div>
                  </Image>
                ) : (
                  <div style={{display: "none"}}/>
                ): (
                  <div style={{display: "none"}}/>
                )}

                {images[3] ? variants.find((e) => e.color === colorSelected)?.img[3] ? (
                  <Image>
                    <div>
                      <img src={
                        colorSelected
                        ? variants.find((e) => e.color === colorSelected)
                            ?.img[3]
                        : images[3]
                        // images[3]
                        } alt="" />
                    </div>
                  </Image>
                ) : (
                  <div style={{display: "none"}}/>
                ) : (
                  <div style={{display: "none"}}/>
                )}

                {images[4] ? variants.find((e) => e.color === colorSelected)?.img[4] ? (
                  <Image>
                    <div>
                      <img src={
                        colorSelected
                        ? variants.find((e) => e.color === colorSelected)
                            ?.img[4]
                        : images[4]
                        // images[4]
                        } alt="" />
                    </div>
                  </Image>
                ) : (
                  <div style={{display: "none"}}/>
                ) : (
                  <div style={{display: "none"}}/>
                )}
              </div>
              {/* )} */}
              <Img>
                <div
                  style={{
                    display: "flex",
                    maxWidth: "500px",
                    maxHeight: "500px",
                    overflow: "hidden",
                  }}
                >
                  <img
                    style={{ objectFit: "cover", width: "100%" }}
                    src={
                      colorSelected
                        ? variants.find((e) => e.color === colorSelected)
                            ?.img[0]
                        : images[0]
                    }
                    alt=""
                  />
                </div>
              </Img>
            </div>
            <Line />
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "30px" }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "5px",
                }}
              >
                <Text1>Modelo:</Text1>
                <Text2>{modelo}</Text2>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "10px",
                  flexWrap: "wrap",
                }}
              >
                <Op>
                  {/* , background: "red" */}
                  <div
                    style={{
                      display: "flex",
                      maxWidth: "100%",
                      maxHeight: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img style={{ width: "60%" }} src={images[0]} alt="" />
                  </div>
                </Op>
                {/* <Op>
                                    <img style={{maxWidth: "50px"}} src={images[1]} alt="" />
                                </Op>
                                <Op>
                                    <img style={{maxWidth: "50px"}} src={images[0]} alt="" />
                                </Op> */}
              </div>
            </div>
            {variants.length > 1 && (
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "5px",
                  }}
                >
                  <Text1>Cor:</Text1>
                  <Text2>{colorSelected}</Text2>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "10px",
                    flexWrap: "wrap",
                  }}
                >
                  {variants
                    .map((e) => e.color)
                    .map((e) => (
                      <Op
                        onClick={() => {
                          setColorSelected(e);
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            maxWidth: "100%",
                            maxHeight: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            style={{ width: "60%" }}
                            src={variants.find((a) => a.color === e)?.img[0]}
                            alt=""
                          />
                        </div>
                      </Op>
                    ))}
                </div>
              </div>
            )}
            {quantTeclas && (
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "5px",
                  }}
                >
                  <Text1>Quantidade de Teclas:</Text1>
                  <Text2>
                    {quantTeclas} {quantTeclas > 1 ? "Botões" : "Botão"}
                  </Text2>
                </div>
                {/* <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "10px", flexWrap: "wrap"}}>
                                        <Op/>
                                        <Op/>
                                        <Op/>
                                        <Op/>
                                        <Op/>
                                        <Op/>
                                    </div>                            */}
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "12px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "5px",
                }}
              >
                <Text3>Quantidade Mínima:</Text3>
                <Text3>{Consulta}</Text3>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "5px",
                }}
              >
                <Text3>Preço:</Text3>
                <Text3>{Consulta}</Text3>
              </div>
            </div>
            {/* <Btn onClick={() => {navigate("../../register")}}>Cadastre-se como Revendedor</Btn> */}
          </div>
        </div>
        <Informacao>Informações do Produto</Informacao>
        <Sobre>{desc}</Sobre>
      </div>
      <div
        style={{
          display: "flex",
          width: "70vw",
          justifyContent: "space-between",
          margin: "4vw 0 5vw",
        }}
      >
        <div style={{ display: "grid", marginTop: "1vw" }}>
          {/* {listColors.length > 0 && (
                        <Variacoes>
                            <Title>Variações</Title>
                            <input type="text" value={varicaoSender} name="variants" hidden />
                            <div style={{ display: "flex" }}>
                                <Var listColors={listColors} onChangeVariacao={setvaricao} />
                            </div>
                        </Variacoes>
                    )} */}

          {/* <Especificacoes>
                        <Title>Especificações</Title>
                        <div style={{ display: "flex", gap: "1.7vw", flexDirection: "column" }}>
                            <EspecificacoesList especificacoes={esp} />
                        </div>
                    </Especificacoes> */}

          {Object.keys(datasheets).length > 0 && (
            <Manual>
              <Title>Manual</Title>
              <FileList datasheets={datasheets} />
              <img
                style={{ paddingLeft: "0.1em" }}
                src={SaibaMais}
                alt="Saiba Mais"
              />
            </Manual>
          )}
        </div>
      </div>
    </PaginaProduto>
  );
}
