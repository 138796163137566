import React, { useState } from "react";
import styled, { css } from "styled-components";
import DropDown from ".";
import Drop from '../../assets/Drop.svg'

 export const Btn = styled.button`
   display: flex;
   align-items: center;
   justify-content: space-between;
   position: absolute;
   /* width: 9vw; */
   min-width: 100px;
   max-width: 200px;
   /* height: 3.5vw; */
   min-height: 45px;
   max-height: 60px;
   padding: 0 13px;
   color: #333;
   background-color: #F2F7FD;
   font-family: 'Rubik';
   font-weight: 400;
   font-size: 16px;
   line-height: 160%;
   text-align: left;
   white-space: nowrap;
   vertical-align: middle;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   border: 0;
   border-radius: 4px;
   cursor: pointer;

   @media screen and (max-width: 480px) {
      min-height: 35px;
      max-height: 45px;
      padding: 0 6px;
      font-size: 14px;
   }

    :hover {
    background-color: #F2F7FD;
    }
    .active {
      background-color: #F2F7FD;
    }
`;

const DropD = <img src={Drop} alt=" " /> ;

// const states = ["AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MG", "MS", "MT", "PA", "PB", "PE", "PI", "PR", "RJ", "RN", "RO", "RR", "RS", "SC", "SE", "SP", "TO"];

interface DropdownProps {
  onChangeEstado?: (estado: string) => void;
  uf: string | undefined;
}

const Menu: React.FC<DropdownProps> = ({onChangeEstado, uf}): JSX.Element => {
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const [selectOpcao, setSelectOpcao] = useState<string>("");
  const [estados, setEstados] = useState<string>("");

  const opcoes = () => {
    return ["AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MG", "MS", "MT", "PA", "PB", "PE", "PI", "PR", "RJ", "RN", "RO", "RR", "RS", "SC", "SE", "SP", "TO"];
  };

  /**
   * Toggle the drop down menu
   */
  const toggleDropDown = () => {
    setShowDropDown(!showDropDown);
  };

  /**
   * Hide the drop down menu if click occurs
   * outside of the drop-down element.
   *
   * @param event  The mouse event
   */
  const dismissHandler = (event: React.FocusEvent<HTMLButtonElement>): void => {
    if (event.currentTarget === event.target) {
      setShowDropDown(false);
    }
  };

  /**
   * Callback function to consume the
   * estado name from the child component
   *
   * @param estado  The selected estado
   */
  const OpcaoSelection = (estado: string): void => {
    setSelectOpcao(estado);
    onChangeEstado?.(estado)
  };
  

  return (
    <>
      <Btn
        className={showDropDown ? "active" : undefined}
        onClick={(): void => toggleDropDown()}
        onBlur={(e: React.FocusEvent<HTMLButtonElement>): void =>
          dismissHandler(e)
        }
      >
        <div style={{fontFamily: "Rubik"}}>{selectOpcao || uf} </div>
        {DropD}
        {showDropDown && (
          <DropDown
            opcoes={opcoes()}
            showDropDown={false}
            toggleDropDown={(): void => toggleDropDown()}
            opcaoSelection={OpcaoSelection}
          />
        )}
      </Btn>
    </>
  );
};

export default Menu;