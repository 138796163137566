import styled from "styled-components";

export const PaginaAssistenciaContainer = styled.div`
    /* min-height: 150vh; */
    position: relative;
    display: flex;
    flex-direction: column;
    /* flex-wrap: nowrap; */
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: center;
    min-width: 300px;
    /* max-width: 1000px; */

    @media screen and (max-width: 480px) {
        /* text-align: center; */
    }
`;

export const Session = styled.div`
    /* width: 100%; */
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    min-width: 300px;
    max-width: 1500px;
    /* div { */
        /* padding: 0px;
        padding-inline: 0px; */
        //height: 290px;
    /* } */

    @media screen and (max-width: 480px) {
        text-align: center;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        padding: 0 10%;
    }
`;

export const TitleSession = styled.h2`
    /* Title/H2-48 */

    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 135%;
    /* margin: 2em 2.5em; */
    letter-spacing: -0.01em;
    color: #1e272f;

    @media screen and (max-width: 480px) {
        font-size: 36px;
        margin: 10% 0;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 36px;
        margin: 5% 8%;
        /* margin: 10px 30px; */
    }
`;

export const TextSession = styled.text`
    display: flex;
    //padding: 1.8em;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 160%;
    //letter-spacing: -0.01em;
    /* margin: -0.5em 3.4em; */
    color: #787d82;
    min-width: 300px;
    max-width: 1045px;

    @media screen and (max-width: 480px) {
        font-size: 18px;
        margin: 0;
        padding-inline: 8%;
    }
    
    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 18px;
        /* margin: 0; */
        padding-inline: 8%;
    }
`;

export const Assistencia = styled.a`
    display: flex;
    //padding: 1.8em;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 160%;
    //letter-spacing: -0.01em;
    /* margin: -0.5em 3.4em; */
    color: #1C70BB;
    min-width: 300px;
    max-width: 1045px;

    @media screen and (max-width: 480px) {
        font-size: 18px;
        margin: 0;
        padding-inline: 8%;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 18px;
        /* margin: 0; */
        padding-inline: 8%;
    }
    
`;

export const SessionContato = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15%;
    flex-wrap: wrap;
    min-width: 300px;
    max-width: 1500px;
`;