import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import styled from "styled-components";
import Vector from '../../assets/Vector.png'
import Ok from '../../assets/Ok.svg'

const Etapa = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: unset;
  width: 30px;
  height: 30px;
  border-radius: 24px;
  background: #A3D6C9;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    width: 20px;
    height: 20px;
  }

  @media screen and (min-width: 481px) and (max-width: 890px) {
    width: 25px;
    height: 25px;
  }
`;

const BarraProgresso2 = props => {
  var stepPercentage = 0;

  if (props.currentStep === 1) {
    stepPercentage = 0;
  } else if (props.currentStep === 2) {
    stepPercentage = 34;
  } else if (props.currentStep === 3) {
    stepPercentage = 67;
  } else if (props.currentStep === 4) {
    stepPercentage = 100;
  } else {
    stepPercentage = 67;
  }

  return (
      <div style={{margin: "1vw 0 3vw"}}>
        <ProgressBar
        percent={stepPercentage}
        // filledBackground="#A3D6C9"
        filledBackground="linear-gradient( to right, #189877 50% , #A3D6C9 50%, #A3D6C9 100% )"  
        // filledBackground="#189877"
        unfilledBackground="#EAEDF6"
        >
          <Step transition="scale">
            {({ accomplished }) => (
              <Etapa className={`Etapa ${accomplished ? "accomplished" : null}`} 
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)`, background: "#189877"}}
                width="30"
                src=""/>
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <Etapa className={`Etapa ${accomplished ? "accomplished" : null}`}
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)`, background: "#189877" }}
                width="30"
                src=""       >
                  <img src={Vector}></img>
                </Etapa>
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <Etapa className={`Etapa ${accomplished ? "accomplished" : null}`}
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width="30"
                src=""       >
                  <img src={Ok}></img>
                  {/* <img style={{color: "#787D82"}} src={Vector}></img> */}
                </Etapa>
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <Etapa className={`Etapa ${accomplished ? "accomplished" : null}`}
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width="30"
                src=""       >
                  <img src={Ok}></img>
                </Etapa>
            )}
          </Step>
        </ProgressBar>
      </div>
      
  );
};

export default BarraProgresso2;