import { collection, doc, getDoc, getDocs, getFirestore, setDoc } from "firebase/firestore";
// import Filters from "../components/pesquisa/Filters/Filters";
// import { useState } from "react";

const db = getFirestore();

type Optional<T> = { [K in keyof T]?: T[K] | undefined };

export default interface Cadastro {
    // dados: Array<{
    nome: string;
    rg: string;
    cpf: string;
    // }>;
    // endereco: Array <{
    cep: string;
    estado: string;
    bairro: string;
    cidade: string;
    endereco: string;
    numero: string;
    // }>;
    // dadosEmpresa: Array <{
    empresa: string;
    cargo: string;
    departamento: string;
    inscricao: string;
    cnpj: string;
    // }>;
    // dadosLogin: Array<{
    email: string;
    senha: string;
    // }>;
    // contato: Array<{
    // ddd: number;
    celular: string;
    telefone: string;
    // ddd2: number;
    celular2: string;
    // }>;
    id?: string | null;
}


export const getAll = async () => {
    console.log("getAll");
    const ref = collection(db, 'cadastros');
    const listCadastros = await getDocs(ref);
    return listCadastros.docs.map((data) => ({ id: data.id, ...data.data() } as Cadastro));
};

export const getCadastroByID = async (id: string) => {
    console.log("getCadastroByID");
    const ref = doc(collection(db, 'cadastros'), id);
    const listCadastros = await getDoc(ref);
    return listCadastros;
}

export const setCadastroByID = async (id: string, cadastro: Optional<Cadastro>) => {
    console.log("setCadastroByID");
    const ref = doc(collection(db, 'cadastros'), id);
    await setDoc(ref, cadastro);
}

export const setCadastro = async (cadastro: Cadastro) => {
    console.log("setCadastro");
    const ref = doc(collection(db, 'cadastros'));
    await setDoc(ref, cadastro);
}

// export const editProduct = async (id: string, product: Optional<Products>) => {
//     console.log("editProduct");
//     const ref = doc(collection(db, 'products'), id);
//     await updateDoc(ref, product);
// }

// export const getCategorys = async () => {
//     console.log("getCategorys");
//     const queryRef = query(collection(db, 'products'), where('category', '!=', false));
//     const snap = await getDocs(queryRef);
//     const categorys = snap.docs.map(e => e.data().category);
//     // console.log(categorys);

//     return categorys.filter((item, pos, a) => {
//         return a.indexOf(item) == pos && item != '' && item;
//     })
// }

// export const getVar = async () => {
//     try {
//         const snap = await getDocs(collection(db, 'products'));
//         const variantss = snap.docs.map(e => e.data().variants) as Array<Products['variants']>;
//         let cores: Array<string> = [];
//         variantss.map(e => e?.map?.(b => b.color)).forEach(e => {
//             if (e) {
//                 cores = [...cores, ...e]
//             }
//         })
//         cores = cores?.filter((item, pos, a) => {
//             return a.indexOf(item) == pos && item != '' && item;
//         })
//         return cores;
//     } catch (error) {
//         console.error(error)
//     }
//     return [];
// }