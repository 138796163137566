import styled from "styled-components";

export const ProductContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
    /* padding: 10px 0 15px 0; */
    /* padding: 1rem 0 2rem 0; */

`;
export const ProductSession = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin: 20px 0 20px 0; */
`;

export const TitleSession = styled.h2`
    /* Title/H2-48 */

    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    /* line-height: 135%; */
    line-height: 0%;
    /* identical to box height, or 65px */

    letter-spacing: -0.02em;

    color: #1e272f;

    @media screen and (max-width: 480px) {
        font-size: 24px;
        align-items: center;
        text-align: center;
    }

   @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 36px;
    }
`;

export const DivCategoria = styled.div`
    display: flex;
    flex-direction: column;
    /* width: 1200px; */
    /* width: 90%; */
    /* height: 450px; */
    background-color: #787D8214;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
    min-width: 90%;
    max-width: 1300px;
    min-height: 450px;
    max-height: 550px;

    @media screen and (max-width: 480px) {
        min-width: 200px;
        max-width: 450px;
        min-height: 400px;
        max-height: 550px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 80%;
        max-width: 850px;
        min-height: 450px;
        max-height: 550px;
    }
`;

export const Img = styled.img`
    min-height: 264px;
    max-height: 264px;
    /* height: 264px; */

    @media screen and (max-width: 480px) {
        /* height: 167px; */
        min-height: 80px;
        max-height: 200px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        /* height: 211px; */
        min-height: 130px;
        max-height: 211px;
    }
`;

export const Categoria = styled.h3`
    font-family: "Rubik";
    font-size: 24px;
    font-weight: 500;
    /* line-height: 33.6px; */
    line-height: 0;
    color: #1e272f;
    flex-wrap: wrap;
    /* min-width: 200px; */

    @media screen and (max-width: 480px) {
        font-size: 18px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 20px;
    }
`;

export const DivFlex = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;

    @media screen and (max-width: 480px) {
        /* font-size: 18px; */
    }
`;

export const DivColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: yellow; */
    padding: 0 10px;
    cursor: pointer;
    /* height: 100%; */

    @media screen and (max-width: 480px) {
        /* font-size: 18px; */
    }
`;