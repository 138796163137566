import React from "react";
import styled, { css } from "styled-components";

import { Dots } from "react-activity";
import "react-activity/dist/library.css";

export const ButtonContainer = styled.div<{ color?: string; textColor?: string; disable?: boolean }>`
    background-color: ${({ color = "#1E272F", disable, theme: { shadow } }) => (disable ? shadow : color)};
    height: 3.8vw;
    width: 22vw;
    border-radius: 6px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline: 0px;
    cursor: ${({ disable }) => (disable ? "not-allowed" : "pointer")};

    @media screen and (min-width: 481px) and (max-width: 890px) {
        /* width: 75px; */
    }

    @media screen and (max-width: 480px) {
        height: 35px;
    }
    span {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        /* identical to box height, or 26px */

        text-align: center;
        color: ${({ theme: { textContrast } }) => textContrast};
        z-index: 1;

        @media screen and (max-width: 480px) {
            font-size: 12px;
            line-height: 100%;
        }
    }
    overflow: hidden;
    /* ${({ disable }) =>
        !disable &&
        css`
            ::after {
                background-color: rgba(255, 255, 255, 0.2);
                content: "";
                inset: 0;
                transform: scaleX(0);
                position: absolute;
                transform-origin: right;
                //transition: transform 0.5s ease-in-out;
                transition: 0.5s ease-in-out;
                z-index: 0;
            }
            :hover::after {
                transform: scaleX(1);
            }
        `}; */
`;

const Button = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLSpanElement> & { title: string; disable?: boolean; loading?: boolean }>(
    ({ title = "Button", loading, ...res }) => {
        return <ButtonContainer {...res}>{loading ? <Dots /> : <span>{title}</span>}</ButtonContainer>
        
    }
);

export default Button;
