import React, { useEffect, useState } from 'react';
import styled from "styled-components";
// import Drop from '../../assets/Drop.svg'

export const Drops = styled.div`
  position: absolute;
  top: 115%;
  left: 0;
  padding: 0;
  text-align: left;
  border-radius: 4px;
  z-index: 1;
  /* padding: 0.6vw 0 1vw 0; */
`;

export const P = styled.p`
  margin: 0;
  padding: 0.8vw 0 0.8vw 1.4vw;
  min-width: 8.7vw;
  font-family: 'Rubik';
  /* align-items: center; */
  /* justify-content: center; */
    
  :hover {
  background-color: #e8f2fd;
  }

  :last-child {
  border-bottom: 0 none;
  }

  @media screen and (max-width: 480px) {
    /* font-size: 12px; */
    min-width: 50px;
    padding: 7px 0 7px 10px;
  }
`;

// const DropD = <img style={{ paddingLeft: "3.5em" }} src={Drop} alt=" " /> ;

export type variacoesType = {
  label: string,
  element: React.ReactNode,
}

type DropDownProps = {
  variacoes: variacoesType[];
  showDropDown: boolean;
  toggleDropDown: Function;
  variacaoSelection: (variacao: variacoesType) => void;
};

const DropDown: React.FC<DropDownProps> = ({
  variacoes,
  variacaoSelection,
}: DropDownProps): JSX.Element => {
  const [showDropDown, setShowDropDown] = useState<boolean>(false);

  /**
   * Handle passing the estado name
   * back to the parent component
   *
   * @param estado  The selected estado
   */
  const onClickHandler = (variacao: variacoesType): void => {
    variacaoSelection(variacao);
  };

  useEffect(() => {
    setShowDropDown(showDropDown);
  }, [showDropDown]);

  return (
    <>
      <Drops className={showDropDown ? 'dropdown' : 'dropdown active'}>
        {variacoes.map(
          (variacao: variacoesType, index: number): JSX.Element => {
            return (
              <P
                key={index}
                onClick={(): void => {
                  onClickHandler(variacao);
                }}
              >
                {variacao.element}
              </P>
            );
          }
        )}
      </Drops>
    </>
  );
};

export default DropDown;