import React, { createContext, useCallback } from 'react'
import { Outlet } from 'react-router-dom';
import { setCadastro } from '../../controllers/rgetCadastro';


type FormData = {
    nome: string;
    rg: string;
    cpf: string;
    cep: string;
    estado: string;
    bairro: string;
    cidade: string;
    endereco: string;
    numero: string;  //number;
    empresa: string;
    cargo: string;
    departamento: string;
    inscricao: string;
    cnpj: string;
    email: string;
    senha: string;
    celular: string; //Record<number, string>;
    telefone: string;
    celular2: string; //Record<number, string>;
};

type FormContextType = {
    formData: FormData;
    setFormData: React.Dispatch<React.SetStateAction<FormData>>;
    sendToFormtoDB: () => void,
};

export const FormContext = createContext<FormContextType>({
    formData: {
        nome: "",
        rg: "",
        cpf: "",
        cep: "",
        estado: "",
        bairro: "",
        cidade: "",
        endereco: "",
        numero: "",
        empresa: "",
        cargo: "",
        departamento: "",
        inscricao: "",
        cnpj: "",
        email: "",
        senha: "",
        celular: "",
        telefone: "",
        celular2: "",
    },
    setFormData: () => { },
    sendToFormtoDB: () => { },
});


const RootRevenda: React.FC = () => {
    const [formData, setFormData] = React.useState<FormData>({
        nome: "",
        rg: "",
        cpf: "",
        estado: "",
        bairro: "",
        cidade: "",
        cep: "",
        endereco: "",
        numero: "",
        empresa: "",
        cargo: "",
        departamento: "",
        inscricao: "",
        cnpj: "",
        email: "",
        senha: "",
        celular: "",
        telefone: "",
        celular2: "",
    });

    const sendToFormtoDB = useCallback(async () => {
        await setCadastro(formData);
    }, [formData]);

    return (
        <FormContext.Provider value={{ formData, setFormData, sendToFormtoDB }}>
            <div style={{ height: '100vh', width: '100vw' }}>
                <Outlet />
            </div>
        </FormContext.Provider >
    );
}

export default RootRevenda;