import React from "react";
import "react-activity/dist/library.css";
import { Root, Img } from "./style"
import logo from "../../assets/logo.png";
import BarraProgresso from "../barraProgresso/apageOneBar";
import ButtonLog from "../buttonCadastro";
import MasterForm from "../barraProgresso/conjunto";
import { Link } from "react-router-dom";

const RootError: React.FC = () => {
    return ( 
    <>
        <Root>
            <div>
                <Link to="/">                 
                    <Img src={logo} alt="logo" />
                </Link>
            </div>
        </Root>
    </>
    );
};

export default RootError;