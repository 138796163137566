import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { categoriasType } from '../dropdownCat/Dropdown';
// import Drop from '../../assets/Drop.svg'

export const Drops = styled.div`
  position: absolute;
  top: 115%;
  left: 0;
  padding: 0;
  text-align: left;
  border-radius: 4px;
  padding: 0.6vw 0 0.3vw 0;
  font-family: 'Rubik';
  max-height: 150px;
  /* overflow-y: scroll; */

  @media screen and (max-width: 480px) {
    /* max-height: 300px; */
  }
`;

export const P = styled.p`
  margin: 0;
  padding: 0.8vw 0 0.8vw 1vw;
  min-width: 285px;
  font-family: 'Rubik';

  @media screen and (max-width: 480px) {
    padding: 5% 0 5% 10%;
    min-width: 100px;
  }
    
  :hover {
  background-color: #e8f2fd;
  }

  :last-child {
  border-bottom: 0 none;
  }
`;

// const DropD = <img style={{ paddingLeft: "3.5em" }} src={Drop} alt=" " /> ;

// export type modeloType = {
//     label: string ,
//     element: React.ReactNode ,
// }

type DropDownProps = {
//   opcoes: string[];
  modelos: categoriasType[];
  showDropDown: boolean;
  toggleDropDown: Function;
//   opcaoSelection: Function;
  modeloSelection: (modelo: categoriasType) => void;
};

const DropDown: React.FC<DropDownProps> = ({
  modelos,
  modeloSelection,
}: DropDownProps): JSX.Element => {
  const [showDropDown, setShowDropDown] = useState<boolean>(false);

  const onClickHandler = (modelo: categoriasType): void => {
    modeloSelection(modelo);
  };

  useEffect(() => {
    setShowDropDown(showDropDown);
  }, [showDropDown]);

  return (
    <>
      <Drops className={showDropDown ? 'dropdown' : 'dropdown active'}>
        {modelos.map(
          (modelo: categoriasType, index: number): JSX.Element => {
            return (
              <P
                key={index}
                onClick={(): void => {
                  onClickHandler(modelo);
                }}
              >
                {modelo.element}
              </P>
            );
          }
        )}
      </Drops>
    </>
  );
};

export default DropDown;