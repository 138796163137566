import React, { useEffect, useState } from "react";
import styled from "styled-components";
import UploadArq from "./arquivoUpload";

import plus from "../../../assets/plus.png";
const Input = styled.input`
  background: #f2f7fd;
  width: 13vw;
  height: 2.15vw;
  border-radius: 21px;
  padding: 0 1.5vw;
  border: 0;
  outline: 0 none;
  font-family: "Rubik";
  font-size: 14px;

  @media screen and (max-width: 480px) {
    font-size: 11px;
    /* width: ; */
    height: 25px;
    width: 80px;
    border-radius: 12px;
  }
`;

const Add = styled.button`
  border: 0 none;
  border-radius: 4vw;
  background: transparent;
`;

const Plus = styled.img`
  width: 3vw;
  height: 3vw;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    width: 20px;
    height: 20px;
  }
`;

interface DataComponenteProps {
  onChange?: (Datasheets: Array<[string, string]>) => void;
}

const UploadArquivo: React.FC<DataComponenteProps> = ({ onChange }) => {
  const [arqs, setArqs] = React.useState<Array<[string, string]>>([]);
//   const [name, setName] = useState<string>('');

  const handleArqUpdate = (numberImg: number) => (ImageUrl: string) => {
    setArqs((old) => {
      const newarry = [...old];
      newarry[numberImg][1] = ImageUrl;
      onChange?.(newarry);
      return newarry;
    });
  };

  const handlesAddArq = () => {
    setArqs((old) => [...old, ['', '']]);
  };

  return (
    <>
      <div style={{ display: "inline-table", gap: "1vw" }}>
        {arqs.map(([key, value], index) => {
            console.log(value, index)
          return (
            <>
              <div style={{ display: "flex", gap: "1vw", margin: "1vw 0", alignItems: "center" }}>
                <Input
                    key={`key-${index}`}
                    type="text"
                    value={key}
                    defaultValue={key}
                    onChange={(e) => {
                        setArqs(old => {
                            let newArq = old;
                            newArq[index][0] = e.target.value;
                            return [...newArq];
                        })
                    }}
                />
                <UploadArq onArqUpload={handleArqUpdate(index)} name="" />
                {/* <Input1
                    key={`key-${index}`}
                    type="text"
                    name="esp"
                    value={key}
                    defaultValue={key}
                    onChange={(e) => {
                        setVars(old => {
                            let newVar = old;
                            newVar[index][0] = e.target.value;
                            return [...newVar];
                        })
                    }}>
                </Input1>
                <Input
                    key={`values-${index}`}
                    value={value}
                    defaultValue={value}
                    onChange={(e) => {
                        setVars(old => {
                            let newVar = old;
                            newVar[index][1] = e.target.value;
                            return [...newVar];
                        })
                    }}>
                </Input> */}
              </div>
            </>
          );
        })}
        <Add type="button" onClick={handlesAddArq}>
          <Plus src={plus} alt="" />
        </Add>
      </div>
    </>
  );
};

export default UploadArquivo;












// import React, { useEffect, useState } from "react";
// import styled from "styled-components";
// import UploadArq from "./arquivoUpload";

// import plus from "../../../assets/plus.png";
// const Input = styled.input`
//   background: #f2f7fd;
//   width: 13vw;
//   height: 2.15vw;
//   border-radius: 21px;
//   padding: 0 1.5vw;
//   border: 0;
//   outline: 0 none;
//   font-family: "Rubik";
//   font-size: 14px;

//   @media screen and (max-width: 480px) {
//     font-size: 11px;
//     /* width: ; */
//     height: 25px;
//     width: 80px;
//     border-radius: 12px;
//   }
// `;

// // const Input1 = styled.input`
// //   background: #f2f7fd;
// //   width: 7.7vw;
// //   height: 2.15vw;
// //   border-radius: 21px;
// //   padding: 0 1.5vw;
// //   border: 0;
// //   outline: 0 none;
// //   font-family: "Rubik";
// //   font-size: 14px;

// //   @media screen and (max-width: 480px) {
// //     font-size: 11px;
// //     /* width: ; */
// //     height: 25px;
// //     width: 40px;
// //     border-radius: 12px;
// //   }
// // `;

// const Add = styled.button`
//   border: 0 none;
//   border-radius: 4vw;
//   background: transparent;
// `;

// const Plus = styled.img`
//   width: 3vw;
//   height: 3vw;
//   cursor: pointer;

//   @media screen and (max-width: 480px) {
//     width: 20px;
//     height: 20px;
//   }
// `;

// // const Input = styled.input`

// // `;

// interface DataComponenteProps {
//   onChange?: (Datasheets: Array<[string, string]>) => void;
// }

// const UploadArquivo: React.FC<DataComponenteProps> = ({ onChange }) => {
//   const [arqs, setArqs] = React.useState<Array<[string, string]>>([]);
//   // const [imgSRC, setImgSRC] = useState<string | undefined>(imageSRC);

//   const handleArqUpdate = (numberImg: number) => (ImageUrl: string, nome: string) => {
//     setArqs((old) => {
//       const newarry = [...old];
//       newarry[numberImg][0] = ImageUrl;
//       newarry[numberImg][1] = nome;
//       onChange?.(newarry);
//       return newarry;
//     });
//   };

//   const handlesAddArq = () => {
//     setArqs((old) => [...old, ['', '']]);
//   };

// //   useEffect(() => {
// //     let arq: Record<string, string> = {}
// //     arqs.forEach((e) => {
// //         arq[e[0]] = e[1];
// //     })
// //     // onChangeEsp?.(esp);
// // }, [arqs]);

//   return (
//     <>
//       <div style={{ display: "inline-table", gap: "1vw" }}>
//         {arqs.map((value, index) => {
//           return (
//             <>
//               <div style={{ display: "flex", gap: "1vw", margin: "1vw 0", alignItems: "center" }}>
//                 <UploadArq onArqUpload={handleArqUpdate(index)} name="" />
//                 <Input type="text" />
//                 {/* <Input1
//                     key={`key-${index}`}
//                     type="text"
//                     name="esp"
//                     value={key}
//                     defaultValue={key}
//                     onChange={(e) => {
//                         setVars(old => {
//                             let newVar = old;
//                             newVar[index][0] = e.target.value;
//                             return [...newVar];
//                         })
//                     }}>
//                 </Input1>
//                 <Input
//                     key={`values-${index}`}
//                     value={value}
//                     defaultValue={value}
//                     onChange={(e) => {
//                         setVars(old => {
//                             let newVar = old;
//                             newVar[index][1] = e.target.value;
//                             return [...newVar];
//                         })
//                     }}>
//                 </Input> */}
//               </div>
//             </>
//           );
//         })}
//         <Add type="button" onClick={handlesAddArq}>
//           <Plus src={plus} alt="" />
//         </Add>
//       </div>
//     </>
//   );
// };

// export default UploadArquivo;