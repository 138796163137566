import styled from "styled-components";

export const Empresa = styled.div`
    display: grid;
    justify-content:center ;
    flex-wrap: wrap;
`;

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 150px;
    padding-bottom: 100px;

    @media screen and (max-width: 480px) {
        margin-top: 50px;
        padding-bottom: 50px;
    }
`;