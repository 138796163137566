import React from "react";
// import styled, { css } from "styled-components";
import "react-activity/dist/library.css";
import { ContainerButton, ButtonLogin, ButtonRegister } from "./style"
import { Link } from "react-router-dom";

const ButtonLog: React.FC = () => {
    return ( 
    <>
        <ContainerButton>
            <div>
                <Link to="/login" style={{ textDecoration: "none", background: "#787D82" }}>
                    <ButtonLogin title = "Log In"/>
                </Link>
            </div>
            <div>
                <Link to="/register" style={{ textDecoration: "none" }}>
                    <ButtonRegister title = "Register"/>
                </Link>
            </div>
        </ContainerButton>
    </>
    );
};

export default ButtonLog;