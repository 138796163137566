import React, { useState } from "react";
import SearchInput from "../../components/pesquisa/Search/SearchInput";
// import IWidget from "../Widget/IWidget";
// import widgets from "../Widget/widgets";
import { genericSearch } from "../../components/pesquisa/Search/genericSearch";
import { genericFilter } from "../../components/pesquisa/Filters/genericFilter";
import { Filters } from "../../components/pesquisa/Filters/Filters";
import IFilter from "../../components/pesquisa/Filters/IFilter";
import styled from "styled-components";
import { object } from "yup";
import { useLoaderData } from "react-router-dom";
import Products from "../../controllers/getProducts";
import { ProductCard } from "./editProduct";

interface BarraProps {
  productsList: Products[];
  categorys: Array<string>;
}

export default function BarraPesquisa({ productsList }: BarraProps) {
  const [query, setQuery] = useState<string>("");

  const resultProducts = productsList
    .filter((product) =>
      genericSearch<Products>(product, ["name", "desc", "id", "category"], query)
    )

  return (
    <div className="container mx-auto my-2" style={{
      display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
      <SearchInput onChangeSearchQuery={(query) => setQuery(query)} />
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 11vw"
      }}>
      </div>
      {resultProducts.length > 0 && (
      // {resultProducts.length == productsList.length && (
        <div style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          // padding: "0 15vw",
          justifyContent: "center",
          // background: "red",
          width: "80%"
        }}>
          {resultProducts.map((product) => (
            <ProductCard key={product.id} {...product} />
          ))}
        </div>
      )}
      {resultProducts.length === 0 && <p style={{ fontFamily: "Rubik", fontSize: "25px", textAlign: "center" }}> {query} não encontrado!</p>}
    </div>
  );
}