import React, { useEffect, useState } from 'react';
import styled, { css } from "styled-components";
// import Drop from '../../assets/Drop.svg'

export const Drops = styled.div`
  position: absolute;
  top: 115%;
  left: 0;
  padding: 0;
  text-align: left;
  border-radius: 4px;
  z-index: 1;

  @media screen and (max-width: 480px) {
    flex-wrap: wrap;
  }
`;

export const P = styled.p`
  margin: 0;
  padding: 0.7vw 0 0.7vw 1vw;
  min-width: 18vw;
  font-family: "Rubik";

  @media screen and (max-width: 480px) {
    padding: 3% 0 3% 10%;
    width: 104px;
    flex-wrap: wrap;
  }
    
  :hover {
  background-color: #e8f2fd;
  }

  :last-child {
  border-bottom: 0 none;
  }
`;

// const DropD = <img style={{ paddingLeft: "3.5em" }} src={Drop} alt=" " /> ;

export type categoriasType = {
  label: string ,
  element: React.ReactNode ,
}

type DropDownProps = {
  // categorias: string[];
  categorias: categoriasType[];
  showDropDown: boolean;
  toggleDropDown: Function;
  // categoriaSelection: Function;
  categoriaSelection: (categoria: categoriasType) => void;
};

const DropDown: React.FC<DropDownProps> = ({
  categorias,
  categoriaSelection,
}: DropDownProps): JSX.Element => {
  const [showDropDown, setShowDropDown] = useState<boolean>(false);

  /**
   * Handle passing the estado name
   * back to the parent component
   *
   * @param estado  The selected estado
   */
  // const onClickHandler = (categoria: string): void => {
  const onClickHandler = (categoria: categoriasType): void => {

    categoriaSelection(categoria);
  };

  useEffect(() => {
    setShowDropDown(showDropDown);
  }, [showDropDown]);

  return (
    <>
      <Drops className={showDropDown ? 'dropdown' : 'dropdown active'}>
        {categorias.map(
          (categoria: categoriasType, index: number): JSX.Element => {
            return (
              <P
                key={index}
                onClick={(): void => {
                  onClickHandler(categoria); 
                }} 
              >
                {categoria.element}
              </P>
            );
          }
        )}
      </Drops>
    </>
  );
};

export default DropDown;