import styled from "styled-components";

export const Root = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2vw 0 2vw 0;
    /* width: 50.7em; */
    /* height: 5.5em; */
`;

export const Img = styled.img`
    /* width: 15.65vw; 
    height: 3.5vw; */
    min-width: 200px;
    max-width: 320px;
    min-height: 45px;
    max-height: 90px;

    @media screen and (max-width: 480px) {
        width: 117.4px; 
        height: 25.3px;
        min-width: 78.5px;
        max-width: 200px;
        min-height: 17.5px;
        max-height: 45px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        /* width: 117.4px;  */
        /* height: 25.3px; */
        min-width: 100px;
        max-width: 200px;
        min-height: 25px;
        max-height: 60px;
    }
`;