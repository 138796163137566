import React, { useCallback, useState, createContext, useContext } from "react";
import ReactDOM from "react-dom/client";
import styled from "styled-components";
import Button from "../../../components/button";
import "react-activity/dist/library.css";
import {
    Revenda, Container, Text, NavIten,
    //Dropdown 
} from "./styled"
import * as Yup from "yup";
// import { Link } from "react-router-dom";
import { Formik } from "formik";
// import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Form, useNavigate, Link } from "react-router-dom";
import Arrow from "../../../assets/Arrow.svg";
import RootCadastro from "../../../components/rootCadastro";
import Menu from "../../../components/dropdown/Menu";
import { authMessage } from "../../login/menssagesCode";
//import Example from "../../components/barraProgresso";
import axios from "axios";
import ProgressBar from "../../../components/barraProgresso/barraProgresso";
import { cpf } from "cpf-cnpj-validator";
import { getAuth } from "firebase/auth";
import { FormContext } from "../root";

const PreencherContainer = styled.div`
    /* width: auto; */
    /* height: auto; */
    /* background: #fafdfd; */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex: 1;
`;

const Containers = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    @media screen and (max-width: 480px) {
      display: grid;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
`;

const TitleRevenda = styled.h3`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 145%;
    color: #000000;

    @media screen and (max-width: 480px) {
        font-size: 16px;
        text-align: center;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 17px;
        text-align: center;
    }
`;

const Title = styled.h4`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 145%;
    color: #787D82;

    @media screen and (max-width: 480px) {
        font-size: 11px;
        line-height: 0%;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        font-size: 12px;
        line-height: 0%;
    }
`;

const CaixaContainer = styled.div`
    /* height: 3.5em; */
    background: #F2F7FD;
    border-radius: 4px;

    @media screen and (max-width: 480px) {
        /* font-size: 14px; */
    }
`;

const Input = styled.input`
    background-color: #F2F7FD;
    font-family: 'Rubik';
    font-size: 16px;
    min-height: 45px;
    max-height: 60px;
    min-width: 380px;
    max-width: 750px;
    border: 0;
    padding: 0 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    border-radius: 4px;
    outline: 0;

    @media screen and (max-width: 480px) {
        min-width: 250px;
        max-width: 350px;
        min-height: 35px;
        max-height: 45px;
        padding: 0 10px;
        font-size: 14px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 200px;
        max-width: 300px;
        min-height: 40px;
        max-height: 60px;
        padding: 0 10px;
        font-size: 15px;
    }
`;

const InputNome = styled.input`
    background-color: #F2F7FD;
    font-family: 'Rubik';
    font-size: 16px;
    min-height: 45px;
    max-height: 60px;
    min-width: 850px;
    max-width: 1800px;
    border: 0;
    padding: 0 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    border-radius: 4px;
    outline: 0;

    @media screen and (max-width: 480px) {
        min-width: 250px;
        max-width: 350px;
        min-height: 35px;
        max-height: 45px;
        padding: 0 10px;
        font-size: 14px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 450px;
        max-width: 800px;
        min-height: 40px;
        max-height: 60px;
        padding: 0 10px;
        font-size: 15px;
    }
`;

const InputCpf = styled.input`
    background-color: #F2F7FD;
    font-family: 'Rubik';
    font-size: 16px;
    min-height: 45px;
    max-height: 60px;
    min-width: 410px;
    max-width: 800px;
    border: 0;
    padding: 0 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    border-radius: 4px;
    outline: 0;

    @media screen and (max-width: 480px) {
        min-width: 250px;
        max-width: 350px;
        min-height: 35px;
        max-height: 45px;
        padding: 0 10px;
        font-size: 14px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 200px;
        max-width: 300px;
        min-height: 40px;
        max-height: 60px;
        padding: 0 10px;
        font-size: 15px;
    }
`;

const InputCep = styled.input`
    background-color: #F2F7FD;
    font-family: 'Rubik';
    font-size: 16px;
    min-height: 45px;
    max-height: 60px;
    min-width: 200px;
    max-width: 500px;
    border: 0;
    padding: 0 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    border-radius: 4px;
    outline: 0;

    @media screen and (max-width: 480px) {
        min-width: 250px;
        max-width: 350px;
        min-height: 35px;
        max-height: 45px;
        padding: 0 10px;
        font-size: 14px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 200px;
        max-width: 300px;
        min-height: 40px;
        max-height: 60px;
        padding: 0 10px;
        font-size: 15px;
    }
`;

const InputBairro = styled.input`
    background-color: #F2F7FD;
    font-family: 'Rubik';
    font-size: 16px;
    min-height: 45px;
    max-height: 60px;
    min-width: 190px;
    max-width: 400px;
    border: 0;
    padding: 0 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    border-radius: 4px;
    outline: 0;

    @media screen and (max-width: 480px) {
        min-width: 250px;
        max-width: 350px;
        min-height: 35px;
        max-height: 45px;
        padding: 0 10px;
        font-size: 14px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 200px;
        max-width: 300px;
        min-height: 40px;
        max-height: 60px;
        padding: 0 10px;
        font-size: 15px;
    }
`;

const InputCidade = styled.input`
    background-color: #F2F7FD;
    font-family: 'Rubik';
    font-size: 16px;
    min-height: 45px;
    max-height: 60px;
    min-width: 150px;
    max-width: 400px;
    border: 0;
    padding: 0 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    border-radius: 4px;
    outline: 0;

    @media screen and (max-width: 480px) {
        min-width: 250px;
        max-width: 350px;
        min-height: 35px;
        max-height: 45px;
        padding: 0 10px;
        font-size: 14px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 200px;
        max-width: 300px;
        min-height: 40px;
        max-height: 60px;
        padding: 0 10px;
        font-size: 15px;
    }
`;

const InputEndereco = styled.input`
    background-color: #F2F7FD;
    font-family: 'Rubik';
    font-size: 16px;
    min-height: 45px;
    max-height: 60px;
    min-width: 590px;
    max-width: 800px;
    border: 0;
    padding: 0 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    border-radius: 4px;
    outline: 0;

    @media screen and (max-width: 480px) {
        min-width: 250px;
        max-width: 350px;
        min-height: 35px;
        max-height: 45px;
        padding: 0 10px;
        font-size: 14px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 200px;
        max-width: 300px;
        min-height: 40px;
        max-height: 60px;
        padding: 0 10px;
        font-size: 15px;
    }
`;

const InputNumero = styled.input`
    background-color: #F2F7FD;
    font-family: 'Rubik';
    font-size: 16px;
    min-height: 45px;
    max-height: 60px;
    min-width: 100px;
    max-width: 200px;
    border: 0;
    padding: 0 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    border-radius: 4px;
    outline: 0;

    @media screen and (max-width: 480px) {
        min-width: 250px;
        max-width: 350px;
        min-height: 35px;
        max-height: 45px;
        padding: 0 10px;
        font-size: 14px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 150px;
        max-width: 250px;
        min-height: 40px;
        max-height: 60px;
        padding: 0 10px;
        font-size: 15px;
    }
`;



const ButtonSalvar = styled(Button)`
    min-width: 400px;
    max-width: 600px;
    /* width: 495px; */
    min-height: 45px;
    max-height: 60px;
    /* height: 55px; */
    background: #202E59;
    border-radius: 4px;
    position: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Rubik';
    color: #FFFFFF;
    font-size: 16px;
    flex-wrap: wrap;

    @media screen and (max-width: 480px) {
        min-width: 100px;
        max-width: 150px;
        min-height: 35px;
        max-height: 60px;
        font-size: 12px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        min-width: 200px;
        max-width: 300px;
        min-height: 40px;
        max-height: 60px;
        font-size: 14px;
    }
`;

const Div = styled.div`
    margin-right: 80px;

    @media screen and (max-width: 480px) {
        margin-bottom: 35px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        margin-bottom: 40px;
    }
`;

const LabelError = styled(CaixaContainer)`
    margin-left: 0;
    display: grid;
    padding: 5px 20px;
    color: #a00;
    background: transparent;

    @media screen and (max-width: 480px) {
        padding: 3px 10px;
        font-size: 12px;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        padding: 3px 10px;
    }
`;

interface Values {
    nome: string;
    rg: string;
    cpf: string;
    cep: string;
    estado: string;
    bairro: string;
    cidade: string;
    endereco: string;
    numero: number;
}

const validator = Yup.object().shape({
    nome: Yup.string().min(6, "Nome não é valido").required("Nome é necessário."),
    rg: Yup.string().min(7, "RG não é valido").required("RG é necessário."),
    cpf: Yup.string().required("CPF é necessário.").test((value) => cpf.isValid(value)),
    cep: Yup.string().min(8, "CEP não é valido").required("CEP é necessário."),
    numero: Yup.number().min(1, "Número não é valido").required("Número é necessário."),
});

// export interface Props {
//     sx?: 
// } 

interface Address {
    rua: string;
    cidade: string;
    bairro: string;
    uf: string;
}

const initialValues = {
    nome: "",
    rg: "",
    cpf: "",
    cep: "",
    numero: "",
    endereco: "",
    cidade: "",
    estado: "",
    bairro: "",
};

// const UserContext = createContext();

const PaginaRevenda: React.FC<{}> = () => {
    const navigate = useNavigate();

    // const [formData, setFormData] = useState({ nome: "", rg: "", cpf: "", cep: "", estado: "", bairro: "", cidade: "", endereco: "", numero: "" });
    const [estado, setEstado] = useState<string>("");
    const [erroCEP, setErrorCEP] = useState<undefined | string>("");
    const [adress, setAddress] = useState<Address | null>(null);
    // const [state, setState] = useState();

    const { formData, setFormData } = useContext(FormContext);

    const handleCepChange = useCallback(async (event:
        React.ChangeEvent<HTMLInputElement>) => {
        const novoCep = event.target.value;

        const regexCep = /^[0-9]{5}-?[0-9]{3}$/;
        setErrorCEP(undefined);
        if (regexCep.test(novoCep)) {
            try {
                const response = await axios.get(`https://viacep.com.br/ws/${novoCep}/json/`);
                if (response.data.erro == "true") {
                    setAddress(null);
                    setErrorCEP("Erro ao buscar o seu CEP");
                } else {
                    setAddress({
                        rua: response.data.logradouro,
                        cidade: response.data.localidade,
                        bairro: response.data.bairro,
                        uf: response.data.uf,
                    });
                }
            } catch (error) {
                setAddress(null);
            }
        } else {
            setAddress(null);
        }
    }, []);

    // const handleInputChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    //     setFormData((prevFormData) => ({
    //         ...prevFormData,
    //         [event.target.name]: event.target.value,
    //     }));
    // };

    const handleClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const syncChange = useCallback((calback: any) => (
        (event:
            React.ChangeEvent<HTMLInputElement>) => {
            handleCepChange(event);
            calback(event);
        }
    ), []);

    return (
        <>
            <Formik
                validationSchema={validator}
                // onSubmit={(values, actions) => {
                //     console.log({values, actions});
                // }}
                onSubmit={async ({ nome, rg, cpf, cep, numero,  }, helper) => {
                    try {
                        const {rua, cidade, uf, bairro} = adress || {rua: '', cidade: '', uf: '', bairro: ''}
                        setFormData((old) => {
                            return (
                                { ...old, nome, rg, cpf, cep, numero, endereco: rua, cidade, estado: uf, bairro }
                            )
                        })
                        helper.setStatus(false);
                        await getAuth();
                        navigate("empresa");
                    } catch (error: any) {
                        helper.setStatus(authMessage[error.code] || "Erro ao fazer a sua solicitação verifique e tente novamente mais tarde");
                    }
                }}
                initialValues={initialValues}
            >
                {({ handleChange, values: { nome, rg, cpf, cep, numero, bairro, cidade, endereco, estado }, submitForm, errors, touched, isSubmitting, status }) => {
                    const nomeError = touched.nome && errors.nome;
                    const rgError = touched.rg && errors.rg;
                    const cpfError = touched.cpf && errors.cpf;
                    const cepError = touched.cep && errors.cep;
                    const numeroError = touched.numero && errors.numero;
                    const bairroError = touched.bairro && errors.bairro;
                    const cidadeError = touched.cidade && errors.cidade;
                    const enderecoError = touched.endereco && errors.endereco;
                    const estadoError = touched.estado && errors.estado;

                    return (
                        <>
                            <Revenda>
                                <RootCadastro />
                                <TitleRevenda>Dados Pessoais</TitleRevenda>
                                <PreencherContainer>
                                    <div>
                                        <div>
                                            <Title>Nome Completo</Title>
                                            <InputNome
                                                type="text"
                                                name="nome"
                                                onChange={handleChange("nome")}
                                                value={nome}
                                            >
                                            </InputNome>
                                            {nomeError && <LabelError>{nomeError}</LabelError>}
                                        </div>
                                        <Containers>
                                            <div>
                                                <Title>RG</Title>
                                                <Input
                                                    type="text"
                                                    name="rg"
                                                    onChange={handleChange("rg")}
                                                    value={rg}
                                                >
                                                </Input>
                                                {rgError && <LabelError>{rgError}</LabelError>}
                                            </div>
                                            <div>
                                                <Title>CPF</Title>
                                                <InputCpf
                                                    type="cpf"
                                                    name="cpf"
                                                    onChange={handleChange("cpf")}
                                                    value={cpf}
                                                >
                                                </InputCpf>
                                                {cpfError && <LabelError>{cpfError}</LabelError>}
                                            </div>
                                        </Containers>
                                        <Containers style={{ justifyContent: "space-between" }}>
                                            <div>
                                                <Title>CEP</Title>
                                                <InputCep
                                                    type="cep"
                                                    value={cep}
                                                    name="cep"
                                                    onChange={syncChange(handleChange("cep"))}
                                                />
                                                {(cepError || erroCEP) && <LabelError>{(cepError || erroCEP)}</LabelError>}
                                            </div>
                                            <Div>
                                                <Title>UF</Title>
                                                <input
                                                    type="text"
                                                    name="estado"
                                                     defaultValue={estado}
                                                    value={adress?.uf || ''}
                                                    hidden
                                                />
                                                <Menu onChangeEstado={setEstado} uf={adress?.uf || estado} />
                                                {estadoError && <LabelError>{estadoError}</LabelError>}
                                            </Div>
                                            <div>
                                                <Title>Bairro</Title>
                                                <InputBairro
                                                    type="text"
                                                    name="bairro"
                                                    // defaultValue={bairro}
                                                    value={adress?.bairro || ''}
                                                ></InputBairro>
                                                {bairroError && <LabelError>{bairroError}</LabelError>}
                                            </div>
                                            <div>
                                                <Title>Cidade</Title>
                                                <InputCidade
                                                    type="text"
                                                    value={adress?.cidade || cidade}
                                                    // defaultValue={adress?.cidade}
                                                    name="cidade"
                                                ></InputCidade>
                                                {cidadeError && <LabelError>{cidadeError}</LabelError>}
                                            </div>
                                        </Containers>
                                        <Containers style={{ justifyContent: "space-between" }}>
                                            <div>
                                                <Title>Endereço</Title>
                                                <InputEndereco
                                                    type="text"
                                                    name="endereco"
                                                    defaultValue={endereco}
                                                    value={adress?.rua || ''}
                                                >
                                                </InputEndereco>
                                                {enderecoError && <LabelError>{enderecoError}</LabelError>}
                                            </div>
                                            {/* )} */}

                                            <div>
                                                <Title>Número</Title>
                                                <InputNumero
                                                    type="number" name="numero" onChange={handleChange("numero")} value={numero}
                                                >
                                                </InputNumero>
                                                {numeroError && <LabelError>{numeroError}</LabelError>}
                                            </div>
                                        </Containers>
                                    </div>
                                </PreencherContainer>
                                <Container>
                                    <NavIten onClick={handleClick} to="/" style={{ display: "flex", textDecoration: "none" }}>
                                        <img style={{ transform: "matrix(-1, 0, 0, -1, 0, 0)" }} src={Arrow} alt="Arrow" />
                                        <Text>Voltar para a página inicial</Text>
                                    </NavIten>
                                    {/* <Link to="/empresa" style={{ textDecoration: "none" }}> */}
                                    <ButtonSalvar
                                        // type="submit"
                                        loading={isSubmitting}
                                        title="Salvar e continuar"
                                        onClick={submitForm}
                                    // onClick={handleClick}
                                    >Salvar e continuar</ButtonSalvar>
                                    {status && <LabelError>{status}</LabelError>}
                                    {/* </Link> */}

                                </Container>

                            </Revenda>
                        </>
                    );
                }}
            </Formik>
        </>
    );
};


export default PaginaRevenda;