import React from "react";
// import Footer from "../../components/footer";
import styled from "styled-components";
import { PaginaContatoContainer, Session, TitleSession, SubTitle, TextSession } from "./style";

// export const email = "contato@xcene.com";
export const number = "+5511958746055";

const Numero = <a style=
{{ 
    color: "#1C70BB",
    fontFamily: "Rubik", 
    textDecoration: "none",
    textDecorationColor: "none"
}}  
href={`https://wa.me/${number}`} rel="noreferrer" target="_blank">
+55 (11) 95874-6055
</a>;

const Email = <a style=
{{
    color: "#1C70BB", 
    fontFamily: "Rubik", 
    textDecoration: "none"
}} 
href={`mailto:contato@xcene.com?body=Vim%20pelo%20site`}>contato@xcene.com</a>;

const PaginaContato: React.FC = () => {
    return (
        <>
            <PaginaContatoContainer>
                <Session>
                    <div style={{ display: "flex", flexDirection: "column", flex: 1,
                     minWidth: "200px", maxWidth: "900px" }}>
                        <TitleSession>Contato</TitleSession>
                        <SubTitle>
                            Horário de funcionamento: 8h às 17h (Horário de Brasília)
                        </SubTitle>
                        <TextSession>
                            Ficou interessado em nossos produtos e serviços de automação residencial? Entre em contato conosco através do email {Email} ou pelo telefone {Numero}.
                            Nossa equipe estará pronta para ajudar a tirar suas dúvidas e oferecer as melhores soluções para as suas necessidades. Não perca a oportunidade de transformar a sua casa em um ambiente inteligente e personalizado. 
                            Aguardamos o seu contato!
                        </TextSession>
                    </div>
                </Session>
            </PaginaContatoContainer>
        </>
    );
};


            //<a style={{color: "##000000", fontFamily: "Rubik", fontWeight: "500", fontSize: "16px", lineHeight: "145%", textDecoration: "none"}}  href={`https://wa.me/${number}`} rel="noreferrer" target="_blank">
            //            {number}
            //</a>
            //<a style={{color: "#1C70BB", fontFamily: "Rubik", fontWeight: "500", fontSize: "16px", lineHeight: "145%", textDecoration: "none"}} href={`mailto:${email}?body=Vim%20pelo%20site`}>{email}</a>
export default PaginaContato;