// import { useState } from "react";
import Products, { editProduct, getAllProducts, getProductByID, setProduct } from "./getProducts";
// import Filters from "../components/pesquisa/Filters/Filters";
// import React, { useMemo, useState } from "react";
// import { useLoaderData } from "react-router-dom";
// import CategoryFilter from "../components/pesquisa/Filters/Filters";


export async function loaderProducts() {
    const products = await getAllProducts();
    return products;
}

export async function loaderProductsCat({ params }: any) {
    const products = await getAllProducts();
    const productsCat = products.filter(e => e.category === params.productCategory);
    return productsCat;
}

export async function loaderProductByID({ params }: any) {
    const products = await getProductByID(params.productID);
    return products.data() || null;
}

export async function addProduct({ request, params }: any) {
    const formData = await request.formData();
    let data: Record<string, any> = {};
    for (const [key, value] of formData.entries()) {
        data[key] = value;
    }

    const imgs = [
        formData.get('image1'),
        formData.get('image2'),
        formData.get('image3'),
        formData.get('image4'),
        formData.get('image5'),
        // formData.get('image3'),
    ]

    // const color = [
    //     formData.get('color'),
    // ]

    // const datasheets = [
    //     formData.get('datasheets'),
    // ]

    // let esp: any = {};
    let variants: any = [];
    let datasheets: any = {};
    // let color: any = [];

    console.log("formData.get('datasheets') - ", formData.get('datasheets'))

    try {
        // esp = JSON.parse(formData.get('esp'));
        variants = JSON.parse(formData.get('variants'));
        // color = JSON.parse(formData.get('color'));
        datasheets = JSON.parse(formData.get('datasheets'));
    } catch (error) {

    }
    console.log("datasheets", typeof datasheets)


    // const esp = JSON.parse(formData.get('esp'));
    // const variants = JSON.parse(formData.get('variants'));

    const product: Products = {
        name: formData.get('name'),
        desc: formData.get('desc'),
        imgs,
        // preco: formData.get('preco'),
        modelo: formData.get('modelo'),
        quantTeclas: formData.get('quantTeclas'),
        persianas: formData.get('persianas'),
        // color,
        // color: formData.get('color'),
        // esp,
        datasheets,
        // quant: formData.get('quant'),
        category: formData.get('category'),
        variants,
    }
    console.log({ product });
    try {
        await setProduct(product);

    } catch (error) {
        return error;

    }
    return { ok: true }
}

export async function edtProduct({ request, params }: any) {
    const id = params.productID;
    const formData = await request.formData();
    let data: Record<string, any> = {};
    for (const [key, value] of formData.entries()) {
        data[key] = value;
    }

    const imgs = [
        formData.get('image1'),
        formData.get('image2'),
        formData.get('image3'),
        formData.get('image4'),
        formData.get('image5'),
    ]

    // const color = [
    //     formData.get('color'),
    // ]

    // const datasheets = [
    //     formData.get('datasheets'),
    // ]

    // let esp: any = {};
    let variants: any = [];
    let datasheets: any = {};
    // let color: any = [];

    try {
        // esp = JSON.parse((formData.get && formData.get('esp')) || '');
        variants = JSON.parse((formData.get && formData.get('variants')) || '');
        datasheets = JSON.parse(formData.get('datasheets' || ''));
        // color = JSON.parse(formData.get('color' || ''));
    } catch (error) {

    }

    const product: Products = {
        name: formData.get('name'),
        desc: formData.get('desc'),
        imgs,
        // preco: formData.get('preco'),
        modelo: formData.get('modelo'),
        quantTeclas: formData.get('quantTeclas'),
        persianas: formData.get('persianas'),
        // color,
        // color: formData.get('color'),
        // esp,
        datasheets,
        // quant: formData.get('quant'),

        category: formData.get('category'),
        variants,
        // modelo: formData.get('modelo')

    }
    console.log({ product });
    try {
        await editProduct(id, product);

    } catch (error) {
        return error;

    }
    return { ok: true }
}

// async ({ request, params }) => {
//     const formData = await request.formData();
//     let data: Record<string, any> = {};
//     for (const [key, value] of formData.entries()) {
//         data[key] = value;
//     }
//     // ((e) => {
//     //
//     // });
//     console.log({ data });
//     return { ok: true }