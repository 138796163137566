import React, { useCallback, useState } from "react";
import styled from "styled-components";
import Dropdown from "./dropdownCat/addCatDrop";
import logo from "../../assets/logo.png";
import DropdownVar from "./dropdownVar/addVarDrop";
import { Form, Link } from "react-router-dom";
import Arrow from "../../assets/Arrow.svg";
import UploadImage from "./addImagem/ImagesUpload";
import UploadArquivo from "./addArquivo";
// import EspComponente from "./addEsp";
import { useVariacoes } from "../../hooks/variacoes";
import Modelo from "./addModelo";

const PaginaAdd = styled.div`
  display: flex;
  flex-direction: column;
`;

const BtnAddEdt = styled.button`
  display: flex;
  width: 11.8vw;
  height: 4.5vw;
  border-radius: 4px;
  background: #1c70bb;
  /* text-align: center; */
  align-items: center;
  justify-content: center;
  border: 0;
  cursor: pointer;

  :hover,
  :focus {
    background: #1c70bb;
    color: #1c70bb;
    /* transform: translateY(-0.25em); */
  }

  @media screen and (max-width: 480px) {
    width: 60px;
    height: 40px;
  }
`;

const Texto = styled.h3`
  font-family: "Rubik";
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;

  @media screen and (max-width: 480px) {
    font-size: 11px;
  }
`;

const Img = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 56.3vw; */
  width: 650px;
  /* height: 38.45vw; */
  height: 583px;
  background: #f5f7f9;
  box-shadow: -8px 26px 40px rgba(131, 155, 177, 0.08);
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 480px) {
    width: 230px;
    height: 160px;
  }
`;

const Image = styled.div`
  display: flex;
  width: 140px;
  height: 140px;
  background: #f5f7f9;
  align-items: center;
  justify-content: center;
  box-shadow: -8px 26px 40px rgba(131, 155, 177, 0.08);

  @media screen and (max-width: 480px) {
    width: 130px;
    height: 80px;
  }
`;

const Text = styled.h4`
  font-family: "Rubik";
  font-size: 16px;
  line-height: 160%;
  font-weight: 500;
  line-height: 0%;
  color: #1c70bb;

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
`;

const Title = styled.h3`
  font-family: "Rubik";
  font-size: 20px;
  font-weight: 600;
  color: #9cafc1;
  line-height: 135%;
  /* text-align: center; */

  @media screen and (max-width: 480px) {
    font-size: 15px;
    flex-wrap: wrap;
    line-height: 20%;
    padding: 0 5px;
    /* margin: 0 2px; */
  }
`;

const Input = styled.input`
  background: #f2f7fd;
  height: 3.15vw;
  border-radius: 10px;
  padding: 0 1.5vw;
  border: 0;
  outline: 0 none;
  font-family: "Rubik";
  font-size: 14px;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  [type=number] {
    /* -moz-appearance: textfield; */
  }

  @media screen and (max-width: 480px) {
    font-size: 11px;
    height: 30px;
  }
`;

// const DropDown = styled.input`
//     background: #F2F7FD;
//     height: 3.15vw;
//     border-radius: 10px;
//     border: 0;
//     outline: 0 none;
//     padding: 0 1vw;
// `;

const TextArea = styled.textarea`
  background: #f2f7fd;
  /* height: 45px; */
  border-radius: 10px;
  /* width: 29.25vw; */
  min-width: 350px;
  max-width: 500px;
  height: 14.4vw;
  border: 0;
  outline: 0 none;
  padding: 1.5vw 2vw;
  font-family: "Rubik";
  font-size: 14px;
  resize: none;

  @media screen and (max-width: 480px) {
    font-size: 11px;
    /* width: ; */
    height: 90px;
  }
`;

export const NavIten = styled(Link)`
  /* display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 20px;
  z-index: 3;

  font-family: "Rubik";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  line-height: 145%;
  text-decoration: none;
  color: #fff;

  @media screen and (max-width: 480px) {
    font-size: 14px;
  } */

  display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    color: #1E272F;
    font-family: "Rubik";
    font-size: 16px;
    line-height: 145%;
    font-weight: 500;
    text-decoration: none;
    /* background-color: red; */
    /* height: 120px; */

    @media screen and (max-width: 480px) {
        font-size: 8px;
    }
`;

const Div = styled.div`
  display: flex;
  gap: 3vw;
  /* background: red; */
  /* width: 100%; */
  justify-content: center;
  /* padding-right: 6.3vw; */

  @media screen and (max-width: 480px) {
    /* font-size: 14px; */
    gap: 40px;
    padding-right: 0px;
  }
`;

// const Div2 = styled.div`
//   display: flex;
//   gap: 3vw;

//   @media screen and (max-width: 480px) {
//     display: grid;
//     gap: 0;
//   }
// `;

const Div3 = styled.div`
  display: flex;
  gap: 3vw;
  /* padding-right: 1vw; */

  @media screen and (max-width: 480px) {
    padding-right: 13vw;
  }
`;

export const Check = styled.input`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    border: 1px solid;
    cursor: pointer;

    @media screen and (max-width: 480px) {
    }
`;

const AdicionarProduto: React.FC = () => {
  // const [formData, setFormData] = useState({ name: "", category: "", desc: "", datasheets: "", esp: "", preco: "", quant: "", variants: "" });
  // const [uploadStatus, setUploadStatus] = useState<string>();
  const [category, setCategory] = useState<string>("");
  const [modelo, setModelo] = useState<string>("");
  const [persianas, setPersianas] = useState<string>("false");
  // const [esp, setEsp] = useState<string>("");
  // const [file, setFile] = useState<string>("");
  // const [variants, setVariants] = useState<string>("");
  const {
    listColors,
    // variacao,
    setvaricao,
    varicaoSender,
    // updateVariacao,
    // varicaoSeleted,
    newVariacao,
  } = useVariacoes({});
  const [datasheets, setDatasheets] = useState<string>("");

  //   const setNewDatasheet = useCallback((newData: string) => {
  //     setDatasheets((old) => {
  //       const newArray = [...old];
  //       newArray.push(newData);
  //       return newArray;
  //     });
  //   }, []);

  console.log("datasheets:", datasheets);

  // const handleInputChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
  //     setFormData((prevFormData) => ({
  //         ...prevFormData,
  //         [event.target.name]: event.target.value,
  //     }));
  // };

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handlesUploadManual = useCallback((data: Array<[string, string]>) => {
    const obj: Record<string, string> = {};
    data.forEach(([key, value]) => {
      obj[key] = value;
    });
    setDatasheets(JSON.stringify(obj));
  }, []);

  // const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
  //     // event.preventDefault();

  //     // // Adiciona os dados do formulário ao banco de dados do Cloud Firestore
  //     // await addDoc(collection(firestore, "products"), formData);

  //     // // Limpa os campos do formulário
  //     // setFormData({ name: "", category: "", desc: "", datasheets: "", esp: "", preco: "", quant: "", variants: "" });
  // };

  const handleImgUpdate = (numberImg: number) => (ImageUrl: string) => {};

  return (
    // <Form method="post" >
    <Form
      method="post"
      // onSubmit={handleSubmit}
    >
      <PaginaAdd>
        {/* <RootAddEdt/> */}
        <div
          style={{
            display: "flex",
            padding: "3vw 30vw 5vw 3.5vw",
            justifyContent: "space-between",
          }}
        >
          <Link to="/">
            <img
              src={logo}
              alt=""
              style={{ width: "", height: "4.2vw", justifyContent: "initial" }}
            />
          </Link>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "24.8vw",
              height: "5.4vw",
              background: "#FAFBFF",
              justifyContent: "center",
              borderRadius: "4px",
              gap: "0.4vw",
            }}
          >
            <Link to="../new" style={{ textDecoration: "none" }}>
              <BtnAddEdt>
                <Texto>Adicionar Produto</Texto>
              </BtnAddEdt>
            </Link>
            <Link to="../edit" style={{ textDecoration: "none" }}>
              <BtnAddEdt
                style={{
                  background: "#FAFBFF",
                }}
              >
                <Texto style={{ color: "#787D82" }}>Editar Produto</Texto>
              </BtnAddEdt>
            </Link>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            justifyContent: "center",
            marginBottom: "5vw",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "grid", gap: "0.6vw" }}>
              <Image>
                <UploadImage onImgUpload={handleImgUpdate(1)} name="image2" />
              </Image>
              <Image>
                <UploadImage onImgUpload={handleImgUpdate(2)} name="image3" />
              </Image>
              <Image>
                <UploadImage onImgUpload={handleImgUpdate(3)} name="image4" />
              </Image>
              <Image>
                <UploadImage onImgUpload={handleImgUpdate(4)} name="image5" />
              </Image>
            </div>
            <div>
              <Img>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <UploadImage onImgUpload={handleImgUpdate(0)} name="image1" />
                </div>
              </Img>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // background: "red"
          }}
        >
          <Div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Title>Nome do produto</Title>
              <Input
                type="text"
                name="name"
                // onChange={handleInputChange}
                placeholder="Nome do Produto"
                style={{ minWidth: "350px", maxWidth: "500px" }}
              ></Input>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // background: "red",
                //  paddingTop: "4vw"
              }}
            >
              <Title>Categoria</Title>
              <input type="text" value={category} name="category" hidden />
              <div
                style={{
                  // marginRight: "19vw",
                }}
              >
                {/* <Dropdown onChangeDropDownSelected={setCategory} /> */}
                <Dropdown
                  onChangeCategoria={setCategory}
                  // categ={category}
                />
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Title>Cores</Title>
                <input type="text" name="variants" value={varicaoSender} hidden />
                <div>
                  <DropdownVar
                    listColors={listColors}
                    // onChangeColor={}
                    onChangeVariacao={setvaricao}
                    onChangeNewVariacao={(e) => {
                      newVariacao(e, []);
                    }}
                  />
                </div>
              </div>
            </div>
          </Div>

          <div style={{ display: "flex", marginTop: "1vw" }}>
            <Div3>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Title>Descrição do produto</Title>
                <TextArea
                  name="desc"
                  placeholder="Descrição do Produto"
                ></TextArea>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ display: "flex", flexDirection: "column", marginBottom: "9%", minWidth: "250px", maxWidth: "400px", height: "100%" }}>
                <div style={{ display: "flex", gap: "3vw" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Title>Modelo</Title>
                    {/* <Input type="text" name="modelo" placeholder="Modelo do Produto" style={{ width: "30.25vw" }}/> */}
                    <input type="text" name="modelo" value={modelo} hidden />
                    <div>
                      <Modelo onChangeModelo={setModelo}/>
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Title>Teclas</Title>
                    {/* <Input type="text" name="modelo" placeholder="Modelo do Produto" style={{ width: "30.25vw" }}/> */}
                    {/* <input type="text" name="modelo" value={modelo} hidden /> */}
                    <div>
                      <Input
                        type="number"
                        name="quantTeclas"
                        // value={formData.quant}
                        // onChange={handleInputChange}
                        // placeholder="Número de teclas"
                        style={{
                          // minWidth: "50px",
                          maxWidth: "90px",
                          // width: "12.8vw"
                        }}
                      ></Input>
                      <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "3%"}}>

                      {/* <Check type="checkbox" value={product.label} onClick={() => {modelo === product.label ? setModelo("") : setModelo(product.label)}} checked={modelo === product.label ? true : false}/> */}
                      {/* <input name="persianas" hidden value={persiana} />
                        <Check
                          type="checkbox"
                          value={persianas}
                          onClick={() => {
                            setPersianas((old) =>
                              old === "true" ? "false" : "true"
                            );
                          }}
                          checked={persiana === "true"}
                          defaultValue={persianas}
                        /> */}
                        <input name="persianas" hidden value={persianas} />
                        <Check type="checkbox" name="persianas" onClick={() => {persianas === "true" ? setPersianas("false") : setPersianas("true")}} checked={persianas === "true" ? true : false}/>
                        <Text style={{color: "#9cafc1"}}>Persiana</Text>
                      </div>
                      {/* <Modelo onChangeModelo={setModelo}/> */}
                    </div>
                  </div>
                </div>
                {/* <div style={{ display: "flex", flexDirection: "column" }}>
                  <Title>Especificações</Title>
                  <input name="esp" type="text" hidden value={esp} />
                  <div>
                    <EspComponente
                      onChangeEsp={(e) => {
                        setEsp(JSON.stringify(e));
                      }}
                    />
                  </div>
                </div> */}
                {/* <Div2>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Title>Quantidade Mínima</Title>
                    <Input
                      type="number"
                      name="quant"
                      // value={formData.quant}
                      // onChange={handleInputChange}
                      placeholder="Quantidade mínima"
                      style={{
                        minWidth: "50px",
                        maxWidth: "145px",
                        // width: "12.8vw"
                      }}
                    ></Input>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Title>Preço</Title>
                    <Input
                      type="text"
                      name="preco"
                      // value={formData.preco}
                      // onChange={handleInputChange}
                      placeholder="Preço do Produto"
                      style={{
                        minWidth: "50px",
                        maxWidth: "145px",
                        // width: "6vw"
                      }}
                    ></Input>
                  </div>
                </Div2> */}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Title>Manual</Title>
                  <input
                    type="text"
                    hidden
                    name="datasheets"
                    value={datasheets}
                  />
                  <UploadArquivo
                    onChange={(e) => {
                      handlesUploadManual(e);
                      //   setDatasheets(JSON.stringify(e));
                    }}
                  />
                  {/* <Input
                                        type="url"
                                        name="datasheets"
                                        // value={formData.datasheets}
                                        // onChange={handleInputChange}
                                        placeholder='Manual do Produto'
                                        style={{ 
                                            minWidth: "50px",
                                            maxWidth: "145px",
                                        }}>
                                    </Input> */}
                </div>
              </div>
              </div>
              </div>
            </Div3>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            padding: "3.5vw 4vw",
            justifyContent: "space-between",
          }}
        >
          <NavIten
            onClick={handleClick}
            to="/"
            style={{ display: "flex", textDecoration: "none", flexDirection: "row", alignItems: "center", justifyContent: "center", height: "5%", }}
          >
            <img
              style={{ transform: "matrix(-1, 0, 0, -1, 0, 0)" }}
              src={Arrow}
              alt="Arrow"
            />
            <Text>Voltar para a página inicial</Text>
          </NavIten>

          <BtnAddEdt
            onClick={handleClick}
            type="submit"
            style={{
              width: "20.8vw",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            }}
          >
            <Texto>Adicionar</Texto>
          </BtnAddEdt>
        </div>
      </PaginaAdd>
    </Form>
  );
};

export default AdicionarProduto;
