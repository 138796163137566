import React from "react";
import Footer from "../../components/footer";
import styled from "styled-components";
import { PaginaAssistenciaContainer, Session, TitleSession, TextSession, SessionContato, Assistencia } from "./style";
import CaixaContato from "./CaixaContato/index";
import Arrow from '../../assets/arrowSm.png';

const PaginaAssistencia: React.FC = () => {
    return (
        <>
            <PaginaAssistenciaContainer>
                <Session style={{ marginBottom: "4%" }}>
                    <div style={{ flex: 1, minWidth: "300px" }}>
                        <TitleSession>Assistência Técnica e Manutenção</TitleSession>
                        <TextSession>
                            Nossa assistência técnica é o que nos diferencia no mercado de automação residencial. Além de oferecermos suporte técnico presencial, temos uma equipe altamente capacitada que oferece assistência técnica remota, dando suporte através do celular. Com isso, nossos clientes têm a tranquilidade de saber que podem contar com a nossa equipe em qualquer momento, garantindo o melhor desempenho e durabilidade dos equipamentos de automação. Caso você tenha algum problema, entre em contato com nossa equipe de suporte e experimente a tranquilidade de contar com o melhor atendimento em automação residencial.
                        </TextSession>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end", paddingRight: "3%"}}>
                            <Assistencia href="">
                                Desejo ser redirecionado para a assistencia remota
                            </Assistencia>
                            <img src={Arrow} alt=""/>
                        </div>
                    </div>
                </Session>
                {/* <SessionContato>
                    
                    <CaixaContato />
                    
                </SessionContato> */}
            </PaginaAssistenciaContainer>
        </>
    );
};

export default PaginaAssistencia;

// import React from "react";
// import "react-activity/dist/library.css";
// import styled, { css } from "styled-components";

// const Lista = styled.div `
//     display: block;
//     //margin-bottom: 8px;
//     font-size: 16px;
// `;

// const ListaSuspensa: React.FC = ({}) => {
//     return (
//     <Lista>
//         <div>
//             <select>
//                 <li>Keypad Pulsador</li>
//                 <li>Produto 1</li>
//                 <li>Produto 2</li>
//                 <li>Produto 3</li>
//             </select>
//         </div>
//     </Lista>
//     );
// };    

// export default ListaSuspensa;

// import Dropdown from 'react-dropdown';
// import 'react-dropdown/style.css';

// const options = [
//     'Keypad Pulsador', 'Produto 1', 'Produto 2', 'Produto 3'
// ];

// const DefaultOption = options[0];
// <Dropdown options={options} onChange={this._onSelect} value={DefaultOption} placeholder="Select an option" />;

// export default DefaultOption;