import styled from "styled-components";
import { Link } from "react-router-dom";
import Button from "../button";

export const ContainerButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: unset;
    width: 254px;
    height: 78px;
    background: #FAFBFF;
    border-radius: 4px;

    @media screen and (max-width: 480px) {
        width: 190.5px;
        height: 58.5px;
    }
`;

export const ButtonRegister = styled(Button)`
    display: flex;
    width: 120px;
    height: 70px;
    background: #FAFBFF;
    border-radius: 4px;
    position: unset;

    span {
        color: #787D82;
    }

    @media screen and (max-width: 480px) {
        width: 90px;
        height: 52.5px;
    }
`;

export const ButtonLogin = styled(Button)`
    display: flex;
    width: 126px;
    height: 70px;
    background: #1C70BB;
    border-radius: 4px;
    position: unset;
    margin-left: 4px;

    @media screen and (max-width: 480px) {
        width: 90px;
        height: 52.5px;
        margin: 3px;
    }
`;

// export const Text = styled.h3`
//     font-family: 'Rubik';
//     font-weight: 400;
//     font-size: 16px;
//     line-height: 160%;
//     color: #787D82;
// `;