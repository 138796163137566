import React, { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { ContainerSideBar, ContainerNavBar, ContainterBack, ContainterBackMini, NavIten } from "./style";
import logo from "../../assets/logo.png";
import backTop from "../../assets/backTop.png";
import Button from "../button";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Menu from '../../assets/Menu.png';


const Nav = styled.div`
    height: 35; 
    /* width: 450; */
    min-width: 450px;
    max-width: 550px;
    display: flex; 
    justify-content: center;
    align-items: center;
    padding-left: 7vw;
    flex-wrap: wrap;

    @media screen and (max-width: 480px) {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 1;
    }

    @media screen and (min-width: 481px) and (max-width: 890px) {
        padding-left: 0vw;
        min-width: 350px;
        max-width: 400px;
        /* display: none;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 1; */
    }
`;

const Dropdown = styled.div`
    display: none;
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 200%;
    left: 0;
    right: 0;
    z-index: 1;

    @media screen and (max-width: 480px) {
        display: flex;
        flex-direction: column;
    }
`;

const NavElements = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    /* min-width: 500px;
    max-width: 800px; */
    background: #F2F7FD;
    position: absolute;
    right: -1.5rem;
    top: 2.3rem;
    height: 18rem;
    transition: all 0.3s ease-in;
    
    @media screen and (min-width: 481px) and (max-width: 890px) {
        /* min-width: 50px;
        max-width: 100px; */
        /* width: 30%; */
        flex-wrap: wrap;
    }
`;

const LinkDrop = styled(Link)`
    text-decoration: none;
    color: #202e59;
    padding-left: 1rem;

    :hover {
    background-color: #9abae27b;
    }
`;

const TextDrop = styled.h2`
    font-family: 'Rubik';
    font-size: 16px;
`;

const Title = styled.h1`
    font-family: "Rubik";
    font-style: normal;
    font-weight: 600;
    font-size: 56px;
    line-height: 130%;
    letter-spacing: -0.0125em;
    color: #202e59;

    @media screen and (max-width: 480px) {

    }
    
    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
    
    @media screen and (min-width: 1024px) {
        
    }
`;

const TextNaviBar = styled.text`
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 161.5%;
    //padding: 0 20% 0 0;
    color: #1e272f;
`;

const BtnProdutos = styled(Button)`
    width: 311px;
    /* height: 100px; */
    background: #202E59;
    margin-top: 8%;
`;

const BtnRevenda = styled(Link)`
    @media screen and (max-width: 480px) {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 1;
    }

    /* @media screen and (min-width: 481px) and (max-width: 890px) {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 1;
    } */
`;

const Logo = styled(Link)`
    @media screen and (max-width: 480px) {
        position: absolute;
        top: 100%;
        left: 15px;
    }

    @media screen and (max-width: 890px) {
        
    }
`;

const L = styled.div`
    height: 2.5rem;
    display: flex;

    @media screen and (max-width: 480px) {
        height: 1.5rem;
    }

    @media screen and (max-width: 890px) {
        height: 2rem;
    }
`;

export const LogoComp = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ style, ...res }, ref) => (
    <L ref={ref} style={{ 
        // height: "2.5rem", display: "flex",
    //  minWidth: "100px", maxWidth: "300px",
      ...style }} {...res}>
        <img style={{ minWidth: "90px" ,maxWidth: "500px" }} src={logo} alt={"logotipo"} />
    </L>
));

// function myFunction() {
//     const x = document.getElementById("myTopnav");
//     if (x.className === "topnav") {
//       x.className += " responsive";
//     } else {
//       x.className = "topnav";
//     }
// }

const heigthNavBar = 250;

const ContainterBackNavBar: React.FC = () => {
    const mavItenRef = useRef<HTMLDivElement>(null);
    const [fixed, setFixed] = useState(false);

    const [showNavbar, setShowNavbar] = useState<boolean>(false);

    const toggleNavbar = () => {
        setShowNavbar(!showNavbar);
    };

    const dismissHandler = (event: React.FocusEvent<HTMLButtonElement>): void => {
        if (event.currentTarget === event.target) {
          setShowNavbar(false);
        }
      };

    const fixnavbar = useCallback(() => {
        if (mavItenRef.current?.clientHeight && window.scrollY >= mavItenRef.current?.clientHeight - heigthNavBar) {
            setFixed(true);
        } else {
            setFixed(false);
        }
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", fixnavbar);
        return () => window.removeEventListener("scroll", fixnavbar);
    }, [fixnavbar]);

    const handleClick = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    const Navites = ({ hiden = false }) => {
        return (
            <ContainerNavBar 
            // fixed={hiden}
            >
                <Logo to="/">
                    <LogoComp />
                </Logo>
                <div style={{ 
                    display: "flex", 
                    alignItems: "center", 
                    justifyContent: "center",
                    }}>
                    <Nav style={{ 
                        // height: 35, 
                        // width: 450, 
                        // display: "flex", 
                        // justifyContent: "center",
                        // alignItems: "center",
                        // paddingLeft: "7vw"
                        }}>
                        <NavIten onClick={handleClick} to="/">Home</NavIten>
                        <NavIten onClick={handleClick} to="/sobre">Sobre</NavIten>
                        <NavIten onClick={handleClick} to="/products">Produtos</NavIten>
                        {/* <NavIten onClick={handleClick} to="/assistencia">Assistência</NavIten> */}
                        <NavIten onClick={handleClick} to="/contato">Contato</NavIten>
                    </Nav>
                    
                    <Dropdown>
                        <button
                         className={showNavbar ? "active" : undefined}
                         onClick={(): void => toggleNavbar()}
                         onBlur={(e: React.FocusEvent<HTMLButtonElement>): void =>
                            dismissHandler(e)
                          }
                          style={{display: "flex", background: "transparent", border: "0 none", cursor: "pointer", position: "absolute", right: 0}}>
                             {/* //  onClick={handleShowNavbar} */}
                            <img style={{width: "2.5rem"}} src={Menu} alt="" />
                        </button>
                        {/* <div></div> */}
                        {showNavbar && (
                            <NavElements onClick={(): void => toggleNavbar()} className={`${showNavbar && 'active'}`}>
                                <LinkDrop to="/" onClick={handleClick}>
                                    <TextDrop>Home</TextDrop>
                                </LinkDrop>
                                <LinkDrop to="/sobre" onClick={handleClick}>
                                    <TextDrop>Sobre</TextDrop>
                                </LinkDrop>
                                <LinkDrop to="/products" onClick={handleClick}>
                                <TextDrop>Produtos</TextDrop>
                                </LinkDrop>
                                {/* <LinkDrop to="/assistencia" onClick={handleClick}>
                                    <TextDrop>Assistência</TextDrop>
                                </LinkDrop> */}
                                <LinkDrop to="/contato" onClick={handleClick}>
                                    <TextDrop>Contato</TextDrop>
                                </LinkDrop>
                                <LinkDrop to="/login" onClick={handleClick}>
                                    <TextDrop>Revenda</TextDrop>
                                </LinkDrop>
                            </NavElements>
                        )}
                        
                    </Dropdown>
                    
                </div>
                {/* <BtnRevenda to="/login" style={{ textDecoration: "none" }}>
                    <Button style={{
                        minWidth: "40px", maxWidth: "280px",
                        height: "50px"
                        }} title={"Revenda"} />
                </BtnRevenda> */}
                
            </ContainerNavBar>
        );
    };

    return (
        <>
            <ContainterBack
            ref={mavItenRef}
            // url={backTop}
            >
                {/* <ContainerSideBar>
                    <div style={{padding: "0 5vw 0 0"}}>
                        <Title>
                            O futuro é agora
                            <br />O futuro é XCENE
                        </Title>
                        <TextNaviBar>
                            Não perca mais tempo com preocupações desnecessárias, acesse nossos produtos de automação residencial e transforme sua casa em um
                            ambiente inteligente e confortável.
                        </TextNaviBar>
                    </div>
                    <div>
                        <Link to="/products" style={{ textDecoration: "none" }}>
                            <BtnProdutos title={"Acessar Produtos"} />
                        </Link>
                    </div>
                </ContainerSideBar> */}
                    <Navites hiden={fixed} />
            </ContainterBack>
            {fixed && (
                <ContainterBackMini url={backTop} height={heigthNavBar}>
                    <Navites />
                </ContainterBackMini>
            )}
        </>
    );
};

export default ContainterBackNavBar;
